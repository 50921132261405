import React, { createContext, useContext, useEffect, useState } from 'react';
import { type AdministratorResponse } from '../interfaces/administrator';
import ApplicationApi from '../api/config';

interface AdminContextProps {
  name: string;
  address: string;
  cellphone: string;
  city: string;
  cnpj: string;
  country: string;
  district: string;
  email: string;
  fantasy: string;
  number: string;
  phone?: string | null;
  state: string;
  zipCode: string;
  facebook: string | null;
  instagram: string | null;
  crf: string | null;
  image: string | null;
}

const AdminContext = createContext<AdminContextProps>({
  name: '',
  address: '',
  cellphone: '',
  city: '',
  cnpj: '',
  country: '',
  district: '',
  facebook: '',
  instagram: '',
  crf: '',
  email: '',
  fantasy: '',
  number: '',
  state: '',
  zipCode: '',
  image: null,
});

const administratorDefaultValue = {
  name: '',
  address: '',
  cellphone: '',
  city: '',
  cnpj: '',
  country: '',
  district: '',
  email: '',
  fantasy: '',
  number: '',
  state: '',
  zipCode: '',
  instagram: '',
  facebook: '',
  crf: '',
  administratorImages: null,
};

interface AdminProviderProps {
  children: React.ReactNode;
}

const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
  const [administrator, setAdministrator] = useState<AdministratorResponse>(administratorDefaultValue);

  useEffect(() => {
    ApplicationApi.get<AdministratorResponse>('administradores/informacoes')
      .then(({ data }) => {
        setAdministrator(data);
      })
      .catch(() => {});
  }, []);

  return (
    <AdminContext.Provider
      value={{
        address: administrator.address,
        cellphone: administrator.cellphone,
        city: administrator.city,
        cnpj: administrator.cnpj,
        country: administrator.country,
        district: administrator.district,
        email: administrator.email,
        fantasy: administrator.fantasy,
        image: administrator.administratorImages !== null ? administrator.administratorImages?.uploads.origin : null,
        name: administrator.name,
        number: administrator.number,
        state: administrator.state,
        zipCode: administrator.zipCode,
        phone: administrator.phone,
        instagram: administrator.instagram,
        facebook: administrator.facebook,
        crf: administrator.crf,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = (): AdminContextProps => useContext(AdminContext);

export default AdminProvider;
