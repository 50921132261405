import React from 'react';
import { PromotionContainer } from '../../styles/pages/includes/promotion';
import { useNavigate } from 'react-router-dom';
import { type PromotionResponse } from '../../interfaces/promotion';

interface PromotionProps {
  data: PromotionResponse;
}

const Promotion: React.FC<PromotionProps> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <PromotionContainer
      onClick={() => {
        navigate(`/promocoes/${data.slug}`);
      }}
    >
      <img
        src={
          data.promotionThumbnails !== null
            ? data.promotionThumbnails.uploads.origin
            : data.promotionImages !== null
              ? data.promotionImages.uploads.origin
              : ''
        }
      />
    </PromotionContainer>
  );
};

export default Promotion;
