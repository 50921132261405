import React from 'react';
import { type BannerResponse } from '../interfaces/banner';
import { CarouselImage } from '../styles/components/carousel';
import Slider from 'react-slick';

interface CarouselProps {
  data: BannerResponse[];
}

const Caroussel: React.FC<CarouselProps> = ({ data }) => {
  return (
    <Slider arrows={false} dots={false} infinite={true} autoplay={true} autoplaySpeed={4000}>
      {data.map((banner, i) => (
        <a key={i} href={banner.link ?? ''}>
          <CarouselImage>
            <img src={banner.bannerImages.uploads.origin} alt={banner.title ?? ''} />
          </CarouselImage>
        </a>
      ))}
    </Slider>
  );
};

export default Caroussel;
