import React, { type SyntheticEvent, useEffect, useState } from 'react';
import {
  HighlightContainer,
  HighlightContainerFluid,
  PermissionBox,
  PermissionContainer,
  PermissionContent,
  RegisterContainer,
  RegisterContainerFluid,
} from '../styles/pages/register';
import Row from '../components/row';
import Column from '../components/column';
import CustomInput from '../components/input';
import CustomButtom from '../components/button';
import Product from './includes/product';
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import ApplicationApi from '../api/config';
import { type TextResponse } from '../interfaces/text';
import Loading from '../components/loading';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { type UserResponse } from '../interfaces/user';
import Alert, { type AlertTypes, AlertTypesValue } from '../components/alert';
import { type AxiosError } from 'axios';
import { formatDateToUS } from '../helpers/dateFormat';
import { type ProductResponse } from '../interfaces/product';

export const useQuery = (): URLSearchParams => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const bodyDefaultValue = {
  name: '',
  lastName: '',
  birthdate: '',
  gender: '',
  document: '',
  email: '',
  phone: '',
  homePhone: '',
  workPhone: '',
  password: '',
};

const Register: React.FC = () => {
  document.title = `Drogaria Malu :: Cadastro`;

  const query = useQuery();

  const [body, setBody] = useState({
    ...bodyDefaultValue,
    email: query.get('email') ?? '',
    name: query.get('name') ?? '',
    lastName: query.get('lastName') ?? '',
  });

  const [permissionActive, setPermissionActive] = useState<boolean>(false);
  const [permissionLoading, setPermissionLoading] = useState<boolean>(true);
  const [passwordVerified, setPasswordVerified] = useState<boolean>();
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const [alert, setAlert] = useState<AlertTypes>();

  const [products, setProducts] = useState<ProductResponse[]>([]);

  const [permission, setPermission] = useState<TextResponse>();

  useEffect(() => {
    ApplicationApi.get<TextResponse>('textos/politica-de-privacidade')
      .then(({ data }) => {
        setPermissionLoading(false);
        setPermission(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductResponse[]>('produtos/home')
      .then(({ data }) => {
        setProducts(data);
      })
      .catch(() => {});
  }, []);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.post<UserResponse>('usuarios', {
        ...body,
        fullname: `${body.name} ${body.lastName}`,
        birthdate: formatDateToUS(body.birthdate),
      });

      setAlert({
        ...AlertTypesValue.success,
        content: ' Cadastro realizado com sucesso. Parabéns agora você já pode realizar as suas compras!',
      });
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.status === 500) {
        setAlert({
          ...AlertTypesValue.error,
          content: 'Não foi possível concluir seu cadastro, tente novamente.',
        });
      } else if (error.response?.status === 400) {
        const data = error.response.data as Array<{ campo: string; mensagem: string }>;

        if (data !== undefined) {
          setAlert({
            ...AlertTypesValue.warning,
            content: (
              <div>
                <p>
                  Não foi possível concluir seu cadastro: <b>{data[0].campo}</b> - <b>{data[0].mensagem}</b>
                </p>
              </div>
            ),
          });
        }
      } else {
        setAlert({
          ...AlertTypesValue.warning,
          content: 'Não foi possível concluir seu cadastro, o usuário já se encontra em nossa base de dados.',
        });
      }
    }

    setFormLoading(false);
  };

  return (
    <>
      <RegisterContainerFluid>
        <RegisterContainer>
          {query.get('oauth') !== null ? (
            <h1>
              Insira seus dados para <b>completar seu cadastro:</b>
            </h1>
          ) : (
            <h1>
              Insira seus dados para <b>criar um novo cadastro:</b>
            </h1>
          )}
          <form onSubmit={submitForm}>
            {formLoading ? <Loading /> : <></>}
            {alert !== undefined ? <Alert type={alert} /> : <></>}
            <Row>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="Nome" margin>
                  <input
                    required
                    defaultValue={body.name}
                    onChange={e => {
                      setBody({ ...body, name: e.target.value });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="Sobrenome" margin>
                  <input
                    required
                    defaultValue={body.lastName}
                    onChange={e => {
                      setBody({ ...body, lastName: e.target.value });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="Data de Nascimento" margin>
                  <InputMask
                    mask="99/99/9999"
                    onChange={e => {
                      setBody({ ...body, birthdate: e.target.value });
                    }}
                    required
                  />
                </CustomInput>
              </Column>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="Sexo" margin>
                  <select
                    onChange={e => {
                      setBody({ ...body, gender: e.target.value });
                    }}
                    required
                  >
                    <option value="" selected>
                      Selecione
                    </option>
                    <option>Feminino</option>
                    <option>Masculino</option>
                    <option>Prefiro não informar</option>
                  </select>
                </CustomInput>
              </Column>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="CPF" margin>
                  <InputMask
                    mask="999.999.999-99"
                    onChange={e => {
                      setBody({ ...body, document: e.target.value });
                    }}
                    required
                  />
                </CustomInput>
              </Column>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="E-mail" margin>
                  <input
                    type="email"
                    required
                    defaultValue={body.email}
                    onChange={e => {
                      setBody({ ...body, email: e.target.value });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="Telefone Residencial" margin>
                  <InputMask
                    mask="(99) 9999-9999"
                    onChange={e => {
                      setBody({ ...body, homePhone: e.target.value });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="Telefone Celular" margin>
                  <InputMask
                    mask="(99) 99999-9999"
                    onChange={e => {
                      setBody({ ...body, phone: e.target.value });
                    }}
                    required
                  />
                </CustomInput>
              </Column>
              <Column xl={4}>
                <CustomInput background="#f5f5f5" label="Telefone Comercial" margin>
                  <InputMask
                    mask="(99) 9999-9999"
                    onChange={e => {
                      setBody({ ...body, workPhone: e.target.value });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={6}>
                <CustomInput background="#f5f5f5" label="Senha" margin>
                  <input
                    onChange={e => {
                      setBody({ ...body, password: e.target.value });
                    }}
                    type="password"
                    required
                  />
                </CustomInput>
              </Column>
              <Column xl={6}>
                <CustomInput
                  border={passwordVerified !== undefined && !passwordVerified}
                  borderColor="#ff0000"
                  background="#f5f5f5"
                  label="Confirmar Senha"
                  margin
                >
                  <input
                    type="password"
                    onBlur={e => {
                      if (e.currentTarget.value !== body.password) {
                        setPasswordVerified(false);
                        e.target.value = '';
                      } else {
                        setPasswordVerified(true);
                      }
                    }}
                    required
                  />
                </CustomInput>
              </Column>
            </Row>
            <PermissionContainer>
              <Row>
                <Column xl={6}>
                  <PermissionBox>
                    <div>
                      <h2>Permissões</h2>
                      <p>Visualize aqui as suas permissões</p>
                    </div>
                    {permissionActive ? (
                      <AiOutlineEyeInvisible
                        onClick={() => {
                          setPermissionActive(false);
                        }}
                      />
                    ) : (
                      <AiOutlineEye
                        onClick={() => {
                          setPermissionActive(true);
                        }}
                      />
                    )}
                  </PermissionBox>
                </Column>
                <Column xl={6} center>
                  <h3>
                    As informações aqui solicitadas são utilizadas conforme demonstrado em nossa Política de Privacidade
                    e Termos de Uso
                  </h3>
                </Column>
              </Row>
              {permissionActive ? (
                <PermissionContent>
                  {permissionLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <h3>{permission?.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: permission?.text !== undefined && permission.text !== null ? permission.text : '',
                        }}
                      />
                    </>
                  )}
                </PermissionContent>
              ) : (
                <></>
              )}
            </PermissionContainer>
            <Row>
              <Column xl={2}>
                <CustomButtom background="#1482ae">
                  <button type="reset">LIMPAR</button>
                </CustomButtom>
              </Column>
              <Column xl={2}>
                <CustomButtom>
                  <button type="submit">CADASTRAR</button>
                </CustomButtom>
              </Column>
            </Row>
          </form>
        </RegisterContainer>
      </RegisterContainerFluid>
      {products.length > 0 ? (
        <HighlightContainerFluid>
          <HighlightContainer>
            <Row>
              {products.slice(0, 6).map((product, i) => (
                <Column key={i} xl={2}>
                  <Product data={product} />
                </Column>
              ))}
            </Row>
          </HighlightContainer>
        </HighlightContainerFluid>
      ) : (
        <></>
      )}
    </>
  );
};

export default Register;
