import styled from 'styled-components';

export const TextContainerFluid = styled.div<{ background?: string }>`
  width: 100%;
  padding: 0 15px;
  background: ${({ background, theme }) => background ?? theme.background.secondary};
`;

export const TextContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;
  position: relative;

  h1 {
    color: ${({ theme }) => theme.background.primary};
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
`;

export const TextBanner = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;
