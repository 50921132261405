import styled from 'styled-components';

export const CustomTextAreaContainer = styled.div<{ margin?: boolean }>`
  margin-bottom: ${({ margin }) => (margin !== undefined && margin ? '12px' : '0')};

  textarea {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    min-height: 300px;
    outline: 0;
    border: 0;
    padding: 12px;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    color: ${({ theme }) => theme.color.third};
    resize: none;
  }
`;

export const CustomTextAreaLabel = styled.p`
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #6e6e77;
  font-weight: 600;
`;
