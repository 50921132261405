import React, { useEffect, useState } from 'react';
import {
  HomeSidebarContainer,
  HomeSidebarContentContainer,
  HomeSidebarItem,
  HomeSidebarItemContainer,
  HomeSidebarManItem,
  HomeSidebarNoContentLabel,
  HomeSidebarSubitem,
} from '../../styles/pages/includes/sidebar';
import ApplicationApi from '../../api/config';
import { type CategoryResponse } from '../../interfaces/category';
import { FaBars } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading';
import { type IconType } from 'react-icons';

/*
import * as IconsFa from 'react-icons/fa';
import * as IconsRi from 'react-icons/ri';
import * as IconsMd from 'react-icons/md';
import * as IconsBs from 'react-icons/bs';
import * as IconsCi from 'react-icons/ci';
import * as IconsCg from 'react-icons/cg';
import * as IconsGi from 'react-icons/gi';
import * as IconsAi from 'react-icons/ai';
import * as IconsBi from 'react-icons/bi';
import * as IconsFi from 'react-icons/fi';
import * as IconsLu from 'react-icons/lu';
*/

interface SidebarProps {
  margin?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ margin }) => {
  const navigate = useNavigate();

  const [loadingCategories, setLoadingCategories] = useState<boolean>(true);
  const [dropdownCategories, setDropdownCategories] = useState(false);
  const [categories, setCategories] = useState<CategoryResponse[]>([]);

  const iconsValues: IconType[] = [];
  /*
  const iconsValues = Object.values(IconsFa)
    .concat(Object.values(IconsRi))
    .concat(Object.values(IconsMd))
    .concat(Object.values(IconsBs))
    .concat(Object.values(IconsCi))
    .concat(Object.values(IconsCg))
    .concat(Object.values(IconsGi))
    .concat(Object.values(IconsAi))
    .concat(Object.values(IconsBi))
    .concat(Object.values(IconsFi))
    .concat(Object.values(IconsLu));
*/

  useEffect(() => {
    ApplicationApi.get<CategoryResponse[]>('categorias')
      .then(({ data }) => {
        setCategories(data);
        setLoadingCategories(false);
      })
      .catch(() => {});
  }, []);

  return (
    <HomeSidebarContainer margin={margin}>
      {!loadingCategories ? (
        <>
          {categories.length > 0 ? (
            <>
              <HomeSidebarItem
                onClick={() => {
                  setDropdownCategories(!dropdownCategories);
                }}
              >
                <FaBars />
                <p>TODAS AS CATEGORIAS</p>
              </HomeSidebarItem>
              {categories.slice(0, 5).map((category, i) => (
                <HomeSidebarManItem key={i}>
                  <HomeSidebarItem
                    onClick={() => {
                      navigate(`/categorias/${category.slug}`);
                    }}
                  >
                    {iconsValues.map((Icon, i) => (Icon.name === category.icon ? <Icon key={i} /> : <></>))}
                    <p>{category.name}</p>
                  </HomeSidebarItem>
                </HomeSidebarManItem>
              ))}
            </>
          ) : (
            <HomeSidebarNoContentLabel>Nenhuma categoria encontrada.</HomeSidebarNoContentLabel>
          )}
        </>
      ) : (
        <Loading radius={24} />
      )}
      <HomeSidebarContentContainer active={dropdownCategories}>
        {!loadingCategories ? (
          <>
            {categories.length > 0 ? (
              <>
                {categories.map((category, i) => (
                  <HomeSidebarItemContainer key={i}>
                    <HomeSidebarItem
                      onClick={() => {
                        navigate(`/categorias/${category.slug}`);
                      }}
                    >
                      {iconsValues.map((Icon, i) => (Icon.name === category.icon ? <Icon key={i} /> : <></>))}
                      <p>{category.name}</p>
                    </HomeSidebarItem>
                    {category.subcategories?.map((subcategory, i) => (
                      <HomeSidebarSubitem
                        onClick={() => {
                          navigate(`/subcategorias/${subcategory.slug}`);
                        }}
                        key={i}
                      >
                        {iconsValues.map((Icon, i) => (Icon.name === subcategory.icon ? <Icon key={i} /> : <></>))}
                        <p>{subcategory.name}</p>
                      </HomeSidebarSubitem>
                    ))}
                  </HomeSidebarItemContainer>
                ))}
              </>
            ) : (
              <p>Nenhum categoria encontrada</p>
            )}
          </>
        ) : (
          <Loading />
        )}
      </HomeSidebarContentContainer>
    </HomeSidebarContainer>
  );
};

export default Sidebar;
