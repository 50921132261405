import styled from 'styled-components';

interface CustomInputContainerProps {
  background?: string;
  border?: boolean;
  borderColor?: string;
  margin?: boolean;
}

export const CustomInputContainer = styled.div<CustomInputContainerProps>`
  height: 42px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: ${({ border, borderColor }) =>
    border !== undefined && border ? `1px solid ${borderColor ?? '#d4d4d4'}` : '0'};
  margin-bottom: ${({ margin }) => (margin !== undefined && margin ? '12px' : '0')};
  display: flex;
  background: ${({ background }) => background ?? '#fff'};
  align-items: center;

  input,
  select {
    font-family: Montserrat, sans-serif;
    border: 0;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    padding: 0 12px;
    color: ${({ theme }) => theme.color.third};
    background: ${({ background }) => background ?? '#fff'};
  }

  input:focus-visible,
  select:focus-visible {
    outline: 0;
  }
`;

export const CustomInputLabel = styled.p`
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #6e6e77;
  font-weight: 600;
`;
