import styled from 'styled-components';

export const OrderContainerFluid = styled.div<{ background?: string }>`
  width: 100%;
  padding: 0 15px;
  background: ${({ background, theme }) => background ?? theme.background.secondary};
`;

export const OrderContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;
  position: relative;
`;

export const OrderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  padding: 24px;
  background: #fff;

  h1 {
    font-size: 1.3rem;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 1rem;
    color: ${({ theme }) => theme.color.third};
    font-weight: 600;
    margin-bottom: 4px;
  }

  h3 {
    font-size: 1rem;
    color: ${({ theme }) => theme.color.third};
    font-weight: 300;
    margin-bottom: 8px;
  }

  button {
    padding: 0 24px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 0;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;

    svg {
      margin-left: 4px;
    }
  }
`;
