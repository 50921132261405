import React, { type ReactElement } from 'react';
import { CustomButtonContainer } from '../styles/components/button';

interface CustomButtomProps {
  children: ReactElement;
  background?: string;
  margin?: boolean;
}

const CustomButtom: React.FC<CustomButtomProps> = ({ children, background, margin }) => {
  return (
    <CustomButtonContainer margin={margin} background={background}>
      {children}
    </CustomButtonContainer>
  );
};

export default CustomButtom;
