import React, { useState, type ReactNode, useEffect, type SyntheticEvent, createRef } from 'react';
import InputMask from 'react-input-mask';
import { type TextResponse } from '../interfaces/text';
import Loading from '../components/loading';
import ApplicationApi from '../api/config';
import Row from '../components/row';
import Column from '../components/column';
import CustomInput from '../components/input';
import CustomButtom from '../components/button';
import { primaryTheme } from '../styles/theme';
import Alert, { AlertTypesValue, type AlertTypes } from '../components/alert';
import { type ResumeBody } from '../interfaces/resume';
import { type UploadResponse } from '../interfaces/upload';
import { ResumeBanner, ResumeContainer, ResumeContainerFluid } from '../styles/pages/resume';
import { CustomInputLabel } from '../styles/components/input';
import { FaUpload } from 'react-icons/fa6';

export default function Resumes(): ReactNode {
  const [text, setText] = useState<TextResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [alerts, setAlerts] = useState<AlertTypes>();
  const [fileList, setFileList] = useState<FileList | null>(null);
  const [body, setBody] = useState<ResumeBody>({ email: '', name: '', phone: '', role: '', uploadId: null });

  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    const getText = async (): Promise<void> => {
      setLoading(true);

      try {
        const { data } = await ApplicationApi.get<TextResponse>(`textos/trabalhe-conosco`);

        document.title = `Drogaria Malu :: ${data.title}`;

        setText(data);
      } catch {}

      setLoading(false);
    };

    getText().catch(() => {});
  }, []);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      const files = inputRef.current?.files;

      if (files === undefined || files === null) throw new Error('Arquivo inválido');

      formData.append('file', files[0]);

      const { data } = await ApplicationApi.post<UploadResponse>('uploads/curriculos', formData);

      await ApplicationApi.post('curriculos', { ...body, uploadId: data.id });

      setAlerts({
        ...AlertTypesValue.success,
        content: 'Seu currículo foi enviado com sucesso. Em breve um de nossos atendentes entrará em contato.',
      });
    } catch {
      setAlerts({ ...AlertTypesValue.error, content: 'Não foi possível enviar currículo, tente novamente.' });
    }

    setLoading(false);
  };

  return (
    <>
      {text?.textImages !== null ? (
        <ResumeBanner>
          <img src={text?.textImages.uploads.origin} />
        </ResumeBanner>
      ) : (
        <></>
      )}
      <ResumeContainerFluid>
        <ResumeContainer>
          {loading ? (
            <Loading />
          ) : (
            <>
              <h1>{text?.title}</h1>
              {text?.text !== undefined ? <div dangerouslySetInnerHTML={{ __html: text.text ?? '' }} /> : <></>}
              {alerts !== undefined ? (
                <Alert type={alerts} />
              ) : (
                <form onSubmit={submitForm}>
                  <Row>
                    <Column xl={4}>
                      <CustomInput label="Digite seu nome" margin>
                        <input
                          onChange={e => {
                            setBody({ ...body, name: e.target.value });
                          }}
                          required
                        />
                      </CustomInput>
                    </Column>
                    <Column xl={4}>
                      <CustomInput label="Digite seu E-mail" margin>
                        <input
                          onChange={e => {
                            setBody({ ...body, email: e.target.value });
                          }}
                          type="email"
                          required
                        />
                      </CustomInput>
                    </Column>
                    <Column xl={4}>
                      <CustomInput label="Digite seu Telefone" margin>
                        <InputMask
                          mask="(99) 99999-9999"
                          onChange={e => {
                            setBody({ ...body, phone: e.target.value });
                          }}
                          required
                        />
                      </CustomInput>
                    </Column>
                    <Column xl={6}>
                      <CustomInput label="Cargo" margin>
                        <input
                          onChange={e => {
                            setBody({ ...body, role: e.target.value });
                          }}
                          required
                        />
                      </CustomInput>
                    </Column>
                    <Column xl={6}>
                      <input
                        type="file"
                        ref={inputRef}
                        onChange={e => {
                          setFileList(e.target.files);
                        }}
                      />
                      <CustomInputLabel>Currículo</CustomInputLabel>
                      <CustomButtom margin>
                        <button type="button" onClick={() => inputRef.current?.click()}>
                          <FaUpload /> {fileList !== null ? fileList[0].name : 'Selecionar'}
                        </button>
                      </CustomButtom>
                    </Column>
                    <Column xl={2} lg={4} md={6} sm={6}>
                      <CustomButtom background={primaryTheme.background.third}>
                        <button
                          type="reset"
                          onClick={() => {
                            if (inputRef.current !== null) {
                              inputRef.current.files = null;
                              setFileList(null);
                            }
                          }}
                        >
                          Limpar
                        </button>
                      </CustomButtom>
                    </Column>
                    <Column xl={2} lg={4} md={6} sm={6}>
                      <CustomButtom>
                        <button type="submit">Enviar</button>
                      </CustomButtom>
                    </Column>
                  </Row>
                </form>
              )}
            </>
          )}
        </ResumeContainer>
      </ResumeContainerFluid>
    </>
  );
}
