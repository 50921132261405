import styled from 'styled-components';

export const PromotionContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
`;
