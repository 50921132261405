import React from 'react';
import { LoadingContainer } from '../styles/components/loading';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface LoadingProps {
  radius?: number;
}

const Loading: React.FC<LoadingProps> = ({ radius }) => {
  return (
    <LoadingContainer radius={radius}>
      <AiOutlineLoading3Quarters />
    </LoadingContainer>
  );
};

export default Loading;
