import styled from 'styled-components';

export const ProductBox = styled.div`
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  transition: box-shadow 0.2s linear;
  margin-bottom: 12px;

  &:hover {
    -webkit-box-shadow: 0px 0px 40px -21px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 40px -21px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 40px -21px rgba(0, 0, 0, 0.75);
  }
`;

export const ProductBoxOffContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 27px;
`;

export const ProductBoxOff = styled.div`
  background: #fefa39;
  padding: 4px 24px;
  color: ${({ theme }) => theme.background.primary};
  text-transform: uppercase;
  text-wrap: nowrap;
  font-weight: 600;
`;

export const ProductBannerContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  svg {
    font-size: 4rem;
    color: ${({ theme }) => theme.color.third};
  }

  img {
    width: auto;
    height: 90%;
  }
`;

export const ProductTitle = styled.div`
  height: 40px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.third};
  display: flex;
  align-items: center;
  text-align: left;

  @media (min-width: 1200px) {
    font-size: 0.7rem;
  }

  @media (min-width: 1440px) {
    font-size: 0.8rem;
  }
`;

export const ProductSubtitle = styled.div`
  font-weight: 300;
  text-decoration: line-through;
  color: ${({ theme }) => theme.color.third};
  height: 19px;

  @media (min-width: 1200px) {
    font-size: 0.7rem;
  }

  @media (min-width: 1440px) {
    font-size: 0.8rem;
  }
`;

export const ProductPrice = styled.div`
  font-weight: bold;
  margin-bottom: 8px;

  @media (min-width: 1200px) {
    font-size: 1rem;
  }

  @media (min-width: 1440px) {
    font-size: 1.2rem;
  }
`;
