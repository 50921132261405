import React, { useEffect, useState } from 'react';
import { TextBanner, TextContainer, TextContainerFluid } from '../styles/pages/text';
import ApplicationApi from '../api/config';
import { type TextResponse } from '../interfaces/text';
import { useParams } from 'react-router-dom';
import Alert, { AlertTypesValue } from '../components/alert';
import Loading from '../components/loading';

const Text: React.FC = () => {
  const { slug } = useParams();

  const [loading, setLoading] = useState<boolean>(true);

  const [text, setText] = useState<TextResponse>();

  useEffect(() => {
    setText(undefined);

    const searchTextBySlug = async (): Promise<void> => {
      setLoading(true);

      try {
        const { data } = await ApplicationApi.get<TextResponse>(`textos/${slug}`);

        document.title = `Drogaria Malu :: ${data.title}`;

        setText(data);
      } catch {}

      setLoading(false);
    };

    searchTextBySlug().catch(() => {});
  }, [slug]);

  return (
    <>
      {text?.textImages !== null ? (
        <TextBanner>
          <img src={text?.textImages.uploads.origin} />
        </TextBanner>
      ) : (
        <></>
      )}
      <TextContainerFluid>
        <TextContainer>
          {loading ? (
            <Loading />
          ) : (
            <>
              {text !== undefined ? (
                <>
                  <h1>{text.title}</h1>
                  {text.text !== null ? (
                    <div dangerouslySetInnerHTML={{ __html: text.text }} />
                  ) : (
                    <Alert type={{ ...AlertTypesValue.warning, content: 'Aguardando inserção de conteúdo.' }} />
                  )}
                </>
              ) : (
                <Alert
                  type={{ ...AlertTypesValue.warning, content: 'Não foi possível encontrar a página solicitada.' }}
                />
              )}
            </>
          )}
        </TextContainer>
      </TextContainerFluid>
    </>
  );
};

export default Text;
