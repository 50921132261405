import styled, { keyframes } from 'styled-components';

interface PlaceholderContainerProps {
  height: number;
  margin?: number;
  radius?: number;
}

const opacityAnimation = keyframes`
  0%, 100% {
    opacity: 100%
  }

  50% {
    opacity: 70%;
  }
`;

export const PlaceholderContainer = styled.div<PlaceholderContainerProps>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  margin-bottom: ${({ margin }) => (margin !== undefined ? `${margin}px` : '12px')};
  background: ${({ theme }) => theme.background.primary};
  border-radius: ${({ radius }) => (radius !== undefined ? `${radius}px` : '8px')};
  animation: ${opacityAnimation} 3s linear infinite;
`;
