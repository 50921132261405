import React, { type ReactElement } from 'react';
import { CustomInputContainer, CustomInputLabel } from '../styles/components/input';

interface CustomInputProps {
  children: ReactElement;
  background?: string;
  border?: boolean;
  borderColor?: string;
  label?: string;
  margin?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({ children, background, border, borderColor, label, margin }) => {
  return (
    <>
      {label !== undefined ? <CustomInputLabel>{label}</CustomInputLabel> : <></>}
      <CustomInputContainer margin={margin} background={background} border={border} borderColor={borderColor}>
        {children}
      </CustomInputContainer>
    </>
  );
};

export default CustomInput;
