import React, { useState } from 'react';
import {
  ProductContainer,
  ProductDiscountBox,
  ProductDiscountContainer,
  ProductBannerContainer,
  ProductTitleContainer,
  ProductButton,
  ProductQuantityContainer,
  ProductOfferBox,
} from '../../styles/pages/includes/product';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import Column from '../../components/column';
import Row from '../../components/row';
import { LuImageOff, LuShoppingBasket } from 'react-icons/lu';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { type ProductResponse } from '../../interfaces/product';
import { formatMoney } from '../../helpers/formatNumber';
import { useCart } from '../../hooks/useCart';
import Decimal from 'decimal.js';
import { useAuth } from '../../hooks/useAuth';
import ApplicationApi from '../../api/config';
import Loading from '../../components/loading';
import { ProductRecipeTypes } from '../../enums/product';
import { useNavigate } from 'react-router-dom';

interface ProductProps {
  data: ProductResponse;
}

const Product: React.FC<ProductProps> = ({ data }) => {
  const navigate = useNavigate();
  const { user, token, favoriteProducts, setFavoriteProducts, setLoginFancyboxActive } = useAuth();
  const { addToCart, setCartActive } = useCart();

  const [quantity, setQuantity] = useState<number>(1);

  const [loading, setLoading] = useState<boolean>(false);

  const addFavorite = (productId: number): void => {
    if (user !== null) {
      setLoading(true);

      ApplicationApi.post(
        `usuarios/${user?.id}/favoritar/produtos/${productId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      )
        .then(() => {
          setFavoriteProducts(products => [...products, { productId, userId: user?.id }]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoginFancyboxActive(true);
    }
  };

  const removeFavorite = (productId: number): void => {
    setLoading(true);

    ApplicationApi.delete(`usuarios/${user?.id}/favoritar/produtos/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setFavoriteProducts(products => products.filter(product => product.productId !== productId));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ProductContainer>
      {loading ? <Loading /> : <></>}
      <ProductDiscountContainer>
        {data.offerProducts !== undefined && data.offerProducts.length > 0 ? (
          <ProductOfferBox>{data.offerProducts[0].offers.name}</ProductOfferBox>
        ) : (
          <>
            {data.newPrice !== null &&
            Decimal.sub(100, Decimal.div(Decimal.mul(data.newPrice, 100), data.price)).toNumber() > 0 ? (
              <ProductDiscountBox>
                {Decimal.sub(100, Decimal.div(Decimal.mul(data.newPrice, 100), data.price)).toFixed(0)}% OFF
              </ProductDiscountBox>
            ) : (
              <></>
            )}
          </>
        )}
        {favoriteProducts.some(favorite => favorite.productId === data.id) ? (
          <AiFillHeart
            onClick={() => {
              removeFavorite(data.id);
            }}
          />
        ) : (
          <AiOutlineHeart
            onClick={() => {
              addFavorite(data.id);
            }}
          />
        )}
      </ProductDiscountContainer>
      <ProductBannerContainer
        onClick={() => {
          navigate(`/produtos/${data.slug}`);
        }}
      >
        {data.productImages !== null && data.productImages.length > 0 ? (
          <img src={data.productImages[0].uploads.origin} />
        ) : (
          <LuImageOff />
        )}
      </ProductBannerContainer>
      <ProductTitleContainer
        onClick={() => {
          navigate(`/produtos/${data.slug}`);
        }}
      >
        <h1>{data.name.length > 40 ? `${data.name.substring(0, 40)}...` : data.name}</h1>
        {data.newPrice !== null &&
        Decimal.sub(100, Decimal.div(Decimal.mul(data.newPrice, 100), data.price)).toNumber() > 0 ? (
          <h2>R$ {formatMoney(data.price)}</h2>
        ) : (
          <></>
        )}
      </ProductTitleContainer>
      {data.newPrice !== null &&
      Decimal.sub(100, Decimal.div(Decimal.mul(data.newPrice, 100), data.price)).toNumber() > 0 ? (
        <h1>R$ {formatMoney(data.newPrice)}</h1>
      ) : (
        <h1>R$ {formatMoney(data.price)}</h1>
      )}
      <Row>
        <Column xl={5} lg={5} md={5} sm={5} center>
          <ProductQuantityContainer>
            <button
              onClick={() => {
                setQuantity(quantity === 1 ? 1 : quantity - 1);
              }}
            >
              <FaMinus />
            </button>
            <p>{quantity}</p>
            <button
              onClick={() => {
                setQuantity(quantity + 1);
              }}
            >
              <FaPlus />
            </button>
          </ProductQuantityContainer>
        </Column>
        <Column xl={7} lg={7} md={7} sm={7}>
          <ProductButton>
            <button
              onClick={() => {
                addToCart({
                  code: data.code,
                  id: data.id,
                  image: data.productImages !== null ? data.productImages[0].uploads.origin : null,
                  name: data.name,
                  price: data.newPrice ?? data.price,
                  specialRecipe: !![ProductRecipeTypes.COM_RETENCAO, ProductRecipeTypes.COM_RETENCAO_ESPECIAL].includes(
                    data.recipeId,
                  ),
                  quantity,
                });
                setCartActive(true);
              }}
            >
              COMPRE <LuShoppingBasket />
            </button>
          </ProductButton>
        </Column>
      </Row>
    </ProductContainer>
  );
};

export default Product;
