import React, { useEffect, useState } from 'react';
import { ActivationContainer, ActivationContainerFluid, ActivationContentContainer } from '../styles/pages/activation';
import Loading from '../components/loading';
import ApplicationApi from '../api/config';
import { Navigate, useParams } from 'react-router-dom';
import { type ActivationResponse } from '../interfaces/activation';
import { FaArrowRight } from 'react-icons/fa6';
import { useAuth } from '../hooks/useAuth';

const Activation: React.FC = () => {
  const { usuario, codigo } = useParams();
  const [loading, setLoading] = useState(true);
  const { setLoginFancyboxActive } = useAuth();

  const [activation, setActivation] = useState<ActivationResponse>();

  useEffect(() => {
    const validateUser = async (): Promise<void> => {
      setLoading(true);

      try {
        const { data } = await ApplicationApi.get<ActivationResponse>(`usuarios/${usuario}/validacao/${codigo}`);

        setActivation(data);
      } catch {
        setActivation(undefined);
      }

      setLoading(false);
    };

    void validateUser();
  }, []);

  return (
    <ActivationContainerFluid>
      <ActivationContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            {activation !== undefined ? (
              <ActivationContentContainer>
                <h1>Sua conta foi ativada com sucesso!</h1>
                <h3>Clique no link abaixo para entrar em sua conta e aproveitar todas as nossas ofertas.</h3>
                <button
                  onClick={() => {
                    setLoginFancyboxActive(true);
                  }}
                >
                  Acessar <FaArrowRight />
                </button>
              </ActivationContentContainer>
            ) : (
              <Navigate to="/home" replace={true} />
            )}
          </>
        )}
      </ActivationContainer>
    </ActivationContainerFluid>
  );
};

export default Activation;
