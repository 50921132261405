import React, { type SyntheticEvent, useState } from 'react';
import {
  CartBox,
  CartDiscountContainer,
  CartImageBox,
  CartInputContainer,
  CartItemBox,
  CartLabelContainer,
  CartLabelDiscountContainer,
} from '../../styles/pages/checkout';
import Row from '../../components/row';
import Column from '../../components/column';
import { useCart } from '../../hooks/useCart';
import { LuImageOff } from 'react-icons/lu';
import { formatMoney } from '../../helpers/formatNumber';
import Decimal from 'decimal.js';
import { RiCoupon3Line } from 'react-icons/ri';
import ApplicationApi from '../../api/config';
import { type CouponResponse } from '../../interfaces/coupon';
import Loading from '../../components/loading';
import { FaCheck } from 'react-icons/fa6';

const Cart: React.FC = () => {
  const { cart, addDiscountCoupon } = useCart();

  const [loading, setLoading] = useState<boolean>(false);

  const [couponCode, setCouponCode] = useState<string>();
  const [validCoupon, setValidCoupon] = useState<boolean>();

  const validateCoupon = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await ApplicationApi.get<CouponResponse>(`cupons/validacao/${couponCode}`);

      addDiscountCoupon(data);
    } catch {
      setValidCoupon(false);
      setCouponCode('');
    }

    setLoading(false);
  };

  return (
    <>
      {cart !== null ? (
        <CartBox>
          {loading ? <Loading /> : <></>}
          <h1>Detalhes do pedido</h1>
          {cart.order.products.map((product, i) => (
            <CartItemBox key={i}>
              <Row>
                <Column xl={2}>
                  <CartImageBox>{product.image !== null ? <img src={product.image} /> : <LuImageOff />}</CartImageBox>
                </Column>
                <Column xl={10}>
                  <h2>{product.name}</h2>
                  <h3>Disponível</h3>
                  <CartLabelContainer>
                    <h4>{product.quantity} unidade(s)</h4>
                    {cart.order.promotion !== undefined ? (
                      <p>PROMOÇÃO</p>
                    ) : (
                      <p>R$ {formatMoney(Decimal.mul(product.quantity, product.price).toFixed(2))}</p>
                    )}
                  </CartLabelContainer>
                </Column>
              </Row>
            </CartItemBox>
          ))}
          {cart.order.offer !== undefined ? (
            <>
              {cart.order.offer.map((offer, i) => (
                <>
                  {offer.products.map((offerProduct, u) => (
                    <CartItemBox key={u}>
                      <Row>
                        <Column xl={2}>
                          <CartImageBox>
                            <img src={offer.image} />
                          </CartImageBox>
                        </Column>
                        <Column xl={10}>
                          <h2>{offerProduct.name}</h2>
                          <h3>Disponível</h3>
                          <CartLabelContainer>
                            <h4>{offerProduct.quantity} unidade(s)</h4>
                            <p>R$ {formatMoney(Decimal.mul(offerProduct.quantity, offerProduct.price).toFixed(2))}</p>
                          </CartLabelContainer>
                        </Column>
                      </Row>
                    </CartItemBox>
                  ))}
                </>
              ))}
            </>
          ) : (
            <></>
          )}
          <CartLabelContainer>
            <h5>Subtotal</h5>
            <h5>R$ {formatMoney(cart.order.subtotal)}</h5>
          </CartLabelContainer>
          {cart.order.deliveryFee !== undefined ? (
            <CartLabelContainer>
              <h5>Frete</h5>
              <h5>R$ {formatMoney(cart.order.deliveryFee)}</h5>
            </CartLabelContainer>
          ) : (
            <></>
          )}
          {cart.order.discount !== undefined ? (
            <CartLabelDiscountContainer>
              <h4>
                Cupom <span>({cart.order.discount.name})</span>
              </h4>
              <h5>- R$ {formatMoney(cart.order.amountWithoutDiscount ?? '0.00')}</h5>
            </CartLabelDiscountContainer>
          ) : (
            <></>
          )}
          <CartLabelContainer>
            <h5>Total</h5>
            <h5>R$ {formatMoney(cart.order.amount)}</h5>
          </CartLabelContainer>
          {cart.order.discount !== undefined ? (
            <CartDiscountContainer>
              <h1>
                Cupom Aplicado <FaCheck />
              </h1>
              <h2>{cart.order.discount.name}</h2>
            </CartDiscountContainer>
          ) : (
            <form onSubmit={validateCoupon}>
              <p>Possui algum cupom de desconto?</p>
              <CartInputContainer valid={validCoupon}>
                <input
                  value={couponCode}
                  onChange={e => {
                    setCouponCode(e.target.value);
                  }}
                  placeholder="Insira seu cupom de desconto"
                  required
                />
                <button type="submit">
                  <RiCoupon3Line />
                </button>
              </CartInputContainer>
            </form>
          )}
        </CartBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default Cart;
