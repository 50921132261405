import styled from 'styled-components';

interface ColumnContainerProps {
  xl?: number;
  lg?: number;
  md?: number;
  sm?: number;
  center?: boolean;
}

export const ColumnContainer = styled.div<ColumnContainerProps>`
  padding: 0 12px;
  display: ${({ center }) => (center !== undefined && center ? 'flex' : 'block')};
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (min-width: 1200px) {
    width: calc((${({ xl }) => xl} * 100%) / 12);
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    width: calc((${({ lg, xl }) => lg ?? 12} * 100%) / 12);
  }

  @media (min-width: 420px) and (max-width: 767px) {
    width: calc((${({ md, lg, xl }) => md ?? 12} * 100%) / 12);
  }

  @media (max-width: 419px) {
    width: calc((${({ sm, md, lg, xl }) => sm ?? 12} * 100%) / 12);
  }
`;
