import React, { useEffect } from 'react';
import Header from './includes/header';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Footer from './includes/footer';
import { useAuth } from '../hooks/useAuth';

const PrivateLayout: React.FC = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  if (user === null) return <Navigate to="/" replace />;

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default PrivateLayout;
