import React from 'react';
import { PlaceholderContainer } from '../styles/components/placeholder';

interface PlaceholderProps {
  height: number;
  margin?: number;
  radius?: number;
}

const Placeholder: React.FC<PlaceholderProps> = ({ height, margin, radius }) => {
  return <PlaceholderContainer height={height} margin={margin} radius={radius} />;
};

export default Placeholder;
