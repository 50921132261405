import styled from 'styled-components';

export const ProductsContainerFluid = styled.div<{ background?: string }>`
  width: 100%;
  padding: 0 15px;
  background: ${({ background }) => background ?? '#fff'};
`;

export const ProductContainerTitle = styled.h1`
  font-weight: 400 !important;
  color: ${({ theme }) => theme.color.third};
  margin-bottom: 12px;

  b {
    font-weight: 800;
  }
`;

export const ProductsContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;
  position: relative;

  h1 {
    font-weight: 600;
    font-size: 1.5rem;
  }
`;

export const ProductsContainerSidebar = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-top: 24px;
  position: relative;

  h1 {
    font-weight: 600;
    font-size: 1.5rem;
  }
`;

export const ProductNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.third};
  min-height: 300px;

  svg {
    font-size: 4rem;
    margin-bottom: 12px;
  }

  button {
    padding: 0 22px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    border-radius: 8px;
    background: ${({ theme }) => theme.background.primary};
    border: 0;
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;

    svg {
      font-size: 1rem;
      margin-bottom: 0;
      margin-left: 8px;
    }
  }
`;

export const ProductsAdvertisingContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-bottom: 24px;
`;

export const ProductsInfoContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-bottom: 24px;

  h1 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
`;

export const ProductHighlightsContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-bottom: 24px;

  h1 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.background.primary};
  }
`;

export const ProductDescriptionContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-bottom: 24px;

  h1 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
`;

export const ProductSlugTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.7rem;
  color: #9b9b9b;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    font-size: 0.9rem;
  }

  svg {
    font-size: 0.8rem;
    margin: 0 8px;
  }
`;

export const ProductInfoContainer = styled.div`
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 18px;
  }

  p {
    text-align: justify;
    margin-bottom: 12px;
  }
`;

export const ProductButtonContainer = styled.a`
  padding: 10px 14px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #077ca8;
  background: #fff;
  color: #077ca8;
  cursor: pointer;
  transition: all 0.1s ease;
  margin: 12px 0;

  &:hover {
    background: #077ca8;
    color: #eee;
  }

  svg {
    font-size: 1.5rem;
  }
`;

export const ProductImagesContainer = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    margin-bottom: 12px;
    flex-direction: column;
  }
`;

export const ProductImagesBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;

  @media (max-width: 1200px) {
    flex-direction: row;
  }

  div {
    height: 80px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e1e1e1;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 8px;

    @media (max-width: 1200px) {
      margin-right: 8px;
    }

    img {
      height: auto;
      width: 95%;
    }
  }
`;

export const ProductMainImageBox = styled.div`
  border: 1px solid #e1e1e1;
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;

  img {
    max-width: 90%;
    max-height: 90%;
  }

  svg {
    color: ${({ theme }) => theme.color.third};
    font-size: 3rem;
  }
`;

export const ProductCartContainer = styled.div`
  width: 100%;
  padding: 18px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background: #fff;
  position: relative;

  h1 {
    font-size: 1.3rem;
    margin-bottom: 12px;
    font-weight: 300;

    b {
      font-weight: 800;
    }
  }
`;

export const ProductOfferContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;

  p {
    text-decoration: line-through;
    color: ${({ theme }) => theme.color.third};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 30px;
    border-radius: 6px;
    background: #fdf64d;
    font-weight: 600;
  }
`;

export const ProductCheckoutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: 2rem;
    font-weight: 600;
  }

  div {
    display: flex;
    align-items: center;

    button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #e1e1e1;
      background: #fff;
      cursor: pointer;

      svg {
        color: #077ca8;
      }
    }

    p {
      margin: 0 12px;
    }
  }
`;

export const ProductCheckoutButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  button:last-child {
    margin-top: 4px;
  }

  button {
    width: 180px;
    padding: 0 12px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    color: ${({ theme }) => theme.color.primary};
    border: 0;
    background: #077ca8;
    cursor: pointer;

    svg {
      font-size: 1.5rem;
    }

    p {
      text-align: left;
      font-size: 0.7rem;
    }
  }
`;

export const ProductDiscountContainer = styled.div<{ valid?: boolean }>`
  width: 100%;
  padding: 12px;
  background: #e1e1e1;
  border-radius: 6px;
  margin-bottom: 12px;

  p {
    font-size: 0.8rem;
    margin-bottom: 8px;
    font-weight: 600;
  }

  div {
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    padding: 4px;
    background: #fff;
    border: ${({ valid }) => (valid !== undefined && !valid ? '1px solid #ff0000' : '0')};

    button {
      width: 34px;
      cursor: pointer;
      color: #077ca8;
      border: 0;
      background: #fff;
    }
  }

  input {
    width: 100%;
    height: 34px;
    border: 0;
    padding: 0 12px;
    outline: 0;
  }
`;

export const ProductDiscountContainerActive = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #e1e1e1;
  border-radius: 6px;
  margin-bottom: 12px;

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 0.8rem;

    svg {
      color: #198754;
    }
  }

  h2 {
    color: ${({ theme }) => theme.color.third};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const ProductDeliveryTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  h2 {
    font-size: 1rem;
    font-weight: 600;
  }

  a {
    color: #077ca8;
  }
`;

export const ProductDeliveryInputContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.third};
  border-radius: 8px;
  overflow: hidden;
  padding: 2px;
  display: flex;

  input {
    width: 100%;
    border: 0;
    height: 48px;
    padding: 0 12px;
    outline: 0;
  }

  button {
    width: 60px;
    border-radius: 8px;
    border: 0;
    background: ${({ theme }) => theme.color.third};
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const DeliveryBox = styled.div<{ fastDelivery: boolean }>`
  display: block;
  width: 100%;
  background: ${({ fastDelivery, theme }) => (fastDelivery ? theme.background.primary : '#e1e1e1')};
  border-radius: 6px;
  padding: 8px;
  margin-top: 8px;
  position: relative;
  color: ${({ fastDelivery, theme }) => (fastDelivery ? theme.color.primary : theme.color.secondary)};

  div {
    position: absolute;
    right: 8px;
    bottom: 8px;
    font-size: 0.7rem;
    font-weight: 800;
    color: ${({ theme }) => theme.background.third};
    display: flex;
    align-items: center;

    svg {
      font-size: 1.1rem;
      margin-right: 4px;
    }
  }

  h1 {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  h2 {
    font-size: 0.8rem;
    color: ${({ fastDelivery, theme }) => (fastDelivery ? theme.color.primary : theme.color.third)};
  }

  h3 {
    font-size: 0.8rem;
  }
`;

export const ProductBrandContainer = styled.div`
  padding: 12px 48px;
  border: 1px solid #e1e1e1;
  background: #fff;
  border-radius: 8px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: 100%;
    height: 54px;
    border: 0;
    border-radius: 8px;
    margin-bottom: 22px;
    background: #077ca8;
    color: #eee;
    cursor: pointer;
    text-transform: uppercase;
  }
`;

export const ProductBrandBox = styled.div`
  display: flex;
  align-items: center;
  height: calc(100% - 152px);
  justify-content: center;

  h1 {
    text-transform: uppercase;
  }

  img {
    max-width: 30%;
    max-height: 30%;
  }
`;

export const ProductEspecificationContainer = styled.div`
  padding: 24px;
  border: 1px solid #e1e1e1;
  background: #fff;
  border-radius: 8px;
  max-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1200px) {
    margin-bottom: 12px;
  }
`;

export const ProductEspecificationBox = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 54px;
  padding: 0 12px;
  border-radius: 8px;
  background: ${({ background }) => background};
`;

export const ProductDescriptionBox = styled.div`
  padding: 24px;
  border: 1px solid #e1e1e1;
  background: #fff;
  border-radius: 8px;
`;

export const ProductPaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${({ theme }) => theme.color.third};
    font-wieght: 0.8rem;
  }
`;

export const ProductPaginationBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductPaginationItem = styled.div<{ active: boolean }>`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active, theme }) => (active ? theme.color.primary : theme.color.secondary)};
  cursor: pointer;
  border-radius: 50%;
  background: ${({ active, theme }) => (active ? theme.background.primary : '#fff')};
  font-size: 0.7rem;
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
`;

export const ProductCoutdownContainer = styled.div`
  width: 100%;
  background: #98ffca;
  padding: 22px 15px;
`;

export const ProductCoutdownBox = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }
  }

  h1 {
    font-weight: 800;
    color: ${({ theme }) => theme.background.primary};
  }

  h2 {
    font-weight: 400;
    color: ${({ theme }) => theme.background.primary};

    @media (max-width: 768px) {
      & {
        margin-bottom: 12px;
      }
    }
  }

  button {
    height: 50px;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    color: #fff;
    background: ${({ theme }) => theme.background.primary};
    border: 0;
    font-size: 1rem;
    text-wrap: wrap;
    cursor: pointer;

    p {
      text-align: left;
      font-weight: 600;
    }

    svg {
      font-size: 2.5rem;
      margin-left: 8px;
    }
  }
`;

export const ProductCountDownClockContainer = styled.div`
  display: flex;
  align-items: center;
  color: #fff;

  @media (max-width: 768px) {
    & {
      margin-bottom: 12px;
    }
  }

  svg {
    font-size: 4rem;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    h2 {
      color: #fff;
      font-size: 2rem;
    }
  }
`;
