import styled from 'styled-components';

export const AdvertisingContainer = styled.div`
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
`;
