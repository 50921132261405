import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { type OrderResponse } from '../interfaces/order';
import ApplicationApi from '../api/config';
import { useAuth } from '../hooks/useAuth';
import { OrderContainer, OrderContainerFluid, OrderContentContainer } from '../styles/pages/order';
import Loading from '../components/loading';
import { FaArrowRight } from 'react-icons/fa6';

const Order: React.FC = () => {
  const { codigo } = useParams();
  const { user, token } = useAuth();
  const navigate = useNavigate();

  document.title = `Drogaria Malu :: Pedido criado com sucesso`;

  const [loading, setLoading] = useState<boolean>(true);

  const [order, setOrder] = useState<OrderResponse>();

  useEffect(() => {
    ApplicationApi.get<OrderResponse>(`pedidos/${codigo}/usuarios/${user?.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setOrder(data);
        setLoading(false);
      })
      .catch(() => {
        navigate('/');
      });
  }, [codigo]);

  return (
    <OrderContainerFluid>
      <OrderContainer>
        {loading ? <Loading /> : <></>}
        {order !== undefined ? (
          <OrderContentContainer>
            <h1>Pedido #{order.code} criado com sucesso.</h1>
            <h2>
              Você receberá informações em seu e-mail para acompanhar o processo de confirmação de pagamento e entrega.
            </h2>
            <h3>Você pode ver detalhes de seu pedido em sua conta, basta clicar no link abaixo.</h3>
            <button
              onClick={() => {
                navigate('/minha-conta?pagina=pedidos');
              }}
            >
              Acessar meus pedidos <FaArrowRight />
            </button>
          </OrderContentContainer>
        ) : (
          <></>
        )}
      </OrderContainer>
    </OrderContainerFluid>
  );
};

export default Order;
