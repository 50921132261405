import React, { useEffect, useState } from 'react';
import {
  ButtonNextStepContainer,
  CheckoutAdminAddressContainer,
  CheckoutContainer,
  CheckoutContainerFluid,
  CheckoutCountdown,
  CheckoutCountdownBox,
  CheckoutCountdownContainer,
  CheckoutCountdownTitle,
  CheckoutDeliveryBox,
  CheckoutDeliveryButtonContainer,
  CheckoutDeliveryContainer,
  CheckoutDeliveryContent,
  CheckoutFastDeliveryContainer,
  CheckoutHeaderStatuBox,
  CheckoutHeaderStatuLabel,
  CheckoutHeaderStatuLine,
  CheckoutInfoContainer,
  CheckoutLoginActive,
  CheckoutNoContentContainer,
  CheckoutOrderHeaderContainer,
  CheckoutPaymentContainer,
  CheckoutPaymentOptionBox,
  CheckoutPaymentOptionContainer,
  CheckoutPaymentOptionContent,
  CheckoutReviewBox,
  CheckoutReviewContainer,
  CheckoutReviewDeliveryButton,
  CheckoutReviewDeliveryContainer,
  CheckoutReviewImageContainer,
  CheckoutReviewInfoContainer,
  CheckoutReviewItem,
  CheckoutReviewItemsContainer,
  CheckoutReviewPaymentContainer,
  CheckoutSecurityContainer,
  UserActiveAddressContainer,
  UserAddressContainer,
  UserPaymentItemContainer,
} from '../../styles/pages/checkout';
import { BsShieldLock, BsCreditCardFill } from 'react-icons/bs';
import { AiFillCheckCircle, AiOutlineBarcode, AiOutlineClockCircle } from 'react-icons/ai';
import Row from '../../components/row';
import Column from '../../components/column';
import Cart from './cart';
import { HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { HiMiniUser, HiMiniMapPin } from 'react-icons/hi2';
import { primaryTheme } from '../../styles/theme';
import Login from '../includes/login';
import { useCart } from '../../hooks/useCart';
import CustomButtom from '../../components/button';
import { FaBagShopping, FaListCheck, FaPix, FaPlus } from 'react-icons/fa6';
import { BiCircle, BiCreditCard } from 'react-icons/bi';
import { TbTruckDelivery } from 'react-icons/tb';
import { useAuth } from '../../hooks/useAuth';
import { VscSignOut } from 'react-icons/vsc';
import ApplicationApi, { publicKey } from '../../api/config';
import { type UserPaymentsResponse, type UserAddressesResponse } from '../../interfaces/user';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading';
import Alert, { type AlertTypes, AlertTypesValue } from '../../components/alert';
import { type DeliveryResponse } from '../../interfaces/delivery';
import { formatMoney } from '../../helpers/formatNumber';
import { type OrderResponse, type OrderBody } from '../../interfaces/order';
import { type FreightResponse } from '../../interfaces/freight';
import { useAdmin } from '../../hooks/useAdmin';
import { LuImageOff } from 'react-icons/lu';
import Product from '../includes/product';
import { type ProductResponse } from '../../interfaces/product';
import { Encrypt } from '../../lib/pagseguro';
import Countdown from 'react-countdown';

enum ContainerTypes {
  LOGIN = 'login',
  ENTREGA = 'delivery',
  PAGAMENTO = 'payment',
  REVISAO = 'review',
}

enum ActiveDeliveryTypes {
  ENVIO = 1,
  RETIRADA = 2,
}

enum DeliveryTypes {
  ENTREGA_RAPIDA = 1,
  CORREIOS = 2,
  RETIRADA = 3,
}

enum PaymentTypes {
  CARTAO_CREDITO = 1,
  BOLETO = 2,
  PIX = 3,
  ENTREGA = 4,
}

const bodyDefaultValue = {
  delivery: {
    deliveryTypeId: DeliveryTypes.ENTREGA_RAPIDA,
  },
  payment: {
    paymentTypeId: PaymentTypes.CARTAO_CREDITO,
  },
  items: [],
};

const Checkout: React.FC = () => {
  const { cart, cartActive, setCartActive, addDeliveryFee, clearCart } = useCart();
  const { user, token, logOut } = useAuth();
  const admin = useAdmin();
  const navigate = useNavigate();

  document.title = `Drogaria Malu :: Meu carrinho`;

  const [activeContainer, setActiveContainer] = useState<ContainerTypes>(
    user !== null ? ContainerTypes.ENTREGA : ContainerTypes.LOGIN,
  );
  const [activeDeliveryType, setActiveDeliveryType] = useState<ActiveDeliveryTypes>(ActiveDeliveryTypes.ENVIO);
  const [activePaymentType, setActivePaymentType] = useState<PaymentTypes>(PaymentTypes.CARTAO_CREDITO);

  const [userAddresses, setUserAddresses] = useState<UserAddressesResponse[]>([]);
  const [userMainAddressActive, setUserMainAddressActive] = useState<UserAddressesResponse>();
  const [userPayments, setUserPayments] = useState<UserPaymentsResponse[]>([]);

  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false);
  const [deliveryLoading, setDeliveryLoading] = useState<boolean>(false);
  const [paymentLoading, setPaymentLoading] = useState<boolean>(true);

  const [checkoutAlert, setCheckoutAlert] = useState<AlertTypes>();
  const [deliveryAlert, setDeliveryAlert] = useState<AlertTypes>();

  const [activePayment, setActivePayment] = useState<UserPaymentsResponse>();
  const [observations, setObservations] = useState<string>();

  const [fastDelivery, setFastDelivery] = useState<DeliveryResponse>();
  const [freights, setFreights] = useState<FreightResponse[]>();

  const [recomendations, setRecomendations] = useState<ProductResponse[]>();

  const [body, setBody] = useState<OrderBody>(bodyDefaultValue);

  useEffect(() => {
    if (cartActive) setCartActive(false);

    const searchAddressesAndFreights = async (): Promise<void> => {
      setDeliveryLoading(true);

      try {
        const addresses = await ApplicationApi.get<UserAddressesResponse[]>(`usuarios/${user?.id}/enderecos`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUserAddresses(addresses.data);
      } catch {}

      setDeliveryLoading(false);
    };

    const searchUserPayments = async (): Promise<void> => {
      setPaymentLoading(true);

      try {
        const payments = await ApplicationApi.get<UserPaymentsResponse[]>(`usuarios/${user?.id}/pagamentos`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUserPayments(payments.data);
      } catch {}

      setPaymentLoading(false);
    };

    const searchRecomendations = async (): Promise<void> => {
      try {
        const { data } = await ApplicationApi.get<ProductResponse[]>('/produtos');

        setRecomendations(data);
      } catch {}
    };

    searchAddressesAndFreights().catch(() => {});
    searchUserPayments().catch(() => {});
    searchRecomendations().catch(() => {});
  }, []);

  useEffect(() => {
    setBody({
      ...body,
      items: cart?.order.products.map(product => ({ productId: product.id, quantity: product.quantity })) ?? [],
      offers: cart?.order.offer,
    });

    if (cart?.order.products.some(item => item.specialRecipe) ?? false) {
      setActiveDeliveryType(ActiveDeliveryTypes.RETIRADA);
    }
  }, [cart]);

  useEffect(() => {
    if (userAddresses.length > 0) {
      setUserMainAddressActive(userAddresses[0]);
    }
  }, [userAddresses]);

  useEffect(() => {
    if (activeDeliveryType === ActiveDeliveryTypes.ENVIO) {
      addDeliveryFee('0.00');
      setDeliveryLoading(true);

      setBody({
        ...body,
        delivery: {
          deliveryTypeId: DeliveryTypes.ENTREGA_RAPIDA,
          integrationId: undefined,
          serviceId: undefined,
          companyId: undefined,
          company: undefined,
          deliveryTimeMax: undefined,
          deliveryTimeMin: undefined,
          fastDeliveryId: undefined,
          price: undefined,
          userAddressId: undefined,
        },
      });

      const searchFastDeliveries = async (): Promise<void> => {
        setFastDelivery(undefined);

        try {
          if (userMainAddressActive !== undefined) {
            const fastDelivery = await ApplicationApi.get<DeliveryResponse>(
              `entregas-rapidas/cep/${userMainAddressActive.zipCode}`,
            );

            setFastDelivery(fastDelivery.data);
          }
        } catch {}
      };

      const searchFreights = async (): Promise<void> => {
        if (admin.zipCode !== null) {
          setFreights(undefined);

          try {
            if (userMainAddressActive !== undefined) {
              const freights = await ApplicationApi.post<FreightResponse[]>(`fretes/calcular`, {
                from: admin.zipCode,
                to: userMainAddressActive.zipCode,
              });

              setFreights(freights.data);
            }
          } catch {}
          setDeliveryLoading(false);
        }
      };

      searchFastDeliveries().catch(() => {});
      searchFreights().catch(() => {});
    }
  }, [userMainAddressActive, activeDeliveryType, admin]);

  useEffect(() => {
    if (activeDeliveryType === ActiveDeliveryTypes.RETIRADA) {
      addDeliveryFee('0.00');
      setBody({
        ...body,
        delivery: {
          deliveryTypeId: DeliveryTypes.RETIRADA,
          integrationId: undefined,
          company: undefined,
          companyId: undefined,
          serviceId: undefined,
          deliveryTimeMax: undefined,
          deliveryTimeMin: undefined,
          fastDeliveryId: undefined,
          price: undefined,
          userAddressId: undefined,
        },
      });
    }
    if (activeDeliveryType === ActiveDeliveryTypes.ENVIO) {
      addDeliveryFee('0.00');
      setBody({
        ...body,
        delivery: {
          deliveryTypeId: DeliveryTypes.ENTREGA_RAPIDA,
          companyId: undefined,
          company: undefined,
          serviceId: undefined,
          integrationId: undefined,
          deliveryTimeMax: undefined,
          deliveryTimeMin: undefined,
          fastDeliveryId: undefined,
          price: undefined,
          userAddressId: undefined,
        },
      });
    }
  }, [activeDeliveryType]);

  useEffect(() => {
    if (userPayments.length > 0) {
      setActivePayment(userPayments[0]);
    }
  }, [userPayments]);

  useEffect(() => {
    if (activePaymentType === PaymentTypes.CARTAO_CREDITO) {
      setBody({
        ...body,
        payment: {
          paymentTypeId: PaymentTypes.CARTAO_CREDITO,
          userPaymentId: activePayment?.id,
          observations: undefined,
        },
      });
    }
    if (activePaymentType === PaymentTypes.BOLETO) {
      setBody({
        ...body,
        payment: { paymentTypeId: PaymentTypes.BOLETO, userPaymentId: undefined, observations: undefined },
      });
    }
    if (activePaymentType === PaymentTypes.PIX) {
      setBody({
        ...body,
        payment: { paymentTypeId: PaymentTypes.PIX, userPaymentId: undefined, observations: undefined },
      });
    }
    if (activePaymentType === PaymentTypes.ENTREGA) {
      setBody({ ...body, payment: { paymentTypeId: PaymentTypes.ENTREGA, userPaymentId: undefined, observations } });
    }
  }, [activePaymentType, activePayment]);

  const checkout = async (): Promise<void> => {
    setCheckoutLoading(true);

    try {
      let encrypted: string | undefined;

      if (activePaymentType === PaymentTypes.CARTAO_CREDITO) {
        const card = Encrypt({
          publicKey,
          holder: activePayment?.name,
          number: activePayment?.cardNumber,
          expMonth: activePayment?.expirationMonth.toString(),
          expYear: activePayment?.expirationYear.toString(),
          securityCode: activePayment?.ccv.toString(),
        });

        encrypted = card.encryptedCard;
      }

      const order = await ApplicationApi.post<OrderResponse>(
        `pedidos/usuarios/${user?.id}`,
        {
          ...body,
          items: cart?.order.products.map(product => ({ productId: product.id, quantity: product.quantity })) ?? [],
          offers: cart?.order.offer,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (![PaymentTypes.PIX, PaymentTypes.ENTREGA].includes(activePaymentType)) {
        await ApplicationApi.post<OrderResponse>(
          `pedidos/${order.data.id}/usuarios/${user?.id}/checkout`,
          { encrypted },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }

      clearCart();
      setCheckoutAlert({
        ...AlertTypesValue.success,
        content: 'Pedido criado com sucesso',
      });
      navigate(`/pedidos/${order.data.code}`);
    } catch {
      setCheckoutAlert({
        ...AlertTypesValue.error,
        content: 'Não foi possível concluir seu pedido, revise as informações inseridas e tente novamente.',
      });
    }

    setCheckoutLoading(false);
  };

  const switchContainerActive = (containerToActive: ContainerTypes): void => {
    setDeliveryAlert(undefined);
    setCheckoutAlert(undefined);
    if (user !== null) {
      if (activeContainer === ContainerTypes.LOGIN) {
        setActiveContainer(containerToActive);
      }
      if (activeContainer === ContainerTypes.ENTREGA) {
        if (
          body.delivery.fastDeliveryId !== undefined ||
          body.delivery.price !== undefined ||
          body.delivery.deliveryTypeId === DeliveryTypes.RETIRADA
        ) {
          setActiveContainer(containerToActive);
        } else {
          setDeliveryAlert({ ...AlertTypesValue.error, content: 'Selecione o tipo de entrega ' });
        }
      }
      if (activeContainer === ContainerTypes.PAGAMENTO) {
        if (
          (body.payment.paymentTypeId === PaymentTypes.CARTAO_CREDITO && body.payment.userPaymentId !== undefined) ||
          [PaymentTypes.BOLETO, PaymentTypes.PIX, PaymentTypes.ENTREGA].includes(body.payment.paymentTypeId)
        ) {
          setActiveContainer(containerToActive);
        } else {
          setCheckoutAlert({ ...AlertTypesValue.error, content: 'Selecione uma forma de pagamento.' });
        }
      }
      if (activeContainer === ContainerTypes.REVISAO) {
        setActiveContainer(containerToActive);
      }
    }
  };

  const selectDelivery = (freight: FreightResponse): void => {
    addDeliveryFee(freight.price);
    setBody({
      ...body,
      delivery: {
        ...body.delivery,
        deliveryTypeId: DeliveryTypes.CORREIOS,
        integrationId: freight.id,
        serviceId: freight.id,
        companyId: freight.company.id,
        company: freight.company.name,
        deliveryTimeMax: freight.deliveryTimeMax,
        deliveryTimeMin: freight.deliveryTimeMin,
        price: freight.price,
        userAddressId: userMainAddressActive?.id,
        fastDeliveryId: undefined,
      },
    });
  };

  const selectFastDelivery = (fastDelivery: DeliveryResponse): void => {
    addDeliveryFee(fastDelivery.price);
    setBody({
      ...body,
      delivery: {
        ...body.delivery,
        deliveryTypeId: DeliveryTypes.ENTREGA_RAPIDA,
        company: undefined,
        deliveryTimeMax: undefined,
        deliveryTimeMin: undefined,
        price: undefined,
        integrationId: undefined,
        userAddressId: userMainAddressActive?.id,
        fastDeliveryId: fastDelivery.id,
      },
    });
  };

  const switcDeliveryType = (type: ActiveDeliveryTypes): void => {
    if (cart?.order.products.some(item => item.specialRecipe) ?? false) {
      setActiveDeliveryType(ActiveDeliveryTypes.RETIRADA);
    } else {
      setActiveDeliveryType(type);
    }
  };

  return (
    <>
      <CheckoutContainerFluid background="#76bc66">
        <CheckoutSecurityContainer>
          <p>Compra Segura</p>
          <BsShieldLock />
          <p>100% Protegido</p>
        </CheckoutSecurityContainer>
      </CheckoutContainerFluid>
      {(cart !== null && cart.order.products.length > 0) ||
      (cart?.order.offer !== undefined && cart.order.offer.length > 0) ? (
        <>
          <CheckoutContainerFluid background="#d4d9dd">
            <CheckoutCountdownContainer>
              <CheckoutCountdownBox>
                <CheckoutCountdown>
                  <div>
                    <Countdown
                      date={Date.now() + 2 * 60 * 60 * 1000}
                      renderer={({ formatted }) => <div>{formatted.hours}</div>}
                    />
                  </div>
                  <p>HORAS</p>
                </CheckoutCountdown>
                <span>:</span>
                <CheckoutCountdown>
                  <div>
                    <Countdown
                      date={Date.now() + 2 * 60 * 60 * 1000}
                      renderer={({ formatted }) => <div>{formatted.minutes}</div>}
                    />
                  </div>
                  <p>MINUTOS</p>
                </CheckoutCountdown>
                <span>:</span>
                <CheckoutCountdown>
                  <div>
                    <Countdown
                      date={Date.now() + 2 * 60 * 60 * 1000}
                      renderer={({ formatted }) => <div>{formatted.seconds}</div>}
                    />
                  </div>
                  <p>SEGUNDOS</p>
                </CheckoutCountdown>
              </CheckoutCountdownBox>
              <CheckoutCountdownTitle>
                <AiOutlineClockCircle />
                <p>Finalize seu pedido!</p>
              </CheckoutCountdownTitle>
            </CheckoutCountdownContainer>
          </CheckoutContainerFluid>
          <CheckoutContainerFluid>
            <CheckoutContainer>
              {checkoutLoading ? <Loading /> : <></>}
              <Row>
                <Column xl={8}>
                  <CheckoutInfoContainer>
                    <CheckoutOrderHeaderContainer>
                      <div>
                        <CheckoutHeaderStatuBox
                          active={true}
                          onClick={() => {
                            switchContainerActive(ContainerTypes.LOGIN);
                          }}
                        >
                          <HiMiniUser />
                          <CheckoutHeaderStatuLabel active>Identificação</CheckoutHeaderStatuLabel>
                        </CheckoutHeaderStatuBox>
                        <CheckoutHeaderStatuLine
                          active={
                            activeContainer === ContainerTypes.ENTREGA ||
                            activeContainer === ContainerTypes.PAGAMENTO ||
                            activeContainer === ContainerTypes.REVISAO
                          }
                        />
                        <CheckoutHeaderStatuBox
                          active={
                            activeContainer === ContainerTypes.ENTREGA ||
                            activeContainer === ContainerTypes.PAGAMENTO ||
                            activeContainer === ContainerTypes.REVISAO
                          }
                          onClick={() => {
                            switchContainerActive(ContainerTypes.ENTREGA);
                          }}
                        >
                          <HiMiniMapPin />
                          <CheckoutHeaderStatuLabel
                            active={
                              activeContainer === ContainerTypes.ENTREGA ||
                              activeContainer === ContainerTypes.PAGAMENTO ||
                              activeContainer === ContainerTypes.REVISAO
                            }
                          >
                            Entrega
                          </CheckoutHeaderStatuLabel>
                        </CheckoutHeaderStatuBox>
                        <CheckoutHeaderStatuLine
                          active={
                            activeContainer === ContainerTypes.PAGAMENTO || activeContainer === ContainerTypes.REVISAO
                          }
                        />
                        <CheckoutHeaderStatuBox
                          active={
                            activeContainer === ContainerTypes.PAGAMENTO || activeContainer === ContainerTypes.REVISAO
                          }
                          onClick={() => {
                            switchContainerActive(ContainerTypes.PAGAMENTO);
                          }}
                        >
                          <BsCreditCardFill />
                          <CheckoutHeaderStatuLabel
                            active={
                              activeContainer === ContainerTypes.PAGAMENTO || activeContainer === ContainerTypes.REVISAO
                            }
                          >
                            Pagamento
                          </CheckoutHeaderStatuLabel>
                        </CheckoutHeaderStatuBox>
                        <CheckoutHeaderStatuLine active={activeContainer === ContainerTypes.REVISAO} />
                        <CheckoutHeaderStatuBox
                          active={activeContainer === ContainerTypes.REVISAO}
                          onClick={() => {
                            switchContainerActive(ContainerTypes.REVISAO);
                          }}
                        >
                          <FaListCheck />
                          <CheckoutHeaderStatuLabel active={activeContainer === ContainerTypes.REVISAO}>
                            Revisão
                          </CheckoutHeaderStatuLabel>
                        </CheckoutHeaderStatuBox>
                      </div>
                    </CheckoutOrderHeaderContainer>
                    {activeContainer === ContainerTypes.LOGIN ? (
                      <>
                        {user !== null ? (
                          <CheckoutLoginActive>
                            <h1> Você está logado como {user.name}</h1>
                            <h2>Não é você? Clique no link abaixo para acessar sua conta</h2>
                            <div>
                              <button onClick={logOut}>
                                Sair <VscSignOut />
                              </button>
                              <button
                                onClick={() => {
                                  switchContainerActive(ContainerTypes.ENTREGA);
                                }}
                              >
                                Prosseguir para entrega <HiOutlineChevronDoubleRight />
                              </button>
                            </div>
                          </CheckoutLoginActive>
                        ) : (
                          <Login />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {activeContainer === ContainerTypes.ENTREGA ? (
                      <CheckoutDeliveryContainer>
                        {deliveryLoading ? <Loading /> : <></>}
                        {deliveryAlert !== undefined ? (
                          <Alert type={{ ...deliveryAlert, content: deliveryAlert.content }} />
                        ) : (
                          <>
                            {cart.order.products.some(item => item.specialRecipe) ? (
                              <Alert
                                type={{
                                  ...AlertTypesValue.info,
                                  content:
                                    'Disponível somente retirada no local. Um ou mais produtos em seu carrinho necessita de receita médica.',
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        <h1>Selecione como prefere receber seu pedido:</h1>
                        <CheckoutDeliveryButtonContainer>
                          <button
                            onClick={() => {
                              switcDeliveryType(ActiveDeliveryTypes.ENVIO);
                            }}
                          >
                            Entrega{' '}
                            {activeDeliveryType === ActiveDeliveryTypes.ENVIO ? <AiFillCheckCircle /> : <BiCircle />}
                          </button>
                          <button
                            onClick={() => {
                              switcDeliveryType(ActiveDeliveryTypes.RETIRADA);
                            }}
                          >
                            Retirada{' '}
                            {activeDeliveryType === ActiveDeliveryTypes.RETIRADA ? <AiFillCheckCircle /> : <BiCircle />}
                          </button>
                        </CheckoutDeliveryButtonContainer>
                        {activeDeliveryType === ActiveDeliveryTypes.ENVIO ? (
                          <>
                            {userAddresses.length > 0 ? (
                              <>
                                {userMainAddressActive !== undefined ? (
                                  <UserActiveAddressContainer>
                                    <h1>{`${userMainAddressActive.address}, ${userMainAddressActive.number} - CEP ${userMainAddressActive.zipCode}`}</h1>
                                    {userAddresses.length > 1 ? (
                                      <button
                                        onClick={() => {
                                          setUserMainAddressActive(undefined);
                                        }}
                                      >
                                        Alterar endereço
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </UserActiveAddressContainer>
                                ) : (
                                  <Row>
                                    {userAddresses.map((address, i) => (
                                      <Column xl={4} key={i}>
                                        <UserAddressContainer
                                          onClick={() => {
                                            setUserMainAddressActive(address);
                                          }}
                                        >
                                          <h1>{address.nick}</h1>
                                          <h2>
                                            {address.address} {address.number}, {address.district} - CEP{' '}
                                            {address.zipCode}
                                          </h2>
                                        </UserAddressContainer>
                                      </Column>
                                    ))}
                                  </Row>
                                )}
                                <Row>
                                  {userMainAddressActive !== undefined ? (
                                    <>
                                      {fastDelivery !== undefined ? (
                                        <Column xl={4}>
                                          <CheckoutDeliveryBox
                                            onClick={() => {
                                              selectFastDelivery(fastDelivery);
                                            }}
                                            active={body.delivery.fastDeliveryId === fastDelivery.id}
                                          >
                                            <h1>Entrega rápida - {fastDelivery.deliveryTime}m</h1>
                                            <h2>CEP {fastDelivery.zipCode}</h2>
                                            <h3>R$ {formatMoney(fastDelivery.price)}</h3>
                                          </CheckoutDeliveryBox>
                                        </Column>
                                      ) : (
                                        <></>
                                      )}
                                      {freights !== undefined ? (
                                        <>
                                          {freights.map((freight, i) => (
                                            <Column key={i} xl={4}>
                                              <CheckoutDeliveryBox
                                                onClick={() => {
                                                  selectDelivery(freight);
                                                }}
                                                active={body.delivery.integrationId === freight.id}
                                              >
                                                <h1>
                                                  {freight.company.name} - de {freight.deliveryTimeMin} a{' '}
                                                  {freight.deliveryTimeMax} Dia(s) úteis
                                                </h1>
                                                <h2>CEP {userMainAddressActive?.zipCode}</h2>
                                                <h3>R$ {formatMoney(freight.price)}</h3>
                                              </CheckoutDeliveryBox>
                                            </Column>
                                          ))}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              </>
                            ) : (
                              <Alert type={{ ...AlertTypesValue.warning, content: 'Nenhum endereço encontrado.' }} />
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {activeDeliveryType === ActiveDeliveryTypes.ENVIO ? (
                          <Row>
                            <Column xl={7}>
                              <CheckoutFastDeliveryContainer>
                                <button
                                  onClick={() => {
                                    navigate('/minha-conta?pagina=enderecos');
                                  }}
                                >
                                  Cadastrar novo endereço
                                  <FaPlus />
                                </button>
                              </CheckoutFastDeliveryContainer>
                            </Column>
                            <Column xl={5}>
                              <ButtonNextStepContainer>
                                <button
                                  onClick={() => {
                                    switchContainerActive(ContainerTypes.PAGAMENTO);
                                  }}
                                >
                                  Prosseguir para o pagamento <HiOutlineChevronDoubleRight />
                                </button>
                              </ButtonNextStepContainer>
                            </Column>
                          </Row>
                        ) : (
                          <>
                            <CheckoutAdminAddressContainer>
                              <h1>Nossos endereços</h1>
                              <p>
                                {admin.address}, {admin.number} - {admin.district}
                              </p>
                              <p>
                                CEP {admin.zipCode} - {admin.city}/{admin.state}
                              </p>
                              <p>
                                {admin.phone} | {admin.cellphone}
                              </p>
                              <h2>DISPONÍVEL EM ATÉ 1 DIA ÚTIL</h2>
                            </CheckoutAdminAddressContainer>
                            <Row>
                              <Column xl={6}>
                                <ButtonNextStepContainer>
                                  <button
                                    onClick={() => {
                                      switchContainerActive(ContainerTypes.PAGAMENTO);
                                    }}
                                  >
                                    Prosseguir para o pagamento <HiOutlineChevronDoubleRight />
                                  </button>
                                </ButtonNextStepContainer>
                              </Column>
                            </Row>
                          </>
                        )}
                      </CheckoutDeliveryContainer>
                    ) : (
                      <></>
                    )}
                    {activeContainer === ContainerTypes.PAGAMENTO ? (
                      <CheckoutPaymentContainer>
                        {paymentLoading ? <Loading /> : <></>}

                        <h1>Selecione uma forma de pagamento:</h1>
                        <CheckoutPaymentOptionContainer
                          active={true}
                          onClick={() => {
                            if (user?.id === 1) {
                              setActivePaymentType(PaymentTypes.CARTAO_CREDITO);
                            } else {
                              setActivePaymentType(PaymentTypes.ENTREGA);
                            }
                          }}
                        >
                          <CheckoutPaymentOptionBox>
                            {activePaymentType === PaymentTypes.CARTAO_CREDITO ? <AiFillCheckCircle /> : <BiCircle />}
                            <p>Cartão de Crédito</p>
                          </CheckoutPaymentOptionBox>
                          <BiCreditCard />
                        </CheckoutPaymentOptionContainer>
                        <CheckoutPaymentOptionContent active={activePaymentType === PaymentTypes.CARTAO_CREDITO}>
                          {userPayments.length > 0 ? (
                            <Row>
                              {userPayments.map((payment, i) => (
                                <Column key={i} xl={3}>
                                  <UserPaymentItemContainer
                                    onClick={() => {
                                      setActivePayment(payment);
                                    }}
                                    active={activePayment?.id === payment.id}
                                  >
                                    <h1>{payment.nick}</h1>
                                    <h2>{payment.maskedCardNumber}</h2>
                                    <h3>
                                      {payment.name} - {payment.flag}
                                    </h3>
                                  </UserPaymentItemContainer>
                                </Column>
                              ))}
                            </Row>
                          ) : (
                            <Alert
                              type={{ ...AlertTypesValue.warning, content: 'Nenhum cartão de crédito cadastrado.' }}
                            />
                          )}
                          <button
                            onClick={() => {
                              navigate('/minha-conta?pagina=cartoes');
                            }}
                          >
                            Adicionar cartão de crédito <FaPlus />
                          </button>
                        </CheckoutPaymentOptionContent>
                        <CheckoutPaymentOptionContainer
                          active={true}
                          onClick={() => {
                            setActivePaymentType(PaymentTypes.PIX);
                          }}
                        >
                          <CheckoutPaymentOptionBox>
                            {activePaymentType === PaymentTypes.PIX ? <AiFillCheckCircle /> : <BiCircle />}
                            <p>PIX</p>
                          </CheckoutPaymentOptionBox>
                          <FaPix />
                        </CheckoutPaymentOptionContainer>
                        <CheckoutPaymentOptionContainer
                          active={true}
                          onClick={() => {
                            setActivePaymentType(PaymentTypes.BOLETO);
                          }}
                        >
                          <CheckoutPaymentOptionBox>
                            {activePaymentType === PaymentTypes.BOLETO ? <AiFillCheckCircle /> : <BiCircle />}
                            <p>Boleto Bancário</p>
                          </CheckoutPaymentOptionBox>
                          <AiOutlineBarcode />
                        </CheckoutPaymentOptionContainer>
                        {body.delivery.fastDeliveryId !== undefined ||
                        activeDeliveryType === ActiveDeliveryTypes.RETIRADA ? (
                          <>
                            <CheckoutPaymentOptionContainer
                              active={true}
                              onClick={() => {
                                setActivePaymentType(PaymentTypes.ENTREGA);
                              }}
                            >
                              <CheckoutPaymentOptionBox>
                                {activePaymentType === PaymentTypes.ENTREGA ? <AiFillCheckCircle /> : <BiCircle />}
                                <p>Pague na Retirada</p>
                              </CheckoutPaymentOptionBox>
                              <TbTruckDelivery />
                            </CheckoutPaymentOptionContainer>
                            <CheckoutDeliveryContent active={activePaymentType === PaymentTypes.ENTREGA}>
                              <textarea
                                onChange={e => {
                                  setObservations(e.target.value);
                                }}
                                placeholder="Deseja adicionar alguma observação? Ex: troco, débito, crédito etc..."
                              ></textarea>
                            </CheckoutDeliveryContent>
                          </>
                        ) : (
                          <></>
                        )}
                        <ButtonNextStepContainer>
                          <button
                            onClick={() => {
                              switchContainerActive(ContainerTypes.REVISAO);
                            }}
                          >
                            Prosseguir para revisão <HiOutlineChevronDoubleRight />
                          </button>
                        </ButtonNextStepContainer>
                      </CheckoutPaymentContainer>
                    ) : (
                      <></>
                    )}
                    {activeContainer === ContainerTypes.REVISAO ? (
                      <CheckoutReviewContainer>
                        {checkoutAlert !== undefined ? <Alert type={checkoutAlert} /> : <></>}
                        <h1>Revisão</h1>
                        <CheckoutReviewBox>
                          <CheckoutReviewItemsContainer>
                            {cart.order.products.map((item, i) => (
                              <CheckoutReviewItem key={i}>
                                <Row>
                                  <Column xl={1}>
                                    <CheckoutReviewImageContainer>
                                      {item.image !== null ? <img src={item.image} /> : <LuImageOff />}
                                    </CheckoutReviewImageContainer>
                                  </Column>
                                  <Column xl={11}>
                                    <CheckoutReviewInfoContainer>
                                      <h1>{item.name}</h1>
                                      <h2>{item.quantity} Unidade(s)</h2>
                                      {cart.order.promotion !== undefined ? (
                                        <h3>PROMOÇÃO</h3>
                                      ) : (
                                        <h3>R$ {formatMoney(item.price)}</h3>
                                      )}
                                    </CheckoutReviewInfoContainer>
                                  </Column>
                                </Row>
                              </CheckoutReviewItem>
                            ))}
                            {cart.order.offer !== undefined && cart.order.offer.length > 0 ? (
                              <>
                                {cart.order.offer.map((item, i) => (
                                  <>
                                    {item.products.map((offerProduct, u) => (
                                      <CheckoutReviewItem key={u}>
                                        <Row>
                                          <Column xl={1}>
                                            <CheckoutReviewImageContainer>
                                              <img src={item.image} />
                                            </CheckoutReviewImageContainer>
                                          </Column>
                                          <Column xl={11}>
                                            <CheckoutReviewInfoContainer>
                                              <h1>{offerProduct.name}</h1>
                                              <h2>{offerProduct.quantity} Unidade(s)</h2>
                                              <h3>R$ {formatMoney(offerProduct.price)}</h3>
                                            </CheckoutReviewInfoContainer>
                                          </Column>
                                        </Row>
                                      </CheckoutReviewItem>
                                    ))}
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </CheckoutReviewItemsContainer>
                          {body.payment.paymentTypeId === PaymentTypes.CARTAO_CREDITO ? (
                            <CheckoutReviewPaymentContainer>
                              <h1>Cartão de crédito</h1>
                              <p>
                                {activePayment?.name} - {activePayment?.flag}
                              </p>
                              <p>
                                {activePayment?.maskedCardNumber} - {activePayment?.expirationMonth}/
                                {activePayment?.expirationYear}
                              </p>
                              <button
                                onClick={() => {
                                  switchContainerActive(ContainerTypes.PAGAMENTO);
                                }}
                              >
                                Alterar forma de pagamento
                              </button>
                            </CheckoutReviewPaymentContainer>
                          ) : (
                            <></>
                          )}
                          {body.payment.paymentTypeId === PaymentTypes.BOLETO ? (
                            <CheckoutReviewPaymentContainer>
                              <h1>Boleto Báncario</h1>
                              <p>Aprovação de 1 a 3 dias úteis</p>
                              <button
                                onClick={() => {
                                  switchContainerActive(ContainerTypes.PAGAMENTO);
                                }}
                              >
                                Alterar forma de pagamento
                              </button>
                            </CheckoutReviewPaymentContainer>
                          ) : (
                            <></>
                          )}
                          {body.payment.paymentTypeId === PaymentTypes.PIX ? (
                            <CheckoutReviewPaymentContainer>
                              <h1>PIX</h1>
                              <p>Aprovação imediata</p>
                              <button
                                onClick={() => {
                                  switchContainerActive(ContainerTypes.PAGAMENTO);
                                }}
                              >
                                Alterar forma de pagamento
                              </button>
                            </CheckoutReviewPaymentContainer>
                          ) : (
                            <></>
                          )}
                          {body.payment.paymentTypeId === PaymentTypes.ENTREGA ? (
                            <CheckoutReviewPaymentContainer>
                              <h1>Pagamento na Entrega</h1>
                              <p>Dinheiro ou Cartão</p>
                              <button
                                onClick={() => {
                                  switchContainerActive(ContainerTypes.PAGAMENTO);
                                }}
                              >
                                Alterar forma de pagamento
                              </button>
                            </CheckoutReviewPaymentContainer>
                          ) : (
                            <></>
                          )}
                          {body.delivery.deliveryTypeId === DeliveryTypes.ENTREGA_RAPIDA ? (
                            <>
                              <Row>
                                <Column xl={4}>
                                  <CheckoutReviewDeliveryContainer>
                                    <h1>{userMainAddressActive?.nick}</h1>
                                    <p>
                                      {userMainAddressActive?.address}, {userMainAddressActive?.number} -{' '}
                                      {userMainAddressActive?.district}
                                    </p>
                                    <p>
                                      CEP {userMainAddressActive?.zipCode} - {userMainAddressActive?.city}/
                                      {userMainAddressActive?.state}
                                    </p>
                                  </CheckoutReviewDeliveryContainer>
                                </Column>
                                <Column xl={4}>
                                  <CheckoutReviewDeliveryContainer>
                                    <h1>Entrega Rápida</h1>
                                    <p>{fastDelivery?.deliveryTime}</p>
                                    <p>CEP {fastDelivery?.zipCode}</p>
                                  </CheckoutReviewDeliveryContainer>
                                </Column>
                              </Row>
                              <CheckoutReviewDeliveryButton>
                                <button
                                  onClick={() => {
                                    switchContainerActive(ContainerTypes.ENTREGA);
                                  }}
                                >
                                  Alterar entrega
                                </button>
                              </CheckoutReviewDeliveryButton>
                            </>
                          ) : (
                            <></>
                          )}
                          {body.delivery.deliveryTypeId === DeliveryTypes.CORREIOS ? (
                            <>
                              <Row>
                                <Column xl={4}>
                                  <CheckoutReviewDeliveryContainer>
                                    <h1>{userMainAddressActive?.nick}</h1>
                                    <p>
                                      {userMainAddressActive?.address}, {userMainAddressActive?.number} -{' '}
                                      {userMainAddressActive?.district}
                                    </p>
                                    <p>
                                      CEP {userMainAddressActive?.zipCode} - {userMainAddressActive?.city}/
                                      {userMainAddressActive?.state}
                                    </p>
                                  </CheckoutReviewDeliveryContainer>
                                </Column>
                                <Column xl={4}>
                                  <CheckoutReviewDeliveryContainer>
                                    <h1>{body.delivery.company}</h1>
                                    <p>
                                      de {body.delivery.deliveryTimeMin} a {body.delivery.deliveryTimeMax} dia(s) úteis
                                    </p>
                                    <p>R$ {formatMoney(body.delivery.price ?? '')}</p>
                                  </CheckoutReviewDeliveryContainer>
                                </Column>
                              </Row>
                              <CheckoutReviewDeliveryButton>
                                <button
                                  onClick={() => {
                                    switchContainerActive(ContainerTypes.ENTREGA);
                                  }}
                                >
                                  Alterar entrega
                                </button>
                              </CheckoutReviewDeliveryButton>
                            </>
                          ) : (
                            <></>
                          )}
                          {body.delivery.deliveryTypeId === DeliveryTypes.RETIRADA ? (
                            <>
                              <CheckoutReviewDeliveryContainer>
                                <h1>Retirada em loja</h1>
                                <p>Disponível em até 1 dia útil</p>
                                <p>
                                  {admin.address}, {admin.number} - {admin.district}
                                </p>
                                <p>
                                  CEP {admin.zipCode} - {admin.city}/{admin.state}
                                </p>
                              </CheckoutReviewDeliveryContainer>
                              <CheckoutReviewDeliveryButton>
                                <button
                                  onClick={() => {
                                    switchContainerActive(ContainerTypes.ENTREGA);
                                  }}
                                >
                                  Alterar entrega
                                </button>
                              </CheckoutReviewDeliveryButton>
                            </>
                          ) : (
                            <></>
                          )}
                        </CheckoutReviewBox>
                        <Row>
                          <Column xl={6}>
                            <ButtonNextStepContainer>
                              <button onClick={checkout}>Finalizar Compra</button>
                            </ButtonNextStepContainer>
                          </Column>
                        </Row>
                      </CheckoutReviewContainer>
                    ) : (
                      <></>
                    )}
                  </CheckoutInfoContainer>
                </Column>
                <Column xl={4}>
                  <Cart />
                </Column>
              </Row>
            </CheckoutContainer>
          </CheckoutContainerFluid>
          {recomendations !== undefined ? (
            <CheckoutContainerFluid background={primaryTheme.background.secondary}>
              <CheckoutContainer>
                <Row>
                  {recomendations.slice(0, 6).map((recomendation, key) => (
                    <Column key={key} xl={2}>
                      <Product data={recomendation} />
                    </Column>
                  ))}
                </Row>
              </CheckoutContainer>
            </CheckoutContainerFluid>
          ) : (
            <></>
          )}
        </>
      ) : (
        <CheckoutContainerFluid background={primaryTheme.background.secondary}>
          <CheckoutContainer>
            <CheckoutNoContentContainer>
              <h1>Seu carrinho está vazio</h1>
              <p>Para adicionar produtos, navegue pelas categorias do site ou faça uma busca por palavra-chave.</p>
              <CustomButtom>
                <button
                  onClick={() => {
                    navigate('/produtos');
                  }}
                >
                  <FaBagShopping />
                  Navegar
                </button>
              </CustomButtom>
            </CheckoutNoContentContainer>
          </CheckoutContainer>
        </CheckoutContainerFluid>
      )}
    </>
  );
};

export default Checkout;
