import React from 'react';
import GlobalStyle from './styles/globalStyle';
import { ThemeProvider } from 'styled-components';
import { primaryTheme } from './styles/theme';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Product from './pages/products/product';
import Register from './pages/register';
import Products from './pages/products/list';
import Checkout from './pages/checkout/view';
import Layout from './pages/layout';
import CartProvider from './hooks/useCart';
import Text from './pages/text';
import AuthProvider from './hooks/useAuth';
import Account from './pages/account';
import PrivateLayout from './pages/privateLayout';
import Order from './pages/order';
import AdminProvider from './hooks/useAdmin';
import Promotion from './pages/promotion';
import Reclamations from './pages/reclamations';
import Resumes from './pages/resumes';
import Activation from './pages/activation';

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={primaryTheme}>
        <GlobalStyle />
        <AdminProvider>
          <AuthProvider>
            <CartProvider>
              <Routes>
                <Route element={<Layout />}>
                  <Route path="/" index element={<Home />} />
                  <Route path="categorias/:categoria" element={<Products />} />
                  <Route path="subcategorias/:subcategoria" element={<Products />} />
                  <Route path="produtos" element={<Products />} />
                  <Route path="produtos/buscar/:busca" element={<Products />} />
                  <Route path="marcas/:marca" element={<Products />} />
                  <Route path="produtos/:slug" element={<Product />} />
                  <Route path="checkout" element={<Checkout />} />
                  <Route path="cadastre-se" element={<Register />} />
                  <Route path="pagina/:slug" element={<Text />} />
                  <Route path="ouvidoria" element={<Reclamations />} />
                  <Route path="trabalhe-conosco" element={<Resumes />} />
                  <Route path="promocoes/:slug" element={<Promotion />} />
                  <Route path="usuarios/:usuario/validacao/:codigo" element={<Activation />} />
                  <Route path="*" element={<Home />} />
                </Route>

                <Route path="" element={<PrivateLayout />}>
                  <Route path="minha-conta" index element={<Account />} />
                  <Route path="pedidos/:codigo" element={<Order />} />
                </Route>
              </Routes>
            </CartProvider>
          </AuthProvider>
        </AdminProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
