import React, { useEffect, useState } from 'react';
import {
  CardAmountContainer,
  CartButtonContainer,
  CartCloseButton,
  CartContainer,
  CartItemContainer,
  CartItemImageContainer,
  CartItemInfoContainer,
  CartItemInfoTitleContainer,
  CartItemQuantityContainer,
  CartNoContentContainer,
  DeliveryFancyboxContent,
  DropdownProductContainer,
  DropdownProductInfoBox,
  DropdownProductInfoContainer,
  DropdownProductInfoImage,
  DropdownSearchNoContentContainer,
  HeaderButton,
  HeaderButtonsContainer,
  HeaderContainerFluid,
  HeaderContainerMenuMobile,
  HeaderContainerMenuMobilePadding,
  HeaderContainerNavMobile,
  HeaderDropdownAddressContainer,
  HeaderDropdownContainer,
  HeaderDropdownContent,
  HeaderDropdownInput,
  HeaderDropdownSearchContainer,
  HeaderDropdownSearchContentContainer,
  HeaderDropdownSearchLoadingContainer,
  HeaderImage,
  HeaderInput,
  HeaderLoginContainer,
  HiddenHeaderContainer,
  HiddenHeaderLogo,
  SearchNavMobileContainer,
} from '../../styles/pages/includes/header';
import {
  FaMotorcycle,
  FaUser,
  FaCartShopping,
  FaX,
  FaBagShopping,
  FaRegFaceSadTear,
  FaPlus,
  FaMinus,
  FaTrash,
  FaBars,
  FaHouse,
} from 'react-icons/fa6';
import { BiSearchAlt2 } from 'react-icons/bi';
import { BsCart4 } from 'react-icons/bs';
import { LuImageOff, LuUser2 } from 'react-icons/lu';
import { LiaMotorcycleSolid } from 'react-icons/lia';
import { FiChevronsRight, FiMapPin } from 'react-icons/fi';
import { AiOutlineClockCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import Fancybox, { FancyboxSizes } from '../../components/fancybox';
import Login from './login';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCart } from '../../hooks/useCart';
import Loading from '../../components/loading';
import { formatMoney } from '../../helpers/formatNumber';
import { useAuth } from '../../hooks/useAuth';
import { type DeliveryResponse } from '../../interfaces/delivery';
import ApplicationApi from '../../api/config';
import Alert, { type AlertTypes, AlertTypesValue } from '../../components/alert';
import { type ProductResponse } from '../../interfaces/product';
import Countdown from 'react-countdown';

const Header: React.FC = () => {
  const [dropdownDelivery, setDropdownDelivery] = useState<boolean>(false);
  const [dropdownSearch, setDropdownSearch] = useState<boolean>(false);

  const [deliveryNavHiddenFancybox, setDeliveryNavHiddenFancybox] = useState<boolean>(false);

  const [hiddenMenuVisible, setHiddenMenuVisible] = useState<boolean>(false);
  const [navMobileActive, setNavMobileActive] = useState<boolean>(false);

  const [loadingSearch, setLoadingSearch] = useState<boolean>(true);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [deliveryAlert, setDeliveryAlert] = useState<AlertTypes>();
  const [deliveryLoading, setDeliveryLoading] = useState<boolean>(true);

  const [searchedDeliverys, setSearchedDeliveries] = useState<DeliveryResponse[]>([]);
  const [deliveries, setDeliveries] = useState<DeliveryResponse[]>([]);

  const [products, setProducts] = useState<ProductResponse[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<ProductResponse[]>([]);

  const {
    cart,
    cartActive,
    setCartActive,
    clearCart,
    removeFromCart,
    addProductQuantity,
    removeProductQuantity,
    removeOffer,
  } = useCart();
  const { user, loginFancybox, setLoginFancyboxActive } = useAuth();

  const [searchBody, setSearchBody] = useState<string>();

  const [navSearchActive, setNavSearchActive] = useState<boolean>(false);

  useEffect(() => {
    window.onscroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 350) {
        setHiddenMenuVisible(true);
      } else {
        setHiddenMenuVisible(false);
      }
    };

    ApplicationApi.get<DeliveryResponse[]>('entregas-rapidas')
      .then(({ data }) => {
        setDeliveries(data);
        setSearchedDeliveries(data);
        setDeliveryLoading(false);
      })
      .catch(() => {
        setDeliveryAlert({ ...AlertTypesValue.error, content: 'Não foi possível recuperar entregas.' });
        setDeliveryLoading(false);
      });

    ApplicationApi.get<ProductResponse[]>('produtos/home')
      .then(({ data }) => {
        setProducts(data);
        setSearchedProducts(data);
        setLoadingSearch(false);
      })
      .catch(() => {
        setLoadingSearch(false);
      });
  }, []);

  useEffect(() => {
    setDropdownDelivery(false);
    setDropdownSearch(false);
    setCartActive(false);
    setLoginFancyboxActive(false);
    setDeliveryNavHiddenFancybox(false);
    setNavMobileActive(false);
  }, [pathname]);

  useEffect(() => {
    if (searchedDeliverys.length === 0) {
      setDeliveryAlert({ ...AlertTypesValue.warning, content: 'Nenhuma entrega rápida encontrada.' });
    }
  }, [searchedDeliverys]);

  const searchDeliveries = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value !== null) {
      const result = deliveries.filter(o =>
        Object.keys(o).some(k =>
          String(o[k as keyof typeof o])
            .toLowerCase()
            .includes(e.target.value.toLowerCase()),
        ),
      );

      setSearchedDeliveries(result);
    }
  };

  const searchProducts = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value !== null) {
      const result = products.filter(o =>
        Object.keys(o).some(k =>
          String(o[k as keyof typeof o])
            .toLowerCase()
            .includes(e.target.value.toLowerCase()),
        ),
      );

      setSearchedProducts(result);
    }
  };

  return (
    <>
      <Fancybox size={FancyboxSizes.MEDIUM} active={deliveryNavHiddenFancybox} setState={setDeliveryNavHiddenFancybox}>
        <DeliveryFancyboxContent>
          <form>
            <HeaderDropdownInput>
              <div>
                <BiSearchAlt2 />
              </div>
              <input type="search" placeholder="Busque por bairros" onChange={searchDeliveries} />
            </HeaderDropdownInput>
            {deliveryLoading ? (
              <HeaderDropdownAddressContainer>
                <Loading />
              </HeaderDropdownAddressContainer>
            ) : (
              <>
                {searchedDeliverys.length > 0 ? (
                  <>
                    {searchedDeliverys.slice(0, 3).map((delivery, i) => (
                      <HeaderDropdownAddressContainer key={i}>
                        <FiMapPin />
                        <p>
                          {delivery.district}/{delivery.state}
                        </p>
                        <div>
                          <p>{delivery.deliveryTime} min</p>
                          <AiOutlineClockCircle />
                        </div>
                      </HeaderDropdownAddressContainer>
                    ))}
                  </>
                ) : (
                  <>{deliveryAlert !== undefined ? <Alert type={deliveryAlert} /> : <></>}</>
                )}
              </>
            )}

            <p>
              Para demais regiões do Brasil, siga no fluxo do pedido para saber qual o tempo de entrega para sua região.
            </p>
          </form>
        </DeliveryFancyboxContent>
      </Fancybox>
      <Fancybox size={FancyboxSizes.LARGE} active={loginFancybox} setState={setLoginFancyboxActive}>
        <HeaderLoginContainer>
          <Login />
        </HeaderLoginContainer>
      </Fancybox>
      <CartContainer active={cartActive}>
        <CartCloseButton
          onClick={() => {
            setCartActive(false);
          }}
        >
          <FaX />
        </CartCloseButton>
        {(cart != null && cart.order.products.length > 0) ||
        (cart?.order.offer !== undefined && cart.order.offer.length > 0) ? (
          <>
            {cart.order.products.map((cart, i) => (
              <CartItemContainer key={i}>
                <CartItemInfoContainer>
                  <CartItemImageContainer>
                    {cart.image !== null ? <img src={cart.image} /> : <LuImageOff />}
                  </CartItemImageContainer>
                  <CartItemInfoTitleContainer>
                    <h1>{cart.name.length > 25 ? `${cart.name.substring(0, 25)}...` : cart.name}</h1>
                    <h2>Código {cart.code}</h2>
                    <h3>R$ {formatMoney(cart.price)}</h3>
                  </CartItemInfoTitleContainer>
                </CartItemInfoContainer>
                <CartItemQuantityContainer>
                  <button
                    onClick={() => {
                      removeProductQuantity(cart.id);
                    }}
                  >
                    <FaMinus />
                  </button>
                  <p>{cart.quantity}</p>
                  <button
                    onClick={() => {
                      addProductQuantity(cart.id);
                    }}
                  >
                    <FaPlus />
                  </button>
                  <button
                    onClick={() => {
                      removeFromCart(cart.id);
                    }}
                  >
                    <FaTrash />
                  </button>
                </CartItemQuantityContainer>
              </CartItemContainer>
            ))}
            {cart.order.offer?.map((offer, u) => (
              <CartItemContainer key={u}>
                <CartItemInfoContainer>
                  <CartItemImageContainer>
                    <img src={offer.image} />
                  </CartItemImageContainer>
                  <CartItemInfoTitleContainer>
                    <h1>{offer.name.length > 25 ? `${offer.name.substring(0, 25)}...` : offer.name}</h1>
                    <h2>
                      Expira em: <Countdown date={offer.expirationDate} daysInHours={false} />
                    </h2>
                    <h3>R$ {formatMoney(offer.amount)}</h3>
                  </CartItemInfoTitleContainer>
                </CartItemInfoContainer>
                <CartItemQuantityContainer>
                  <button
                    onClick={() => {
                      removeOffer(offer.id);
                    }}
                  >
                    <FaTrash />
                  </button>
                </CartItemQuantityContainer>
              </CartItemContainer>
            ))}
            <CardAmountContainer>
              <h1>Subtotal</h1>
              <p>R$ {formatMoney(cart.order.amount)}</p>
            </CardAmountContainer>
            {cart.order.deliveryFee !== undefined ? (
              <CardAmountContainer>
                <h1>Frete</h1>
                <p>R$ {formatMoney(cart.order.amount)}</p>
              </CardAmountContainer>
            ) : (
              <></>
            )}
            {cart.order.discount !== undefined ? (
              <CardAmountContainer>
                <h1>Cupom</h1>
                <p>{cart.order.discount.name}</p>
              </CardAmountContainer>
            ) : (
              <></>
            )}
            <CardAmountContainer>
              <h1>Total</h1>
              <p>R$ {formatMoney(cart.order.amount)}</p>
            </CardAmountContainer>
            <CartButtonContainer>
              <button
                onClick={() => {
                  clearCart();
                }}
              >
                Limpar carrinho <AiOutlineCloseCircle />
              </button>
              <button
                onClick={() => {
                  navigate('/checkout');
                }}
              >
                Finalizar <BsCart4 />
              </button>
            </CartButtonContainer>
          </>
        ) : (
          <CartNoContentContainer>
            <h1>Seu carrinho está vazio</h1>
            <p>Para adicionar produtos, navegue pelas categorias do site ou faça uma busca por palavra-chave.</p>
            <CustomButtom>
              <button>
                <FaBagShopping />
                Navegar
              </button>
            </CustomButtom>
          </CartNoContentContainer>
        )}
      </CartContainer>
      <HiddenHeaderContainer active={hiddenMenuVisible}>
        <HiddenHeaderLogo src="/images/logotipo.png" alt="Logo" />
        <div>
          <form
            onSubmit={e => {
              e.preventDefault();
              navigate(`/produtos/buscar/${searchBody}`);
            }}
          >
            <input
              onChange={e => {
                setSearchBody(e.target.value);
              }}
              type="search"
              placeholder="O que você está procurando?"
              required
            />
            <button type="submit">
              <BiSearchAlt2 />
            </button>
          </form>
          <button
            onClick={() => {
              setDeliveryNavHiddenFancybox(true);
            }}
          >
            <FaMotorcycle />
          </button>
          <button
            onClick={() => {
              setLoginFancyboxActive(true);
            }}
          >
            <FaUser />
          </button>
          <button
            onClick={() => {
              setCartActive(true);
            }}
          >
            {cart !== null ? (
              <div>
                {cart.order.offer !== undefined
                  ? cart.order.products.length + cart.order.offer.length
                  : cart.order.products.length}
              </div>
            ) : (
              <></>
            )}
            <FaCartShopping />
          </button>
        </div>
      </HiddenHeaderContainer>
      <HeaderContainerFluid>
        <Row>
          <Column xl={7}>
            <Row>
              <Column center xl={4}>
                <Link to="/">
                  <HeaderImage src="/images/logotipo.png" alt="Logo" />
                </Link>
              </Column>
              <Column xl={8}>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    navigate(`/produtos/buscar/${searchBody}`);
                  }}
                >
                  <HeaderInput>
                    <input
                      placeholder="O que você está procurando?"
                      onChange={e => {
                        e.currentTarget.value !== '' ? setDropdownSearch(true) : setDropdownSearch(false);
                        searchProducts(e);
                        setSearchBody(e.target.value);
                      }}
                      required
                    />
                    <button type="submit">
                      <BiSearchAlt2 />
                    </button>
                  </HeaderInput>
                  <HeaderDropdownSearchContainer active={dropdownSearch}>
                    <HeaderDropdownSearchContentContainer>
                      {loadingSearch ? (
                        <HeaderDropdownSearchLoadingContainer>
                          <Loading />
                        </HeaderDropdownSearchLoadingContainer>
                      ) : (
                        <>
                          {searchedProducts.length > 0 ? (
                            <>
                              {searchedProducts.slice(0, 4).map((product, i) => (
                                <DropdownProductContainer
                                  onClick={() => {
                                    navigate(`/produtos/${product.slug}`);
                                  }}
                                  key={i}
                                  grey={i % 2 === 0}
                                >
                                  <DropdownProductInfoContainer>
                                    <DropdownProductInfoImage>
                                      {product.productImages !== null && product.productImages.length > 0 ? (
                                        <img src={product.productImages[0].uploads.origin} />
                                      ) : (
                                        <LuImageOff />
                                      )}
                                    </DropdownProductInfoImage>
                                    <DropdownProductInfoBox>
                                      <h1>{product.name}</h1>
                                      <h3>{product.productStatus.name}</h3>
                                    </DropdownProductInfoBox>
                                  </DropdownProductInfoContainer>
                                  <p>
                                    R${' '}
                                    {product.newPrice !== null
                                      ? formatMoney(product.newPrice)
                                      : formatMoney(product.price)}
                                  </p>
                                </DropdownProductContainer>
                              ))}
                              <p>
                                Exibindo {searchedProducts.length} de {products.length} produtos
                              </p>
                            </>
                          ) : (
                            <DropdownSearchNoContentContainer>
                              <FaRegFaceSadTear />
                              <p>Nenhum produto encontrado.</p>
                            </DropdownSearchNoContentContainer>
                          )}
                        </>
                      )}
                    </HeaderDropdownSearchContentContainer>
                  </HeaderDropdownSearchContainer>
                </form>
              </Column>
            </Row>
          </Column>
          <Column xl={5}>
            <HeaderButtonsContainer>
              <HeaderDropdownContainer>
                <button
                  onClick={() => {
                    dropdownDelivery ? setDropdownDelivery(false) : setDropdownDelivery(true);
                  }}
                >
                  <LiaMotorcycleSolid />
                  Tempo de entrega
                </button>
                <HeaderDropdownContent active={dropdownDelivery}>
                  <form>
                    <HeaderDropdownInput>
                      <div>
                        <BiSearchAlt2 />
                      </div>
                      <input type="search" placeholder="Busque por bairros" onChange={searchDeliveries} />
                    </HeaderDropdownInput>
                    {deliveryLoading ? (
                      <HeaderDropdownAddressContainer>
                        <Loading />
                      </HeaderDropdownAddressContainer>
                    ) : (
                      <>
                        {searchedDeliverys.length > 0 ? (
                          <>
                            {searchedDeliverys.slice(0, 3).map((delivery, i) => (
                              <HeaderDropdownAddressContainer key={i}>
                                <FiMapPin />
                                <p>
                                  {delivery.district}/{delivery.state}
                                </p>
                                <div>
                                  <p>{delivery.deliveryTime} min</p>
                                  <AiOutlineClockCircle />
                                </div>
                              </HeaderDropdownAddressContainer>
                            ))}
                          </>
                        ) : (
                          <>{deliveryAlert !== undefined ? <Alert type={deliveryAlert} /> : <></>}</>
                        )}
                      </>
                    )}

                    <p>
                      Para demais regiões do Brasil, siga no fluxo do pedido para saber qual o tempo de entrega para sua
                      região.
                    </p>
                  </form>
                </HeaderDropdownContent>
              </HeaderDropdownContainer>
              <HeaderButton>
                {user !== null ? (
                  <button
                    onClick={() => {
                      navigate('/minha-conta');
                    }}
                  >
                    <LuUser2 />
                    Bem vindo(a) {user.name}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setLoginFancyboxActive(true);
                    }}
                  >
                    <LuUser2 />
                    Minha conta ou Cadastre-se
                  </button>
                )}
              </HeaderButton>
              <HeaderButton>
                <button
                  onClick={() => {
                    setCartActive(true);
                  }}
                >
                  {(cart !== null && cart.order.products.length > 0) ||
                  (cart?.order.offer !== undefined && cart.order.offer.length > 0) ? (
                    <div>
                      {cart.order.offer !== undefined
                        ? cart.order.products.length + cart.order.offer.length
                        : cart.order.products.length}
                    </div>
                  ) : (
                    <></>
                  )}
                  <BsCart4 /> Carrinho
                </button>
              </HeaderButton>
            </HeaderButtonsContainer>
          </Column>
        </Row>
      </HeaderContainerFluid>
      <HeaderContainerMenuMobilePadding />
      <HeaderContainerMenuMobile>
        <img src="/images/logotipo.png" />
        <FaBars
          onClick={() => {
            navMobileActive ? setNavMobileActive(false) : setNavMobileActive(true);
          }}
        />
      </HeaderContainerMenuMobile>
      <HeaderContainerNavMobile active={navMobileActive}>
        <ul>
          <li
            onClick={() => {
              navigate('/');
            }}
          >
            <FaHouse /> Home
          </li>
          <li
            onClick={() => {
              setNavSearchActive(!navSearchActive);
            }}
          >
            <BiSearchAlt2 /> Buscar produtos
          </li>
          {navSearchActive ? (
            <li>
              <SearchNavMobileContainer>
                <input
                  onChange={e => {
                    setSearchBody(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    searchBody !== undefined ? navigate(`/produtos/buscar/${searchBody}`) : navigate(`/produtos`);
                    setNavSearchActive(false);
                  }}
                >
                  <FiChevronsRight />
                </button>
              </SearchNavMobileContainer>
            </li>
          ) : (
            <></>
          )}
          <li
            onClick={() => {
              setDeliveryNavHiddenFancybox(true);
            }}
          >
            <LiaMotorcycleSolid /> Entregas
          </li>
          <li
            onClick={() => {
              setCartActive(true);
            }}
          >
            <BsCart4 />
            Carrinho
          </li>
          {user !== null ? (
            <li
              onClick={() => {
                navigate('/minha-conta');
              }}
            >
              <LuUser2 /> Minha conta
            </li>
          ) : (
            <li
              onClick={() => {
                setLoginFancyboxActive(true);
              }}
            >
              <LuUser2 />
              Faça seu login ou cadastre-se
            </li>
          )}
        </ul>
      </HeaderContainerNavMobile>
    </>
  );
};

export default Header;
