import React, { useEffect } from 'react';
import Header from './includes/header';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from './includes/footer';
import ApplicationApi from '../api/config';
import axios from 'axios';
import { useAdmin } from '../hooks/useAdmin';
import { HomeWhatsAppContainer } from '../styles/pages/home';
import { FaWhatsapp } from 'react-icons/fa6';

const Layout: React.FC = () => {
  const { pathname } = useLocation();
  const admin = useAdmin();

  useEffect(() => {
    axios
      .get('https://api.ipify.org?format=json')
      .then(({ data }) => {
        ApplicationApi.post('analytics', { remoteIp: data.ip, path: pathname })
          .then(() => {})
          .catch(() => {});
      })
      .catch(() => {});

    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <HomeWhatsAppContainer
        target="new"
        href={`https://wa.me/55${admin.cellphone
          .replaceAll(' ', '')
          .replace('(', '')
          .replace(')', '')
          .replace('-', '')}`}
      >
        <FaWhatsapp />
      </HomeWhatsAppContainer>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
