import { styled } from 'styled-components';

export const PromotionContainerFluid = styled.div<{ background?: string }>`
  width: 100%;
  padding: 0 15px;
  background: ${({ background, theme }) => background ?? theme.background.secondary};
`;

export const PromotionContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;
  position: relative;
  min-height: 200px;
`;

export const PromotionBannerContainer = styled.div`
  width: 100%;
  border-radius: 18px;
  overflow: hidden;
  margin-bottom: 24px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const PromotionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  button {
    padding: 0 24px;
    height: 42px;
    border-radius: 8px;
    background: ${({ theme }) => theme.background.primary};
    border: 0;
    color: ${({ theme }) => theme.color.primary};
    margin-left: 12px;
    cursor: pointer;
  }

  h1 {
    color: ${({ theme }) => theme.background.primary};
    font-size: 1.2rem;
  }
`;
