import React, { useState, type ReactNode, useEffect, type SyntheticEvent } from 'react';
import InputMask from 'react-input-mask';
import { ReclamationBanner, ReclamationContainer, ReclamationContainerFluid } from '../styles/pages/reclamation';
import { type TextResponse } from '../interfaces/text';
import Loading from '../components/loading';
import ApplicationApi from '../api/config';
import Row from '../components/row';
import Column from '../components/column';
import CustomInput from '../components/input';
import CustomTextArea from '../components/textarea';
import CustomButtom from '../components/button';
import { primaryTheme } from '../styles/theme';
import Alert, { AlertTypesValue, type AlertTypes } from '../components/alert';
import { type ReclamationBody } from '../interfaces/reclamation';

export default function Reclamations(): ReactNode {
  const [text, setText] = useState<TextResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [alerts, setAlerts] = useState<AlertTypes>();
  const [body, setBody] = useState<ReclamationBody>({ name: '', details: '', email: '', subject: '' });

  useEffect(() => {
    const getText = async (): Promise<void> => {
      setLoading(true);

      try {
        const { data } = await ApplicationApi.get<TextResponse>(`textos/ouvidoria`);

        document.title = `Drogaria Malu :: ${data.title}`;

        setText(data);
      } catch {}

      setLoading(false);
    };

    getText().catch(() => {});
  }, []);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    try {
      await ApplicationApi.post('reclamacoes', body);

      setAlerts({
        ...AlertTypesValue.success,
        content: 'Sua reclamação foi enviada com sucesso. Em breve um de nossos atendentes entrará em contato.',
      });
    } catch {
      setAlerts({ ...AlertTypesValue.error, content: 'Não foi possível enviar reclamação, tente novamente.' });
    }

    setLoading(false);
  };

  return (
    <>
      {text?.textImages !== null ? (
        <ReclamationBanner>
          <img src={text?.textImages.uploads.origin} />
        </ReclamationBanner>
      ) : (
        <></>
      )}
      <ReclamationContainerFluid>
        <ReclamationContainer>
          {loading ? (
            <Loading />
          ) : (
            <>
              <h1>{text?.title}</h1>
              {text?.text !== undefined ? <div dangerouslySetInnerHTML={{ __html: text.text ?? '' }} /> : <></>}
              {alerts !== undefined ? (
                <Alert type={alerts} />
              ) : (
                <form onSubmit={submitForm}>
                  <Row>
                    <Column xl={4}>
                      <CustomInput label="Digite seu nome" margin>
                        <input
                          onChange={e => {
                            setBody({ ...body, name: e.target.value });
                          }}
                          required
                        />
                      </CustomInput>
                    </Column>
                    <Column xl={4}>
                      <CustomInput label="Digite seu E-mail" margin>
                        <input
                          onChange={e => {
                            setBody({ ...body, email: e.target.value });
                          }}
                          type="email"
                          required
                        />
                      </CustomInput>
                    </Column>
                    <Column xl={4}>
                      <CustomInput label="Digite seu Telefone" margin>
                        <InputMask
                          mask="(99) 99999-9999"
                          onChange={e => {
                            setBody({ ...body, phone: e.target.value });
                          }}
                        />
                      </CustomInput>
                    </Column>
                    <Column xl={12}>
                      <CustomInput label="Assunto" margin>
                        <input
                          onChange={e => {
                            setBody({ ...body, subject: e.target.value });
                          }}
                          required
                        />
                      </CustomInput>
                    </Column>
                    <Column xl={12}>
                      <CustomTextArea label="Conte-nos mais sobre o ocorrido" margin>
                        <textarea
                          onChange={e => {
                            setBody({ ...body, details: e.target.value });
                          }}
                          required
                        />
                      </CustomTextArea>
                    </Column>
                    <Column xl={2} lg={4} md={6} sm={6}>
                      <CustomButtom background={primaryTheme.background.third}>
                        <button type="reset">Limpar</button>
                      </CustomButtom>
                    </Column>
                    <Column xl={2} lg={4} md={6} sm={6}>
                      <CustomButtom>
                        <button type="submit">Enviar</button>
                      </CustomButtom>
                    </Column>
                  </Row>
                </form>
              )}
            </>
          )}
        </ReclamationContainer>
      </ReclamationContainerFluid>
    </>
  );
}
