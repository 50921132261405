import styled from 'styled-components';
import { type FancyboxSizes } from '../../components/fancybox';

interface FancyboxContainerProps {
  active: boolean;
}

interface FancyboxContentProps {
  size: FancyboxSizes;
}

export const FancyboxContainer = styled.div<FancyboxContainerProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition:
    opacity 0.2s ease,
    visibility 0.4s ease;
`;

export const FancyboxCloseButton = styled.div`
  position: fixed;
  right: 0;
  top: 0;

  button {
    width: 40px;
    height: 40px;
    border: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.2s ease;
  }

  button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

export const FancyboxContent = styled.div<FancyboxContentProps>`
  overflow: hidden;
  width: ${({ size }) => size};
`;
