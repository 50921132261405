import React, { type SyntheticEvent, createRef, useState, useEffect } from 'react';
import { LoginBox, LoginContainer, LoginSubmitContainer, PasswordBox } from '../../styles/pages/includes/login';
import Row from '../../components/row';
import Column from '../../components/column';
import { FaArrowLeft, FaGoogle } from 'react-icons/fa6';
import CustomButtom from '../../components/button';
import ApplicationApi from '../../api/config';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading';
import { useAuth } from '../../hooks/useAuth';
import Alert, { AlertTypesValue, type AlertTypes } from '../../components/alert';
import { type AxiosError } from 'axios';
import { type OauthTokenResponse } from '../../interfaces/oauth';

interface GoogleUserResponse {
  id: string;
  email: string;
  verified_email: true;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  locale: string;
}

interface BodyData {
  email: string;
  password: string;
}

enum ActiveContainerTypes {
  LOGIN,
  REMEMBER_PASSWORD,
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { logIn } = useAuth();

  const inputEmailRef = createRef<HTMLInputElement>();

  const [activeContainer, setActiveContainer] = useState<ActiveContainerTypes>(ActiveContainerTypes.LOGIN);

  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertTypes>();
  const [passowordAlert, setPasswordAlert] = useState<AlertTypes>();

  const [rememberEmail, setRememberEmail] = useState<string>();

  const [body, setBody] = useState<BodyData>({ email: '', password: '' });

  const [oauthAlert, setOauthAlert] = useState<AlertTypes>();
  const [googleResponseToken, setGoogleResponseToken] = useState<string | null>(null);

  const oauthGoogle = async (): Promise<void> => {
    try {
      setLoading(true);

      const { data } = await ApplicationApi.get<{ url: string; responseId: number }>('usuarios/google');

      const left = screen.width / 2 - 500 / 2;
      const top = screen.height / 2 - 600 / 2;

      const win = window.open(
        data.url,
        'Entrar com o Google',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=500, height=600, top=${top}, left=${left}`,
      );

      setInterval(async () => {
        if (oauthAlert === undefined && googleResponseToken === null) {
          const response = await ApplicationApi.get<OauthTokenResponse>(
            `usuarios/google/auth/check/${data.responseId}`,
          );

          if (response.data.status === 1) {
            setGoogleResponseToken(response.data.token ?? '');
            win?.close();
          }

          if (response.data.status === 2) {
            setOauthAlert({ ...AlertTypesValue.error, content: 'Não foi possível completar login. Tente novamente' });
            win?.close();
          }
        }
      }, 1000);
    } catch {
      setLoading(false);
    }
  };

  const submitForm = (e: SyntheticEvent): void => {
    e.preventDefault();

    navigate(`/cadastre-se?email=${inputEmailRef.current?.value}`);
  };

  const submitFormLogin = async (e: SyntheticEvent): Promise<void> => {
    try {
      e.preventDefault();

      setLoading(true);

      await logIn(body.email, body.password);

      setLoading(false);
      setAlert(undefined);

      navigate('/minha-conta');
    } catch (err) {
      const errors = err as AxiosError;

      if (errors.response !== undefined) {
        if (errors.response.status === 403) {
          setAlert({ ...AlertTypesValue.error, content: 'Usuário com cadastro bloqueado.' });
        } else {
          setAlert({ ...AlertTypesValue.error, content: 'Usuário e/ou senha inválido(s).' });
        }
      }
      setLoading(false);
    }
  };

  const submitFormPassword = async (e: SyntheticEvent): Promise<void> => {
    try {
      e.preventDefault();

      setLoading(true);

      await ApplicationApi.get(`usuarios/lembrar-senha/${rememberEmail}`);

      setLoading(false);
      setPasswordAlert({
        ...AlertTypesValue.success,
        content: 'Enviamos uma senha de acesso em seu e-mail.',
      });
    } catch {
      setLoading(false);
      setPasswordAlert({
        ...AlertTypesValue.error,
        content: 'Não foi possível concluir operação, verifique o e-mail informado.',
      });
    }
  };

  useEffect(() => {
    if (googleResponseToken !== null) {
      ApplicationApi.get<GoogleUserResponse>('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
        headers: { Authorization: `Bearer ${googleResponseToken}` },
      })
        .then(({ data }) => {
          setLoading(false);
          navigate(`/cadastre-se?oauth=true&email=${data.email}&name=${data.given_name}&lastName=${data.family_name}`);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [googleResponseToken]);

  return (
    <LoginContainer>
      {loading ? <Loading /> : <></>}
      {activeContainer === ActiveContainerTypes.LOGIN ? (
        <Row>
          <Column xl={6}>
            <LoginBox>
              <h1>Usuário novo?</h1>
              <p>Seus dados serão cadastrados em um servidor com total segurança, fique tranquilo.</p>
              {oauthAlert !== undefined ? <Alert type={oauthAlert} /> : <></>}
              <form onSubmit={submitForm}>
                <input placeholder="E-mail" type="email" ref={inputEmailRef} required />
                <LoginSubmitContainer>
                  <div>
                    <FaGoogle onClick={oauthGoogle} />
                  </div>
                  <CustomButtom>
                    <button type="submit">Cadastre-se agora</button>
                  </CustomButtom>
                </LoginSubmitContainer>
              </form>
              <p>Caso prefira, você pode escolher iniciar o cadastro utilizando o Google ou Facebook.</p>
            </LoginBox>
          </Column>
          <Column xl={6}>
            <form onSubmit={submitFormLogin}>
              <LoginBox>
                <h1>Realize o seu login</h1>
                <p>
                  Acesse agora mesmo sua conta para acompanhar seus pedidos, ver suas ofertas exclusivas e muito mais.
                </p>
                {alert !== undefined ? <Alert type={alert} /> : <></>}
                <input
                  type="email"
                  placeholder="E-mail"
                  onChange={e => {
                    setBody({ ...body, email: e.target.value });
                  }}
                  required
                />
                <input
                  type="password"
                  placeholder="Senha"
                  onChange={e => {
                    setBody({ ...body, password: e.target.value });
                  }}
                  required
                />
                <LoginSubmitContainer>
                  <p
                    onClick={() => {
                      setActiveContainer(ActiveContainerTypes.REMEMBER_PASSWORD);
                    }}
                  >
                    Esqueci minha senha
                  </p>
                  <CustomButtom>
                    <button type="submit">Entrar</button>
                  </CustomButtom>
                </LoginSubmitContainer>
              </LoginBox>
            </form>
          </Column>
        </Row>
      ) : (
        <></>
      )}
      {activeContainer === ActiveContainerTypes.REMEMBER_PASSWORD ? (
        <PasswordBox>
          <form onSubmit={submitFormPassword}>
            <FaArrowLeft
              onClick={() => {
                setActiveContainer(ActiveContainerTypes.LOGIN);
              }}
            />
            <h1>Lembrar Senha</h1>
            <p>Digite seu e-mail e enviaremos uma senha termporária para seu acesso.</p>
            {passowordAlert !== undefined ? <Alert type={passowordAlert} /> : <></>}
            <input
              onChange={e => {
                setRememberEmail(e.target.value);
              }}
              type="email"
              required
            />
            <CustomButtom>
              <button type="submit">ENVIAR</button>
            </CustomButtom>
          </form>
        </PasswordBox>
      ) : (
        <></>
      )}
    </LoginContainer>
  );
};

export default Login;
