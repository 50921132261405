import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingContainer = styled.div<{ radius?: number }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: ${({ radius }) => (radius !== undefined ? `${radius}px` : '0')};
  z-index: 8;

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.background.primary};
    animation: ${spinAnimation} 1.5s linear infinite;
  }
`;
