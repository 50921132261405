import React, { type ReactElement } from 'react';
import { FancyboxCloseButton, FancyboxContainer, FancyboxContent } from '../styles/components/fancybox';
import { FaX } from 'react-icons/fa6';

export enum FancyboxSizes {
  LARGE = '1000px',
  MEDIUM = '700px',
  SMALL = '300px',
}

interface FancyboxProps {
  children: ReactElement;
  active: boolean;
  size: FancyboxSizes;
  setState?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Fancybox: React.FC<FancyboxProps> = ({ children, active, size, setState }) => {
  return (
    <FancyboxContainer active={active}>
      {setState !== undefined ? (
        <FancyboxCloseButton>
          <button
            onClick={() => {
              setState(false);
            }}
          >
            <FaX />
          </button>
        </FancyboxCloseButton>
      ) : (
        <></>
      )}
      <FancyboxContent size={size}>{children}</FancyboxContent>
    </FancyboxContainer>
  );
};

export default Fancybox;
