import React, { type SyntheticEvent, useEffect, useState } from 'react';
import {
  AccountAddressesContainer,
  AccountButtonContainer,
  AccountCardsContainer,
  AccountContainer,
  AccountContainerFluid,
  AccountDataContainer,
  AccountNavContainer,
  AccountNavContainerItem,
  AccountOrdersContainer,
  ActiveContainerTitle,
  AddressContainer,
  AddressDeleteContainer,
  CardContainer,
  CardDeleteContainer,
  OrderChargebackContainer,
  OrderChargebackFormContainer,
  OrderContainer,
  OrderDeliveryContainer,
  OrderDetailsContainer,
  OrderDetailsTitleContainer,
  OrderPaymentContainer,
  OrderProductContainer,
  OrderProductImageContainer,
  OrderProductInfoContainer,
  OrderTitleContainer,
  UserContainer,
} from '../styles/pages/account';
import { primaryTheme } from '../styles/theme';
import Row from '../components/row';
import Column from '../components/column';
import { BiCreditCard, BiUserCircle } from 'react-icons/bi';
import { useAuth } from '../hooks/useAuth';
import { VscSignOut } from 'react-icons/vsc';
import { BsBoxSeam } from 'react-icons/bs';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { PiUserListBold } from 'react-icons/pi';
import CustomInput from '../components/input';
import ApplicationApi from '../api/config';
import { type UserAddressesResponse, type UserPaymentsResponse, type UserResponse } from '../interfaces/user';
import InputMask from 'react-input-mask';
import CustomButtom from '../components/button';
import Placeholder from '../components/placeholder';
import Alert, { type AlertTypes, AlertTypesValue } from '../components/alert';
import { FaAngleDown, FaAngleUp, FaClock, FaTrash } from 'react-icons/fa6';
import Loading from '../components/loading';
import { getCreditCardFlag } from '../helpers/creditCard';
import { formatDateToUS } from '../helpers/dateFormat';
import axios from 'axios';
import { type OrderResponse } from '../interfaces/order';
import { LuImageOff } from 'react-icons/lu';
import Decimal from 'decimal.js';
import { formatMoney } from '../helpers/formatNumber';
import { type ChargebackBody } from '../interfaces/chargeback';
import { useQuery } from './register';

enum ContainerTypes {
  CARDS,
  DATA,
  ADDRESSES,
  ORDERS,
}

interface ZipCodeResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface BodyData {
  fullname: string;
  birthdate: string;
  document: string;
  phone: string;
  workPhone: string | null;
  homePhone: string | null;
  gender: string;
  email: string;
  password: string;
}

const bodyDataDefaultValue = {
  fullname: '',
  birthdate: '',
  document: '',
  phone: '',
  workPhone: null,
  homePhone: null,
  gender: '',
  email: '',
  password: '',
};

interface BodyCard {
  nick?: string;
  name: string;
  flag: string;
  cardNumber: string;
  expirationMonth: number | null;
  expirationYear: number | null;
  ccv: number | null;
}

const bodyCardDefaultValue = {
  nick: '',
  name: '',
  flag: '',
  cardNumber: '',
  expirationMonth: null,
  expirationYear: null,
  ccv: null,
};

interface BodyAddress {
  nick?: string;
  address: string;
  complement?: string;
  number: string;
  district: string;
  zipCode: string;
  city: string;
  state: string;
}

const bodyAddressDefaultValue = {
  nick: '',
  address: '',
  complement: '',
  number: '',
  district: '',
  zipCode: '',
  city: '',
  state: '',
};

const Account: React.FC = () => {
  const { user, token, logOut } = useAuth();
  const query = useQuery();

  document.title = `Drogaria Malu :: Minha conta`;

  const queryPage = query.get('pagina');
  const activePage =
    queryPage !== null
      ? queryPage === 'enderecos'
        ? ContainerTypes.ADDRESSES
        : queryPage === 'cartoes'
          ? ContainerTypes.CARDS
          : queryPage === 'pedidos'
            ? ContainerTypes.ORDERS
            : ContainerTypes.DATA
      : ContainerTypes.DATA;

  const [activeContainer, setActiveContainer] = useState<ContainerTypes>(activePage);

  const [currentUser, setCurrentUser] = useState<UserResponse>();
  const [userPayments, setUserPayments] = useState<UserPaymentsResponse[]>([]);
  const [userAddresses, setUserAddresses] = useState<UserAddressesResponse[]>([]);
  const [userOrders, setUserOrders] = useState<OrderResponse[]>([]);

  const [refreshUserPayments, setRefreshUserPayments] = useState<number>(0);
  const [refreshUserAddresses, setRefreshUserAddresses] = useState<number>(0);
  const [refreshUserOrders] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [cardsLoading, setCardsLoading] = useState<boolean>(false);
  const [cardFormLoading, setCardFormLoading] = useState<boolean>(false);
  const [chargebackFormLoading, setChargebackFormLoading] = useState<boolean>(false);
  const [addressesLoading, setAddressesLoading] = useState<boolean>(false);
  const [addressesFormLoading, setAddressesFormLoading] = useState<boolean>(false);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [dataFormLoading, setDataFormLoading] = useState<boolean>(false);

  const [cardsAlert, setCardsAlert] = useState<AlertTypes>();
  const [cardFormAlert, setCardFormAlert] = useState<AlertTypes>();
  const [addressesAlert, setAddressesAlert] = useState<AlertTypes>();
  const [addressFormAlert, setAddressFormAlert] = useState<AlertTypes>();
  const [ordersAlert] = useState<AlertTypes>();
  const [dataFormAlert, setDataFormAlert] = useState<AlertTypes>();
  const [chargebackFormAlert, setChargebackFormAlert] = useState<AlertTypes>();

  const [bodyCard, setBodyCard] = useState<BodyCard>(bodyCardDefaultValue);
  const [bodyData, setBodyData] = useState<BodyData>(bodyDataDefaultValue);
  const [bodyAddress, setBodyAddress] = useState<BodyAddress>(bodyAddressDefaultValue);
  const [bodyChargeback, setBodyChargeback] = useState<ChargebackBody>();

  const [collapseOrders, setCollapseOrders] = useState<Array<{ id: number; active: boolean }>>();
  const [chargebackForm, setChargebackForm] = useState<boolean>(false);

  useEffect(() => {
    ApplicationApi.get<UserResponse>(`usuarios/${user?.id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setCurrentUser(data);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (currentUser !== undefined) {
      setBodyData({
        birthdate: new Date(currentUser.birthdate).toLocaleDateString('pt-BR'),
        document: currentUser.document,
        email: currentUser.email,
        fullname: currentUser.fullname,
        gender: currentUser.gender,
        homePhone: currentUser.homePhone,
        password: currentUser.password,
        phone: currentUser.phone,
        workPhone: currentUser.workPhone,
      });
    }
  }, [currentUser]);

  useEffect(() => {
    setOrdersLoading(true);

    ApplicationApi.get<OrderResponse[]>(`usuarios/${user?.id}/pedidos`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setUserOrders(data);
        setCollapseOrders(data.map((order, i) => ({ id: order.id, active: i === 0 })));
        setOrdersLoading(false);
      })
      .catch(() => {});
  }, [refreshUserOrders]);

  useEffect(() => {
    setCardsLoading(true);

    ApplicationApi.get<UserPaymentsResponse[]>(`usuarios/${user?.id}/pagamentos`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setUserPayments(data);
        setCardsLoading(false);
      })
      .catch(() => {});
  }, [refreshUserPayments]);

  useEffect(() => {
    setAddressesLoading(true);

    ApplicationApi.get<UserAddressesResponse[]>(`usuarios/${user?.id}/enderecos`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setUserAddresses(data);
        setAddressesLoading(false);
      })
      .catch(() => {});
  }, [refreshUserAddresses]);

  const searchCep = async (e: React.FocusEvent<HTMLInputElement, Element>): Promise<void> => {
    try {
      setAddressesFormLoading(true);
      const zipCode = e.target.value;
      const { data } = await axios.get<ZipCodeResponse>(`https://viacep.com.br/ws/${zipCode}/json`);

      setBodyAddress({
        ...bodyAddress,
        address: data.logradouro,
        district: data.bairro,
        city: data.localidade,
        state: data.uf,
      });
      setAddressesFormLoading(false);
    } catch {
      setAddressesFormLoading(false);
    }
  };

  const saveCreditCard = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setCardFormLoading(true);
    setCardsAlert(undefined);

    try {
      await ApplicationApi.post<UserPaymentsResponse>(`usuarios/${user?.id}/pagamentos`, bodyCard, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRefreshUserPayments(refresh => refresh + 1);

      setCardFormAlert({ ...AlertTypesValue.success, content: 'Cartão de crédito cadastrado com sucesso!' });
    } catch {
      setCardFormAlert({ ...AlertTypesValue.error, content: 'Não foi possível cadastrar cartão de crédito.' });
    }

    setCardFormLoading(false);
  };

  const saveAddress = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setAddressesFormLoading(true);
    setAddressesAlert(undefined);

    try {
      await ApplicationApi.post<UserAddressesResponse>(`usuarios/${user?.id}/enderecos`, bodyAddress, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRefreshUserAddresses(refresh => refresh + 1);
      setBodyAddress(bodyAddressDefaultValue);
      setAddressFormAlert({ ...AlertTypesValue.success, content: 'Endereço cadastrado com sucesso!' });
    } catch {
      setAddressFormAlert({ ...AlertTypesValue.error, content: 'Não foi possível cadastrar endereço.' });
    }

    setAddressesFormLoading(false);
  };

  const deletePayment = async (id: number): Promise<void> => {
    setCardsLoading(true);

    try {
      await ApplicationApi.delete(`usuarios/${user?.id}/pagamentos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRefreshUserPayments(refresh => refresh + 1);
      setCardsAlert({ ...AlertTypesValue.success, content: 'Cartão de crédito excluído com sucesso.' });
    } catch {
      setCardsAlert({
        ...AlertTypesValue.error,
        content: 'Não foi possível excluir cartão de crédito, tente novamente.',
      });
    }

    setCardsLoading(false);
  };

  const deleteAddress = async (id: number): Promise<void> => {
    setAddressesLoading(true);

    try {
      await ApplicationApi.delete(`usuarios/${user?.id}/enderecos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRefreshUserAddresses(refresh => refresh + 1);
      setAddressesAlert({ ...AlertTypesValue.success, content: 'Endereço excluído com sucesso.' });
    } catch {
      setAddressesAlert({
        ...AlertTypesValue.error,
        content: 'Não foi possível excluir endereço, tente novamente.',
      });
    }

    setAddressesLoading(false);
  };

  const updateUserData = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setDataFormLoading(true);

    try {
      await ApplicationApi.patch(
        `usuarios/${user?.id}`,
        { ...bodyData, birthdate: formatDateToUS(bodyData.birthdate) },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setDataFormAlert({ ...AlertTypesValue.success, content: 'Informações atualizadas com sucesso.' });
    } catch {
      setDataFormAlert({ ...AlertTypesValue.error, content: 'Não foi possível atualizar informações.' });
    }

    setDataFormLoading(false);
  };

  const closeCollapseOrder = (id: number): void => {
    const newCollapse = collapseOrders?.map(order =>
      order.id !== id ? { id: order.id, active: order.active } : { id: order.id, active: false },
    );

    setCollapseOrders(newCollapse);
  };

  const openCollapseOrder = (id: number): void => {
    const newCollapse = collapseOrders?.map(order =>
      order.id !== id ? { id: order.id, active: order.active } : { id: order.id, active: true },
    );

    setCollapseOrders(newCollapse);
  };

  const saveChargeback = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setChargebackFormLoading(true);
    try {
      await ApplicationApi.post(`estornos/usuarios/${user?.id}`, bodyChargeback, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setChargebackFormAlert({ ...AlertTypesValue.success, content: 'Solicitação de estorno enviada com sucesso.' });
    } catch {
      setChargebackFormAlert({
        ...AlertTypesValue.error,
        content: 'Não foi possível enviar sua solicitação de estorno.',
      });
    }
    setChargebackFormLoading(false);
  };

  return (
    <AccountContainerFluid background={primaryTheme.background.secondary}>
      <AccountContainer>
        {loading ? (
          <Row>
            <Column xl={3}>
              <Placeholder height={100} />
              <Placeholder height={60} />
              <Placeholder height={60} />
              <Placeholder height={60} />
              <Placeholder height={60} />
              <Placeholder height={60} />
            </Column>
            <Column xl={9}>
              <Placeholder height={460} />
            </Column>
          </Row>
        ) : (
          <>
            {currentUser !== undefined ? (
              <Row>
                <Column xl={3}>
                  <UserContainer>
                    <BiUserCircle />
                    <p>
                      Olá, <b>{user?.name}</b>
                    </p>
                  </UserContainer>
                  <AccountNavContainer>
                    <AccountNavContainerItem
                      active={activeContainer === ContainerTypes.CARDS}
                      onClick={() => {
                        setActiveContainer(ContainerTypes.CARDS);
                      }}
                    >
                      <BiCreditCard /> Cartões
                    </AccountNavContainerItem>
                    <AccountNavContainerItem
                      active={activeContainer === ContainerTypes.DATA}
                      onClick={() => {
                        setActiveContainer(ContainerTypes.DATA);
                      }}
                    >
                      <PiUserListBold /> Dados Pessoais
                    </AccountNavContainerItem>
                    <AccountNavContainerItem
                      active={activeContainer === ContainerTypes.ADDRESSES}
                      onClick={() => {
                        setActiveContainer(ContainerTypes.ADDRESSES);
                      }}
                    >
                      <MdOutlineMapsHomeWork />
                      Endereços
                    </AccountNavContainerItem>
                    <AccountNavContainerItem
                      active={activeContainer === ContainerTypes.ORDERS}
                      onClick={() => {
                        setActiveContainer(ContainerTypes.ORDERS);
                      }}
                    >
                      <BsBoxSeam />
                      Pedidos
                    </AccountNavContainerItem>
                    <AccountNavContainerItem onClick={logOut}>
                      <VscSignOut /> Sair
                    </AccountNavContainerItem>
                  </AccountNavContainer>
                </Column>
                <Column xl={9}>
                  {activeContainer === ContainerTypes.CARDS ? (
                    <>
                      <ActiveContainerTitle>Cartões</ActiveContainerTitle>
                      <AccountCardsContainer>
                        {cardFormLoading ? <Loading /> : <></>}
                        {cardFormAlert !== undefined ? <Alert type={cardFormAlert} /> : <></>}
                        <form onSubmit={saveCreditCard}>
                          <Row>
                            <Column xl={12}>
                              <CustomInput background={primaryTheme.background.secondary} label="Apelido" margin>
                                <input
                                  onChange={e => {
                                    setBodyCard({ ...bodyCard, nick: e.target.value });
                                  }}
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Nome" margin>
                                <input
                                  onChange={e => {
                                    setBodyCard({ ...bodyCard, name: e.target.value });
                                  }}
                                  required
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Número" margin>
                                <>
                                  <InputMask
                                    mask="9999 9999 9999 9999"
                                    onChange={e => {
                                      setBodyCard({ ...bodyCard, cardNumber: e.target.value.replaceAll(' ', '') });
                                    }}
                                    onBlur={e => {
                                      setBodyCard({
                                        ...bodyCard,
                                        flag: getCreditCardFlag(e.target.value.replaceAll(' ', '')),
                                      });
                                    }}
                                    required
                                  />
                                </>
                              </CustomInput>
                            </Column>
                            <Column xl={2}>
                              <CustomInput background={primaryTheme.background.secondary} label="Validade" margin>
                                <InputMask
                                  mask="99/9999"
                                  onBlur={e => {
                                    setBodyCard({
                                      ...bodyCard,
                                      expirationMonth: parseInt(e.target.value.split('/')[0]),
                                      expirationYear: parseInt(e.target.value.split('/')[1]),
                                    });
                                  }}
                                  required
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={2}>
                              <CustomInput background={primaryTheme.background.secondary} label="CCV" margin>
                                <InputMask
                                  mask="999"
                                  onChange={e => {
                                    setBodyCard({ ...bodyCard, ccv: parseInt(e.target.value) });
                                  }}
                                  required
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={3}>
                              <AccountButtonContainer>
                                <CustomButtom background="#077ca8">
                                  <button type="reset">LIMPAR</button>
                                </CustomButtom>
                              </AccountButtonContainer>
                            </Column>
                            <Column xl={3}>
                              <CustomButtom>
                                <button type="submit">SALVAR</button>
                              </CustomButtom>
                            </Column>
                          </Row>
                        </form>
                      </AccountCardsContainer>
                      <ActiveContainerTitle>Cartões cadastrados</ActiveContainerTitle>
                      <AccountCardsContainer>
                        {cardsLoading ? <Loading /> : <></>}
                        {cardsAlert !== undefined ? <Alert type={cardsAlert} /> : <></>}
                        {userPayments.length > 0 ? (
                          <>
                            {userPayments.map((card, i) => (
                              <CardContainer key={i}>
                                <CardDeleteContainer
                                  onClick={async () => {
                                    await deletePayment(card.id);
                                  }}
                                >
                                  <FaTrash />
                                </CardDeleteContainer>
                                {card.nick !== null ? <h1>{card.nick}</h1> : <></>}
                                <h2>{card.name}</h2>
                                <h3>
                                  {card.cardNumber} - {card.flag}
                                </h3>
                              </CardContainer>
                            ))}
                          </>
                        ) : (
                          <Alert type={{ ...AlertTypesValue.warning, content: 'Nenhum cartão encontrado' }} />
                        )}
                      </AccountCardsContainer>
                    </>
                  ) : (
                    <></>
                  )}
                  {activeContainer === ContainerTypes.DATA ? (
                    <>
                      <ActiveContainerTitle>Dados Pessoais</ActiveContainerTitle>
                      <AccountDataContainer>
                        {dataFormLoading ? <Loading /> : <></>}
                        {dataFormAlert !== undefined ? <Alert type={dataFormAlert} /> : <></>}
                        <form onSubmit={updateUserData}>
                          <Row>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Nome" margin>
                                <input required defaultValue={currentUser?.fullname.split(' ')[0]} readOnly />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Sobrenome" margin>
                                <input required defaultValue={currentUser?.fullname.split(' ').slice(1)} readOnly />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Nascimento" margin>
                                <input
                                  required
                                  defaultValue={new Date(currentUser?.birthdate ?? '').toLocaleDateString('pt-BR')}
                                  readOnly
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={6}>
                              <CustomInput background={primaryTheme.background.secondary} label="Documento" margin>
                                <input required defaultValue={currentUser?.document} readOnly />
                              </CustomInput>
                            </Column>
                            <Column xl={6}>
                              <CustomInput background={primaryTheme.background.secondary} label="Sexo" margin>
                                <select
                                  onChange={e => {
                                    setBodyData({ ...bodyData, gender: e.target.value });
                                  }}
                                >
                                  <option value="">Selecione</option>
                                  <option selected={currentUser.gender === 'Feminino'}>Feminino</option>
                                  <option selected={currentUser.gender === 'Masculino'}>Masculino</option>
                                  <option selected={currentUser.gender === 'Prefiro não dizer'}>
                                    Prefiro não dizer
                                  </option>
                                </select>
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Celular" margin>
                                <InputMask
                                  mask="(99) 99999-9999"
                                  value={bodyData.phone}
                                  onChange={e => {
                                    setBodyData({ ...bodyData, phone: e.target.value });
                                  }}
                                  required
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput
                                background={primaryTheme.background.secondary}
                                label="Telefone Residencial"
                                margin
                              >
                                <InputMask
                                  mask="(99) 9999-9999"
                                  value={bodyData.homePhone ?? ''}
                                  onChange={e => {
                                    setBodyData({ ...bodyData, homePhone: e.target.value });
                                  }}
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput
                                background={primaryTheme.background.secondary}
                                label="Telefone Comercial"
                                margin
                              >
                                <InputMask
                                  mask="(99) 9999-9999"
                                  value={bodyData.workPhone ?? ''}
                                  onChange={e => {
                                    setBodyData({ ...bodyData, workPhone: e.target.value });
                                  }}
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={8}>
                              <CustomInput background={primaryTheme.background.secondary} label="E-mail" margin>
                                <input
                                  type="email"
                                  required
                                  defaultValue={currentUser.email}
                                  onChange={e => {
                                    setBodyData({ ...bodyData, email: e.target.value });
                                  }}
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Senha" margin>
                                <input
                                  type="password"
                                  required
                                  onChange={e => {
                                    setBodyData({ ...bodyData, password: e.target.value });
                                  }}
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={3}>
                              <AccountButtonContainer>
                                <CustomButtom background="#077ca8">
                                  <button type="reset">LIMPAR</button>
                                </CustomButtom>
                              </AccountButtonContainer>
                            </Column>
                            <Column xl={3}>
                              <CustomButtom>
                                <button type="submit">SALVAR</button>
                              </CustomButtom>
                            </Column>
                          </Row>
                        </form>
                      </AccountDataContainer>
                    </>
                  ) : (
                    <></>
                  )}
                  {activeContainer === ContainerTypes.ADDRESSES ? (
                    <>
                      <ActiveContainerTitle>Endereços</ActiveContainerTitle>
                      <AccountAddressesContainer>
                        {addressesFormLoading ? <Loading /> : <></>}
                        {addressFormAlert !== undefined ? <Alert type={addressFormAlert} /> : <></>}
                        <form onSubmit={saveAddress}>
                          <Row>
                            <Column xl={6}>
                              <CustomInput background={primaryTheme.background.secondary} label="Apelido" margin>
                                <input
                                  value={bodyAddress.nick ?? ''}
                                  onChange={e => {
                                    setBodyAddress({ ...bodyAddress, nick: e.target.value });
                                  }}
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={6}>
                              <CustomInput background={primaryTheme.background.secondary} label="CEP" margin>
                                <InputMask
                                  mask="99999-999"
                                  onChange={e => {
                                    setBodyAddress({ ...bodyAddress, zipCode: e.target.value });
                                  }}
                                  value={bodyAddress.zipCode}
                                  onBlur={searchCep}
                                  required
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Cidade" margin>
                                <input required value={bodyAddress.city} readOnly />
                              </CustomInput>
                            </Column>
                            <Column xl={2}>
                              <CustomInput background={primaryTheme.background.secondary} label="Estado" margin>
                                <input required value={bodyAddress.state} readOnly />
                              </CustomInput>
                            </Column>
                            <Column xl={6}>
                              <CustomInput background={primaryTheme.background.secondary} label="Bairro" margin>
                                <input required value={bodyAddress.district} readOnly />
                              </CustomInput>
                            </Column>
                            <Column xl={4}>
                              <CustomInput background={primaryTheme.background.secondary} label="Endereço" margin>
                                <input required value={bodyAddress.address} readOnly />
                              </CustomInput>
                            </Column>
                            <Column xl={2}>
                              <CustomInput background={primaryTheme.background.secondary} label="Número" margin>
                                <input
                                  required
                                  defaultValue={bodyAddress.number}
                                  onChange={e => {
                                    setBodyAddress({ ...bodyAddress, number: e.target.value });
                                  }}
                                  value={bodyAddress.number}
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={6}>
                              <CustomInput background={primaryTheme.background.secondary} label="Complemento" margin>
                                <input
                                  defaultValue={bodyAddress.complement}
                                  onChange={e => {
                                    setBodyAddress({ ...bodyAddress, complement: e.target.value });
                                  }}
                                />
                              </CustomInput>
                            </Column>
                            <Column xl={3}>
                              <AccountButtonContainer>
                                <CustomButtom background="#077ca8">
                                  <button type="reset">LIMPAR</button>
                                </CustomButtom>
                              </AccountButtonContainer>
                            </Column>
                            <Column xl={3}>
                              <CustomButtom>
                                <button type="submit">SALVAR</button>
                              </CustomButtom>
                            </Column>
                          </Row>
                        </form>
                      </AccountAddressesContainer>
                      <ActiveContainerTitle>Endereços cadastrados</ActiveContainerTitle>
                      <AccountAddressesContainer>
                        {addressesLoading ? <Loading /> : <></>}
                        {addressesAlert !== undefined ? <Alert type={addressesAlert} /> : <></>}
                        {userAddresses.length > 0 ? (
                          <>
                            {userAddresses.map((address, i) => (
                              <AddressContainer key={i}>
                                <AddressDeleteContainer
                                  onClick={async () => {
                                    await deleteAddress(address.id);
                                  }}
                                >
                                  <FaTrash />
                                </AddressDeleteContainer>
                                {address.nick !== null ? <h1>{address.nick}</h1> : <></>}
                                <h2>
                                  {address.address}, {address.number}
                                </h2>
                                <h3>
                                  CEP {address.zipCode} - {address.city}/{address.state}
                                </h3>
                              </AddressContainer>
                            ))}
                          </>
                        ) : (
                          <Alert type={{ ...AlertTypesValue.warning, content: 'Nenhum endereço encontrado' }} />
                        )}
                      </AccountAddressesContainer>
                    </>
                  ) : (
                    <></>
                  )}
                  {activeContainer === ContainerTypes.ORDERS ? (
                    <>
                      <ActiveContainerTitle>Pedidos</ActiveContainerTitle>
                      <AccountOrdersContainer>
                        {ordersLoading ? <Loading /> : <></>}
                        {ordersAlert !== undefined ? <Alert type={ordersAlert} /> : <></>}
                        {userOrders.length > 0 ? (
                          <>
                            {userOrders.map((order, i) => (
                              <OrderContainer key={i}>
                                {collapseOrders?.find(item => item.id === order.id)?.active ?? false ? (
                                  <>
                                    <OrderTitleContainer>
                                      <h1>
                                        #{order.code} - {order.orderStatus.name}
                                      </h1>
                                      <FaAngleUp
                                        onClick={() => {
                                          closeCollapseOrder(order.id);
                                        }}
                                      />
                                    </OrderTitleContainer>
                                    <OrderDetailsContainer active>
                                      {order.orderProducts.map((orderProduct, i) => (
                                        <OrderProductContainer key={i}>
                                          <OrderProductImageContainer>
                                            {orderProduct.products.productImages !== null ? (
                                              <img src={orderProduct.products.productImages[0].uploads.origin} />
                                            ) : (
                                              <LuImageOff />
                                            )}
                                          </OrderProductImageContainer>
                                          <OrderProductInfoContainer>
                                            <div>
                                              <h1> {orderProduct.products.name}</h1>
                                              <h2>{orderProduct.quantity} Unidade(s)</h2>
                                            </div>
                                            <div>
                                              {orderProduct.products.newPrice !== null ? (
                                                <>
                                                  <h4>
                                                    R$
                                                    {formatMoney(
                                                      Decimal.mul(
                                                        orderProduct.products.price,
                                                        orderProduct.quantity,
                                                      ).toFixed(2),
                                                    )}
                                                  </h4>
                                                  <h3>
                                                    R$
                                                    {formatMoney(
                                                      Decimal.mul(
                                                        orderProduct.products.newPrice,
                                                        orderProduct.quantity,
                                                      ).toFixed(2),
                                                    )}
                                                  </h3>
                                                </>
                                              ) : (
                                                <h3>
                                                  R$
                                                  {formatMoney(
                                                    Decimal.mul(
                                                      orderProduct.products.price,
                                                      orderProduct.quantity,
                                                    ).toFixed(2),
                                                  )}
                                                </h3>
                                              )}
                                            </div>
                                          </OrderProductInfoContainer>
                                        </OrderProductContainer>
                                      ))}
                                      <OrderDetailsTitleContainer>
                                        <h2>SUBTOTAL</h2>
                                        <h2>R$ {formatMoney(new Decimal(order.subTotal).toFixed(2))}</h2>
                                      </OrderDetailsTitleContainer>
                                      <OrderDetailsTitleContainer>
                                        <h2>FRETE</h2>
                                        <h2>R$ {formatMoney(new Decimal(order.orderDeliveries.price).toFixed(2))}</h2>
                                      </OrderDetailsTitleContainer>
                                      <OrderDetailsTitleContainer>
                                        <h1>TOTAL</h1>
                                        <h1>R$ {formatMoney(new Decimal(order.amount).toFixed(2))}</h1>
                                      </OrderDetailsTitleContainer>
                                    </OrderDetailsContainer>
                                    <OrderDetailsContainer active>
                                      {order.orderPayments.paymentsTypes.id === 1 ? (
                                        <OrderPaymentContainer>
                                          <h2>{order.orderPayments.paymentsTypes.name}</h2>
                                          <h3>{order.orderPayments.userPayments?.name}</h3>
                                          <h3>
                                            {order.orderPayments.userPayments?.cardNumber} -{' '}
                                            {order.orderPayments.userPayments?.flag}
                                          </h3>
                                        </OrderPaymentContainer>
                                      ) : (
                                        <></>
                                      )}
                                      {order.orderPayments.paymentsTypes.id === 2 ? (
                                        <OrderPaymentContainer>
                                          <h2>{order.orderPayments.paymentsTypes.name}</h2>
                                          {order.orderPayments.ticketLink !== null ? (
                                            <>
                                              <h3>{order.orderPayments.ticketLink}</h3>
                                              <a href={order.orderPayments.ticketLink} target="new">
                                                CLIQUE AQUI PARA VISUALIZAR
                                              </a>
                                            </>
                                          ) : (
                                            <h3>BOLETO NÃO DISPONÍVEL</h3>
                                          )}
                                        </OrderPaymentContainer>
                                      ) : (
                                        <></>
                                      )}
                                      {order.orderPayments.paymentsTypes.id === 3 ? (
                                        <OrderPaymentContainer>
                                          <h2>{order.orderPayments.paymentsTypes.name}</h2>
                                          {order.orderPayments.qrLink !== null ? (
                                            <>
                                              <h3>{order.orderPayments.qrCode}</h3>
                                              <a href={order.orderPayments.qrLink} target="new">
                                                CLIQUE AQUI PARA VISUALIZAR
                                              </a>
                                            </>
                                          ) : (
                                            <h3>QRCODE NÃO DISPONÍVEL</h3>
                                          )}
                                        </OrderPaymentContainer>
                                      ) : (
                                        <></>
                                      )}
                                      {order.orderPayments.paymentsTypes.id === 4 ? (
                                        <OrderPaymentContainer>
                                          <h2>{order.orderPayments.paymentsTypes.description}</h2>
                                          <h3>{order.orderPayments.observations ?? 'Nenhuma observação'}</h3>
                                        </OrderPaymentContainer>
                                      ) : (
                                        <></>
                                      )}
                                    </OrderDetailsContainer>
                                    <OrderDetailsContainer active>
                                      {order.orderDeliveries.deliveryTypes.id === 1 ? (
                                        <OrderDeliveryContainer>
                                          <h2>{order.orderDeliveries.deliveryTypes.name}</h2>
                                          {order.orderDeliveries.fastDeliveries !== null ? (
                                            <>
                                              <h3>
                                                <FaClock /> {order.orderDeliveries.fastDeliveries.deliveryTime} min
                                              </h3>
                                              <h3>
                                                {order.orderDeliveries.userAddresses?.address},
                                                {order.orderDeliveries.userAddresses?.number} -{' '}
                                                {order.orderDeliveries.userAddresses?.district}
                                              </h3>
                                              <h3>
                                                CEP {order.orderDeliveries.userAddresses?.zipCode} -{' '}
                                                {order.orderDeliveries.userAddresses?.city}/
                                                {order.orderDeliveries.userAddresses?.state}
                                              </h3>
                                            </>
                                          ) : (
                                            <h3>Não disponível</h3>
                                          )}
                                        </OrderDeliveryContainer>
                                      ) : (
                                        <></>
                                      )}
                                      {order.orderDeliveries.deliveryTypes.id === 2 ? (
                                        <OrderDeliveryContainer>
                                          <h2>{order.orderDeliveries.deliveryTypes.name}</h2>
                                          <h3>
                                            {order.orderDeliveries.company} - de {order.orderDeliveries.deliveryTimeMin}{' '}
                                            a {order.orderDeliveries.deliveryTimeMax} dia(s) úteis
                                          </h3>
                                        </OrderDeliveryContainer>
                                      ) : (
                                        <></>
                                      )}
                                      {order.orderDeliveries.deliveryTypes.id === 3 ? (
                                        <OrderDeliveryContainer>
                                          <h2>{order.orderDeliveries.deliveryTypes.name}</h2>
                                          <h3>Disponível em 1 dia(s) úteis</h3>
                                        </OrderDeliveryContainer>
                                      ) : (
                                        <></>
                                      )}
                                    </OrderDetailsContainer>
                                    {[4, 5].includes(order.orderStatus.id) &&
                                    order.orderPayments.paymentTypeId === 1 ? (
                                      <OrderDetailsContainer active>
                                        {order.chargebacks !== null ? (
                                          <OrderChargebackContainer>
                                            <h3>
                                              Solicitação criada em{' '}
                                              {new Date(order.chargebacks.createdAt).toLocaleDateString('pt-BR', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                              })}
                                            </h3>
                                          </OrderChargebackContainer>
                                        ) : (
                                          <>
                                            <OrderChargebackContainer>
                                              {chargebackFormAlert !== undefined ? (
                                                <Alert type={chargebackFormAlert} />
                                              ) : (
                                                <>
                                                  <h2>
                                                    Houve algum problema com seu pedido e deseja cancelar a compra?
                                                  </h2>
                                                  <OrderChargebackFormContainer active={chargebackForm}>
                                                    {chargebackFormLoading ? <Loading /> : <></>}
                                                    <form onSubmit={saveChargeback}>
                                                      <Row>
                                                        <Column xl={12}>
                                                          <textarea
                                                            placeholder="Digite aqui o motivo de sua solicitação"
                                                            required
                                                            onChange={e => {
                                                              setBodyChargeback({
                                                                orderId: order.id,
                                                                reason: e.target.value,
                                                              });
                                                            }}
                                                          />
                                                        </Column>
                                                        <Column xl={3}>
                                                          <button type="submit">Enviar solicitação</button>
                                                        </Column>
                                                      </Row>
                                                    </form>
                                                  </OrderChargebackFormContainer>
                                                </>
                                              )}
                                              {!chargebackForm ? (
                                                <button
                                                  onClick={() => {
                                                    setChargebackForm(true);
                                                  }}
                                                >
                                                  Clique aqui e abra uma solicitação de estorno
                                                </button>
                                              ) : (
                                                <></>
                                              )}
                                            </OrderChargebackContainer>
                                          </>
                                        )}
                                      </OrderDetailsContainer>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <OrderTitleContainer>
                                      <h1>
                                        #{order.code} - {order.orderStatus.name}
                                      </h1>
                                      <FaAngleDown
                                        onClick={() => {
                                          openCollapseOrder(order.id);
                                        }}
                                      />
                                    </OrderTitleContainer>
                                  </>
                                )}
                              </OrderContainer>
                            ))}
                          </>
                        ) : (
                          <Alert type={{ ...AlertTypesValue.warning, content: 'Nenhum pedido encontrado' }} />
                        )}
                      </AccountOrdersContainer>
                    </>
                  ) : (
                    <></>
                  )}
                </Column>
              </Row>
            ) : (
              <></>
            )}
          </>
        )}
      </AccountContainer>
    </AccountContainerFluid>
  );
};

export default Account;
