import React, { type ReactElement } from 'react';
import { RowContainer } from '../styles/components/row';

interface RowProps {
  children: ReactElement | ReactElement[];
}

const Row: React.FC<RowProps> = ({ children }) => {
  return <RowContainer>{children}</RowContainer>;
};

export default Row;
