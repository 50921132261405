import { styled } from 'styled-components';

interface HeaderDropdownContainerProps {
  active: boolean;
}

export const HeaderContainerFluid = styled.div`
  width: 100%;
  padding: 18px 12px;
  background-color: ${({ theme }) => theme.background.primary};
  color: ${({ theme }) => theme.color.primary};
  display: none;

  @media (min-width: 1200px) {
    display: block !important;
  }

  @media (min-width: 1440px) {
    padding: 18px 48px !important;
  }
`;

export const HeaderContainerMenuMobile = styled.div`
  display: none;
  padding: 0 15px;
  height: 48px;
  background: ${({ theme }) => theme.background.primary};
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;

  img {
    width: auto;
    height: 60%;
  }

  svg {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const HeaderContainerNavMobile = styled.div<{ active: boolean }>`
  display: none;
  padding: 8px 15px;
  background: #24916b;
  color: ${({ theme }) => theme.color.primary};
  position: fixed;
  width: 100%;
  top: 48px;
  left: 0;
  z-index: 9;
  -webkit-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.75);

  @media (max-width: 1200px) {
    display: ${({ active }) => (active ? 'block' : 'none')};
  }

  ul {
    list-style: none;
    font-size: 0.8rem;

    li {
      font-weight: 600;
      height: 42px;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 4px;
        font-size: 1.2rem;
      }
    }
  }
`;

export const HeaderContainerMenuMobilePadding = styled.div`
  display: none;
  height: 48px;

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  padding: 0 12px;

  @media (min-width: 1600px) {
    & {
      width: auto;
      height: 58px;
      padding: 0;
    }
  }
`;

export const HeaderInput = styled.div`
  height: 58px;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  display: flex;

  button {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 0;
    cursor: pointer;

    svg {
      color: ${({ theme }) => theme.background.primary};
      font-size: 1.6rem;
    }
  }

  input {
    font-family: Montserrat, sans-serif;
    border: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    padding-left: 12px;
    color: ${({ theme }) => theme.color.third};

    @media (min-width: 1200px) {
      font-size: 0.7rem;
    }

    @media (min-width: 1440px) {
      font-size: 1rem;
    }
  }
`;

export const HeaderButton = styled.div`
  position: relative;

  div {
    position: absolute;
    right: 14px;
    top: 15px;
    width: 14px;
    height: 14px;
    background: ${({ theme }) => theme.background.third};
    font-size: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50%;
    border: 0;
  }

  button {
    width: 100%;
    height: 58px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    transition: filter 0.1s ease;
    text-align: left;

    @media (min-width: 1200px) {
      font-size: 0.7rem;
    }

    @media (min-width: 1440px) {
      font-size: 1rem;
    }

    svg {
      font-size: 2.3rem;
      margin-right: 12px;
    }
  }

  button:hover {
    filter: contrast(1.3);
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderDropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    height: 58px;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    transition: filter 0.1s ease;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;

    @media (min-width: 1200px) {
      font-size: 0.7rem;
    }

    @media (min-width: 1440px) {
      font-size: 1rem;
    }

    svg {
      font-size: 2.3rem;
      color: ${({ theme }) => theme.color.primary};
      margin-right: 8px;
    }
  }

  button:hover {
    filter: contrast(1.3);
  }
`;

export const HeaderDropdownContent = styled.div<HeaderDropdownContainerProps>`
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  position: absolute;
  display: block;
  background: #fff;
  width: 400px;
  top: calc(100% + 8px);
  border-radius: 6px;
  padding: 12px;
  -webkit-box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);
  color: ${({ theme }) => theme.color.secondary};
  z-index: 8;

  p {
    text-align: justify;
    font-size: 0.7rem;
  }
`;

export const DeliveryFancyboxContent = styled.div`
  display: block;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
  color: ${({ theme }) => theme.color.secondary};

  p {
    text-align: justify;
    font-size: 0.7rem;
  }
`;

export const HeaderDropdownInput = styled.div`
  border: 1px solid ${({ theme }) => theme.background.primary};
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  display: flex;
  height: 48px;

  div {
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.color.third};
    }
  }

  input {
    width: 100%;
    border: 0;
    outline: 0;
    height: 100%;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.color.third};
  }
`;

export const HeaderDropdownAddressContainer = styled.div`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.background.secondary};
  border: 1px solid ${({ theme }) => theme.background.primary};
  padding: 0 12px;
  border-radius: 8px;
  color: ${({ theme }) => theme.color.third};
  margin-bottom: 12px;

  div {
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }

  p {
    font-size: 0.9rem;
  }

  svg {
    font-size: 1.5rem;
  }
`;

export const HeaderDropdownSearchContainer = styled.div<{ active: boolean }>`
  position: relative;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const HeaderDropdownSearchContentContainer = styled.div`
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0;
  top: calc(100% + 8px);
  z-index: 8;
  -webkit-box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  overflow: hidden;

  p {
    text-align: center;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.color.third};
    padding: 6px 0;
  }
`;

export const DropdownSearchNoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: ${({ theme }) => theme.color.third};

  svg {
    font-size: 2rem;
  }

  p {
    font-weight: 600;
  }
`;

export const HeaderDropdownSearchLoadingContainer = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;

export const DropdownProductContainer = styled.div<{ grey: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 8px;
  background: ${({ grey, theme }) => (grey ? theme.background.secondary : '#fff')};

  p {
    color: ${({ theme }) => theme.color.third};
  }
`;

export const DropdownProductInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownProductInfoBox = styled.div`
  display: block;
  overflow: hidden;

  h1 {
    font-size: 0.9rem;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.color.secondary};
  }

  h3 {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.background.primary};
  }
`;

export const DropdownProductInfoImage = styled.div`
  width: 60px;
  height: 60px;
  border: 1px solid ${({ theme }) => theme.background.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 6px;
  margin-right: 8px;

  img {
    width: 80%;
    height: 80%;
  }
`;

export const CartContainer = styled.div<{ active: boolean }>`
  width: 350px;
  right: ${({ active }) => (active ? '0' : '-350px')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  top: 0;
  position: fixed;
  height: 100%;
  background: #fff;
  z-index: 10;
  -webkit-box-shadow: -4px 0px 19px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 0px 19px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -4px 0px 19px -9px rgba(0, 0, 0, 0.75);
  transition:
    right 0.4s ease,
    visibility 0.4s ease;
`;

export const CartCloseButton = styled.button`
  width: 40px;
  height: 40px;
  border: 0;
  background: ${({ theme }) => theme.background.secondary};
  color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const CardAmountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 12px;
  font-size: 0.9rem;

  h1 {
    font-weight: 600;
    font-size: 0.9rem;
  }

  p {
    color: #666;
  }
`;

export const CartButtonContainer = styled.div`
  padding: 0 8px;
  display: flex;

  button:last-child {
    margin-left: 4px;
    background: ${({ theme }) => theme.background.primary};
  }

  button {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    border: 0;
    color: #eee;
    background: #1482ae;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    cursor: pointer;

    svg {
      font-size: 1.3rem;
    }
  }
`;

export const CartItemContainer = styled.div`
  padding: 8px;
  margin: 8px;
  background: ${({ theme }) => theme.background.secondary};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const CartItemImageContainer = styled.div`
  background: #fff;
  width: 70px;
  height: 70px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.color.third};
    font-size: 2rem;
  }

  img {
    width: 80%;
    max-height: 80%;
  }
`;

export const CartItemInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const CartItemInfoTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;
  padding: 4px 0;

  h1 {
    font-size: 0.8rem;
  }

  h2 {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.background.primary};
  }

  h3 {
    font-size: 0.9rem;
  }
`;

export const CartItemQuantityContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 8px;
  }

  button:last-child {
    margin-left: 8px;
    background: #ff0000;
  }

  button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: #1482ae;
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;
  }
`;

export const CartNoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;

  button {
    padding: 0px 32px;
  }

  h1 {
    font-size: 1rem;
    margin-bottom: 8px;
    text-align: center;
  }

  p {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 300;
    margin-bottom: 8px;
  }
`;

export const HiddenHeaderContainer = styled.div<{ active: boolean }>`
  position: fixed;
  top: ${({ active }) => (active ? '0' : '-48px')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  display: none;
  align-items: center;
  justify-content: space-between;
  left: 0;
  width: 100%;
  padding: 9px 24px;
  z-index: 8;
  transition:
    top 0.2s ease,
    visibility 0.1s ease;
  background-color: ${({ theme }) => theme.background.primary};
  color: ${({ theme }) => theme.color.primary};
  -webkit-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.75);

  @media (min-width: 1200px) {
    & {
      display: flex !important;
    }
  }

  div,
  form {
    display: flex;
  }

  input {
    border: 0;
    height: 30px;
    border-radius: 5px;
    padding: 0 12px;
    width: 300px;
    outline: 0;
  }

  button {
    width: 30px;
    height: 30px;
    font-size: 1rem;
    background-color: #299973;
    border-radius: 8px;
    margin-left: 5px;
    color: ${({ theme }) => theme.color.primary};
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    div {
      width: 12px;
      height: 12px;
      background: ${({ theme }) => theme.background.third};
      position: absolute;
      right: -4px;
      top: -4px;
      font-size: 0.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const HiddenHeaderLogo = styled.img`
  height: 30px;
`;

export const HeaderLoginContainer = styled.div`
  padding: 18px;
  background: #fff;
  border-radius: 12px;
`;

export const SearchNavMobileContainer = styled.div`
  display: flex;
  width: 100%;

  input {
    background: #107854;
    border: 0;
    height: 38px;
    border-radius: 6px;
    width: 100%;
    outline: none !important;
    padding: 0 12px;
    color: #eee;
  }

  button {
    margin-left: 5px;
    border-radius: 6px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    padding: 0;
    color: #107854;

    svg {
      margin-right: 0 !important;
    }
  }
`;
