import React, { useEffect, useState } from 'react';
import {
  ProductContainerTitle,
  ProductPaginationBox,
  ProductPaginationContainer,
  ProductPaginationItem,
  ProductsContainer,
  ProductsContainerFluid,
  ProductsContainerSidebar,
} from '../../styles/pages/product';
import Product from '../includes/product';
import { primaryTheme } from '../../styles/theme';
import Row from '../../components/row';
import Column from '../../components/column';
import Advertisement from '../includes/advertising';
import { useParams } from 'react-router-dom';
import { type CategoryResponse } from '../../interfaces/category';
import ApplicationApi from '../../api/config';
import { type ProductPaginationResponse } from '../../interfaces/product';
import Loading from '../../components/loading';
import Alert, { AlertTypesValue } from '../../components/alert';
import { type SubcategoryResponse } from '../../interfaces/subcategory';
import { type AdvertisementResponse } from '../../interfaces/advertisements';
import { type BrandResponse } from '../../interfaces/brand';
import Sidebar from '../includes/sidebar';

const Products: React.FC = () => {
  const params = useParams();

  const [category, setCategory] = useState<CategoryResponse>();
  const [subcategory, setSubcategory] = useState<SubcategoryResponse>();
  const [brand, setBrand] = useState<BrandResponse>();
  const [products, setProducts] = useState<ProductPaginationResponse>();
  const [advertising, setAdvertising] = useState<AdvertisementResponse>();

  const [productsLoading, setProductsLoading] = useState<boolean>(true);

  const [totalItems, setTotalItems] = useState<number>();
  const [pages, setPages] = useState<number>();
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(12);

  document.title = `Drogaria Malu :: Produtos`;

  useEffect(() => {
    ApplicationApi.get<AdvertisementResponse>(`anuncios/buscar`)
      .then(({ data }) => {
        setAdvertising(data);
      })
      .catch(() => {});
    if (params.categoria != null) {
      ApplicationApi.get<CategoryResponse>(`categorias/slug/${params.categoria}`)
        .then(({ data }) => {
          setCategory(data);

          ApplicationApi.get<ProductPaginationResponse>(
            `produtos/categoria/${data.id}/paginacao?pagina=${page}&limite=${limit}`,
          )
            .then(({ data }) => {
              setProducts(data);
              setTotalItems(data.totalItems);
              setPages(data.pagesCount);
              setProductsLoading(false);
            })
            .catch(() => {});
        })
        .catch(() => {});
    } else if (params.subcategoria != null) {
      ApplicationApi.get<SubcategoryResponse>(`subcategorias/slug/${params.subcategoria}`)
        .then(({ data }) => {
          setSubcategory(data);

          ApplicationApi.get<ProductPaginationResponse>(
            `produtos/subcategoria/${data.id}/paginacao?pagina=${page}&limite=${limit}`,
          )
            .then(({ data }) => {
              setProducts(data);
              setTotalItems(data.totalItems);
              setPages(data.pagesCount);
              setProductsLoading(false);
            })
            .catch(() => {});
        })
        .catch(() => {});
    } else if (params.marca != null) {
      ApplicationApi.get<BrandResponse>(`marcas/slug/${params.marca}`)
        .then(({ data }) => {
          setBrand(data);

          ApplicationApi.get<ProductPaginationResponse>(
            `produtos/marca/${data.id}/paginacao?pagina=${page}&limite=${limit}`,
          )
            .then(({ data }) => {
              setProducts(data);
              setTotalItems(data.totalItems);
              setPages(data.pagesCount);
              setProductsLoading(false);
            })
            .catch(() => {});
        })
        .catch(() => {});
    } else if (params.busca !== null) {
      ApplicationApi.get<ProductPaginationResponse>(
        `produtos/paginacao?pagina=${page}&limite=${limit}&keyword=${params.busca}`,
      )
        .then(({ data }) => {
          setProducts(data);
          setTotalItems(data.totalItems);
          setPages(data.pagesCount);
          setProductsLoading(false);
        })
        .catch(() => {});
    } else {
      ApplicationApi.get<ProductPaginationResponse>(`produtos`)
        .then(({ data }) => {
          setProducts(data);
          setProductsLoading(false);
        })
        .catch(() => {});
    }
  }, [params, page]);

  useEffect(() => {
    setProductsLoading(true);
    setTotalItems(0);
    setPages(undefined);
    setPage(1);
  }, [params]);

  return (
    <>
      <ProductsContainerFluid>
        <ProductsContainerSidebar>
          <Sidebar margin={false} />
        </ProductsContainerSidebar>
      </ProductsContainerFluid>
      {advertising !== undefined ? (
        <ProductsContainerFluid>
          <ProductsContainer>
            <Advertisement data={advertising} />
          </ProductsContainer>{' '}
        </ProductsContainerFluid>
      ) : (
        <></>
      )}
      <ProductsContainerFluid background={primaryTheme.background.secondary}>
        <ProductsContainer>
          {productsLoading ? <Loading /> : <></>}
          {brand !== undefined ? (
            <ProductContainerTitle>
              Resultados da Marca <b>{brand.name}</b>
            </ProductContainerTitle>
          ) : (
            <>
              {category !== undefined ? (
                <ProductContainerTitle>
                  Resultados da categoria <b>{category.name}</b>
                </ProductContainerTitle>
              ) : (
                <>
                  {subcategory !== undefined ? (
                    <ProductContainerTitle>
                      Resultados da subcategoria <b>{subcategory.name}</b>
                    </ProductContainerTitle>
                  ) : (
                    <>
                      {params.busca !== null ? (
                        <ProductContainerTitle>
                          Resultados para a pesquisa <b>&quot;{params.busca}&quot;</b>
                        </ProductContainerTitle>
                      ) : (
                        <ProductContainerTitle>Exibindo todos os resultados encontrados</ProductContainerTitle>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {products !== undefined && products.data.length > 0 ? (
            <Row>
              {products.data.map((product, i) => (
                <Column key={i} xl={2} lg={3} md={6}>
                  <Product data={product} />
                </Column>
              ))}
            </Row>
          ) : (
            <Alert type={{ ...AlertTypesValue.info, content: 'Nenhum produto encontrado.' }} />
          )}
          {pages !== undefined ? (
            <ProductPaginationContainer>
              {totalItems !== undefined ? (
                <p>
                  Exibindo {limit * page > totalItems ? totalItems : limit * page} de {totalItems} produto(s)
                </p>
              ) : (
                <></>
              )}
              <ProductPaginationBox>
                {new Array(pages).fill(0).map((number, i) => (
                  <ProductPaginationItem
                    key={i}
                    onClick={() => {
                      setPage(i + 1);
                    }}
                    active={page === i + 1}
                  >
                    {i + 1}
                  </ProductPaginationItem>
                ))}
              </ProductPaginationBox>
            </ProductPaginationContainer>
          ) : (
            <></>
          )}
        </ProductsContainer>
      </ProductsContainerFluid>
    </>
  );
};

export default Products;
