import React from 'react';
import { AdvertisingContainer } from '../../styles/pages/includes/advertising';
import { type AdvertisementResponse } from '../../interfaces/advertisements';

interface AdvertisementnProps {
  data: AdvertisementResponse;
}

const Advertisement: React.FC<AdvertisementnProps> = ({ data }) => {
  return (
    <AdvertisingContainer
      onClick={() => {
        window.location.href = data.link;
      }}
    >
      <img src={data.advertisementImages?.uploads.origin} />
    </AdvertisingContainer>
  );
};

export default Advertisement;
