import styled from 'styled-components';

export const FooterContainerFluid = styled.div<{ background?: string }>`
  width: 100%;
  padding: 0 15px;
  background: ${({ background }) => background ?? '#fff'};
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;

  h1 {
    color: ${({ theme }) => theme.background.primary};
    font-size: 1.5rem;
  }

  h2 {
    color: ${({ theme }) => theme.background.primary};
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 35px;
  }

  h4 {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.third};
    font-weight: 300;

    @media (max-width: 1200px) {
      & {
        margin-bottom: 12px;
      }
    }
  }

  h5 {
    color: ${({ theme }) => theme.color.third};
    font-size: 1.5rem;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const LogotipoImage = styled.img`
  width: 80%;
  height: auto;
  margin-bottom: 12px;
`;

export const FooterLabel = styled.p<{ margin?: boolean; bold?: boolean; background?: string; center?: boolean }>`
  color: ${({ theme, background }) => background ?? theme.color.primary};
  margin-bottom: ${({ margin }) => (margin !== undefined && margin ? '18px' : '0')};
  font-weight: ${({ bold }) => (bold !== undefined && bold ? '600' : '400')};
  text-align: ${({ center }) => (center !== undefined && center ? 'center' : 'left')};
`;

export const FooterTitle = styled.h1`
  color: ${({ theme }) => theme.color.primary} !important;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const FooterList = styled.ul`
  list-style: none;

  a {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.primary};
    margin-bottom: 4px;

    svg {
      font-size: 0.3rem;
      margin-right: 15px;
    }
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
  align-items: center;
  padding: 0 24px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 0px;
  }

  img {
    height: auto;
    width: 70px;
  }

  margin-bottom: 15px;
`;

export const FooterImage = styled.img`
  width: 300px;
  height: auto;

  @media (min-width: 1200px) {
    width: 80%;
    height: auto;
  }
`;

export const FooterNewsletterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;

  img {
    width: 350px;
    height: 250px;
    display: none;
  }

  @media (min-width: 768px) {
    img {
      display: block;
    }
  }
`;

export const FooterContainerNewsletter = styled.div`
  margin: 0 auto;
  max-width: 1600px;

  h1 {
    color: ${({ theme }) => theme.background.primary};
    font-size: 1.5rem;
  }

  h2 {
    color: ${({ theme }) => theme.background.primary};
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 35px;
  }

  h4 {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.third};
    font-weight: 300;

    @media (max-width: 1200px) {
      & {
        margin-bottom: 12px;
      }
    }
  }

  h5 {
    color: ${({ theme }) => theme.color.third};
    font-size: 1.5rem;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const FooterNewsletterInput = styled.div`
  @media (max-width: 1200px) {
    margin-bottom: 8px;
  }
`;

export const FooterColumContainer = styled.div`
  @media (max-width: 1200px) {
    margin-bottom: 8px;
  }
`;

export const FooterNewsletterButton = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const FooterSocialContainer = styled.div`
  display: flex;
  margin-top: 12px;

  a {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
    margin-right: 8px;
    border-radius: 4px;
    cursor: pointer;

    svg {
      color: ${({ theme }) => theme.background.primary};
    }
  }
`;
