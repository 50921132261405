import styled from 'styled-components';

export const AlertContainer = styled.div<{ background: string; color: string }>`
  display: flex;
  align-items: center;
  height: 42px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 8px;
  padding: 0 12px;
  font-size: 0.8rem;
  margin-bottom: 8px;

  svg {
    margin-right: 4px;
    font-size: 1rem;
    color: ${({ color }) => color} !important;
    margin-bottom: 0 !important;
  }
`;
