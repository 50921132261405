import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/custom/slick.css';
import React, { useEffect, useState } from 'react';
import { HomeBrandBox, HomeContainer, HomeContainerFluid, HomeHighlightsContainer } from '../styles/pages/home';
import Row from '../components/row';
import Column from '../components/column';
import Product from './includes/highlight';
import ApplicationApi from '../api/config';
import { type BannerResponse } from '../interfaces/banner';
import Placeholder from '../components/placeholder';
import Carousel from '../components/carousel';
import Sidebar from './includes/sidebar';
import { type ProductResponse } from '../interfaces/product';
import Alert, { AlertTypesValue } from '../components/alert';
import { type BrandResponse } from '../interfaces/brand';
import Promotion from './includes/promotion';
import { useNavigate } from 'react-router-dom';
import { type PromotionResponse } from '../interfaces/promotion';
import Slider from 'react-slick';

const Home: React.FC = () => {
  const navigate = useNavigate();

  document.title = `Drogaria Malu :: Home`;

  const [loadingBanners, setLoadingBanners] = useState<boolean>(true);
  const [loadingOffers, setLoadingOffers] = useState<boolean>(true);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);

  const [banners, setBanners] = useState<BannerResponse[]>([]);
  const [brands, setBrands] = useState<BrandResponse[]>([]);
  const [promotions, setPromotions] = useState<PromotionResponse[]>([]);
  const [products, setProducts] = useState<ProductResponse[]>([]);

  const slidePromotionsSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    infinite: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slideBrandsSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    slidesToShow: 10,
    draggable: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 996,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    ApplicationApi.get<BannerResponse[]>('banners/home')
      .then(({ data }) => {
        setBanners(data);
        setLoadingBanners(false);
      })
      .catch(() => {});
    ApplicationApi.get<BrandResponse[]>('marcas')
      .then(({ data }) => {
        setBrands(data);
        setLoadingBanners(false);
      })
      .catch(() => {});
    ApplicationApi.get<PromotionResponse[]>('promocoes')
      .then(({ data }) => {
        setPromotions(data);
        setLoadingOffers(false);
      })
      .catch(() => {});
    ApplicationApi.get<ProductResponse[]>('produtos/vendidos')
      .then(({ data }) => {
        setProducts(data);
        setLoadingProducts(false);
      })
      .catch(() => {});
  }, []);

  /*
  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//code.tidio.co/ggrn9hhoar4s67bmjnuankg2m1iyqteg.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  */

  return (
    <>
      <HomeContainerFluid>
        <HomeContainer>
          <Sidebar />
          {!loadingBanners ? <Carousel data={banners} /> : <Placeholder radius={18} margin={24} height={400} />}
          {!loadingOffers ? (
            <>
              {promotions.length > 0 ? (
                <>
                  <h1>Promoções imperdíveis</h1>
                  <Slider {...slidePromotionsSettings}>
                    {promotions.map((promotion, i) => (
                      <>
                        {promotion.promotionThumbnails !== null || promotion.promotionImages !== null ? (
                          <Promotion data={promotion} />
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </Slider>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Row>
              {new Array(3).fill(0).map((_, i) => (
                <Column key={i} xl={4}>
                  <Placeholder height={200} margin={24} radius={18} />
                </Column>
              ))}
            </Row>
          )}
          <h1>Mais Vendidos!</h1>
          <HomeHighlightsContainer>
            <Row>
              {loadingProducts ? (
                <>
                  {new Array(6).fill(0).map(number => (
                    <Column xl={2} lg={3} md={6} key={number}>
                      <Placeholder height={339} />
                    </Column>
                  ))}
                </>
              ) : (
                <>
                  {products.length > 0 ? (
                    <>
                      {products.map((product, i) => (
                        <Column key={i} xl={2} lg={3} md={6}>
                          <Product data={product} />
                        </Column>
                      ))}
                    </>
                  ) : (
                    <Column xl={12}>
                      <Alert type={{ ...AlertTypesValue.warning, content: 'Nenhum produto encontrado.' }} />
                    </Column>
                  )}
                </>
              )}
            </Row>
          </HomeHighlightsContainer>
        </HomeContainer>
      </HomeContainerFluid>
      {brands.length > 0 ? (
        <HomeContainerFluid>
          <HomeContainer>
            <h1>Marcas amadas do Brasil e do Mundo!</h1>
            <Slider {...slideBrandsSettings}>
              {brands.map((brand, i) => (
                <>
                  {brand.brandImages !== null ? (
                    <HomeBrandBox
                      onClick={() => {
                        navigate(`/marcas/${brand.slug}`);
                      }}
                      key={i}
                    >
                      <img src={brand.brandImages.uploads.origin} />
                    </HomeBrandBox>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </Slider>
          </HomeContainer>
        </HomeContainerFluid>
      ) : (
        <></>
      )}
    </>
  );
};

export default Home;
