import styled from 'styled-components';

export const CustomButtonContainer = styled.div<{ background?: string; margin?: boolean }>`
  margin-bottom: ${({ margin }) => (margin !== undefined && margin ? '12px' : '0')};

  button {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 6px;
    background: ${({ theme, background }) => background ?? theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    font-size: 1rem;
    padding: 8px 12px;
    cursor: pointer;

    svg {
      font-size: 1.2rem;
      margin-right: 8px;
    }
  }
`;
