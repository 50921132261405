import styled from 'styled-components';

export const AccountContainerFluid = styled.div<{ background?: string }>`
  width: 100%;
  padding: 0 15px;
  background: ${({ background, theme }) => background ?? '#fff'};
`;

export const AccountContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  b {
    color: ${({ theme }) => theme.background.primary};
  }

  svg {
    font-size: 4rem;
    color: ${({ theme }) => theme.background.primary};
    margin-right: 8px;
  }
`;

export const AccountNavContainer = styled.ul`
  list-style: none;
  margin-bottom: 12px;

  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

export const AccountNavContainerItem = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  height: 48px;
  background: ${({ active, theme }) => (active !== undefined && active ? theme.background.primary : '#fff')};
  color: ${({ active, theme }) => (active !== undefined && active ? theme.color.primary : theme.color.secondary)};
  font-weight: ${({ active }) => (active !== undefined && active ? '600' : '400')};
  padding: 0 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 0.9rem;
  transition: background 0.1s ease;
  cursor: pointer;

  &:hover {
    background: ${({ active, theme }) => (active !== undefined && active ? theme.background.primary : '#8be0c4')};
  }

  svg {
    font-size: 1.3rem;
    margin-right: 4px;
    color: ${({ active, theme }) => (active !== undefined && active ? '#fff' : theme.background.primary)};
  }
`;

export const AccountDataContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 12px;
  position: relative;
`;

export const AccountCardsContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 12px;
  position: relative;
  margin-bottom: 12px;
`;

export const AccountAddressesContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 12px;
  position: relative;
  margin-bottom: 12px;
`;

export const AccountOrdersContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 12px;
  position: relative;
  margin-bottom: 12px;
`;

export const ActiveContainerTitle = styled.h1`
  font-size: 1rem;
  color: ${({ theme }) => theme.background.primary};
  margin-bottom: 12px;
`;

export const CardContainer = styled.div`
  background: ${({ theme }) => theme.background.secondary};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;

  h1 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.background.primary};
    margin-bottom: 4px;
  }
  h2 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.color.third};
  }

  h3 {
    font-size: 0.9rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const AddressContainer = styled.div`
  background: ${({ theme }) => theme.background.secondary};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;

  h1 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.background.primary};
    margin-bottom: 4px;
  }
  h2 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.color.third};
  }

  h3 {
    font-size: 0.9rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardDeleteContainer = styled.div`
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  background: #ff0000;
  cursor: pointer;

  svg {
    font-size: 0.8rem;
    color: #fff;
  }
`;

export const AddressDeleteContainer = styled.div`
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  background: #ff0000;
  cursor: pointer;

  svg {
    font-size: 0.8rem;
    color: #fff;
  }
`;

export const OrderContainer = styled.div`
  background: ${({ theme }) => theme.background.secondary};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;

  svg {
    color: ${({ theme }) => theme.background.primary};
    cursor: pointer;
  }

  h1 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.background.primary};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const OrderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OrderDetailsContainer = styled.div<{ active: boolean }>`
  padding: 12px;
  background: #fff;
  display: ${({ active }) => (active ? 'block' : 'none')};
  margin-top: 12px;
  border-radius: 8px;
  position: relative;
`;

export const OrderProductContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 18px;
`;

export const OrderProductImageContainer = styled.div`
  border: 1px solid #e1e1e1;
  width: 70px;
  height: 70px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 80%;
    max-height: 80%;
  }

  svg {
    font-size: 2rem;
    color: ${({ theme }) => theme.color.third};
    cursor: auto;
  }
`;

export const OrderProductInfoContainer = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    color: #111;
  }

  h2 {
    color: ${({ theme }) => theme.color.third};
    font-size: 0.8rem;
  }

  h3 {
    font-size: 0.9rem;
  }

  h4 {
    font-size: 0.7rem;
    text-decoration: line-through;
    color: ${({ theme }) => theme.color.third};
  }
`;

export const OrderDetailsTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 0.9rem;
    font-weight: 300;
  }

  h1 {
    font-size: 0.9rem;
    font-weight: 800;
    color: #111;
  }
`;

export const OrderPaymentContainer = styled.div`
  h2 {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.color.third};
  }

  a {
    width: 300px;
    height: 38px;
    background: ${({ theme }) => theme.background.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: 8px;
    font-size: 0.8rem;
  }
`;

export const OrderDeliveryContainer = styled.div`
  h2 {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.color.third};
    display: flex;
    align-items: center;
  }

  svg {
    color: ${({ theme }) => theme.color.third};
    margin-right: 4px;
  }

  a {
    width: 300px;
    height: 38px;
    background: ${({ theme }) => theme.background.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: 8px;
    font-size: 0.8rem;
  }
`;

export const OrderChargebackContainer = styled.div`
  h2 {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.color.third};
    display: flex;
    align-items: center;
  }

  svg {
    color: ${({ theme }) => theme.color.third};
    margin-right: 4px;
  }

  button {
    border: 0;
    width: 350px;
    height: 38px;
    background: ${({ theme }) => theme.background.third};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: 8px;
    font-size: 0.8rem;
    cursor: pointer;
  }
`;

export const OrderChargebackFormContainer = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  textarea {
    background: ${({ theme }) => theme.background.secondary};
    padding: 12px;
    width: 100%;
    border: 0;
    height: 200px;
    border-radius: 6px;
    resize: none;
  }

  textarea:focus-visible {
    outline: 0;
  }

  button {
    border: 0;
    width: 350px;
    height: 38px;
    background: ${({ theme }) => theme.background.third};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    color: ${({ theme }) => theme.color.primary};
    border-radius: 8px;
    font-size: 0.8rem;
    cursor: pointer;
  }
`;

export const AccountButtonContainer = styled.div`
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }

  margin-bottom: 12px;
`;
