import styled from 'styled-components';

export const CheckoutContainerFluid = styled.div<{ background?: string }>`
  width: 100%;
  padding: 0 15px;
  background: ${({ background, theme }) => background ?? '#fff'};
`;

export const CheckoutContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;
  position: relative;
`;

export const CheckoutNoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  h1 {
    margin-bottom: 8px;
    font-size: 1.5rem;
  }

  p {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.color.third};
  }
`;

export const CheckoutSecurityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  color: ${({ theme }) => theme.color.primary};
  font-weight: 600;

  svg {
    margin: 0 18px;
    font-size: 1.3rem;
  }
`;

export const CheckoutCountdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
`;

export const CheckoutCountdownBox = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 15px;
  }
`;

export const CheckoutCountdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 45px;
    height: 45px;
    border-radius: 6px;
    background: #fff;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  p {
    font-size: 0.5rem;
    font-weight: 800;
  }
`;

export const CheckoutCountdownTitle = styled.div`
  display: flex;
  align-items: center;
  color: #78818a;
  margin-left: 18px;

  svg {
    font-size: 1.3rem;
    margin-right: 5px;
  }

  p {
    font-weight: 600;
  }
`;

export const CartBox = styled.div`
  border: 1px solid #e1e1e1;
  padding: 24px;
  border-radius: 12px;
  position: relative;

  h1 {
    font-size: 1.3rem;
    margin-bottom: 18px;
    color: ${({ theme }) => theme.background.primary};
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 12px;
  }

  h3 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.background.primary};
    margin-bottom: 8px;
  }

  h4 {
    font-weight: 800;
    font-size: 1.1rem;
  }

  h5 {
    font-size: 1rem;
    margin-bottom: 4px;
    font-weight: 400;
  }

  form {
    margin-top: 12px;

    p {
      font-weight: 600;
      font-size: 0.8rem;
    }
  }
`;

export const CartItemBox = styled.div`
  margin-bottom: 12px;
  border-bottom: 1px solid #e1e1e1;
  padding: 18px 0;

  h2 {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 4px;
  }

  h3 {
    font-size: 0.7rem;
    font-weight: 600;
    margin-bottom: 4px;
  }

  h4 {
    font-size: 0.9rem;
    font-weight: 600;
  }
`;

export const CartImageBox = styled.div`
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    margin-bottom: 8px;
  }

  img {
    max-width: 80%;
    max-height: 80%;
  }

  svg {
    font-size: 2rem;
    color: ${({ theme }) => theme.color.third};
  }
`;

export const CartLabelContainer = styled.div<{ margin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ margin }) => (margin !== undefined && margin ? '12px' : '4px')};

  h5 {
    margin: 0;
  }
`;

export const CartLabelDiscountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  h5 {
    margin: 0;
    font-weight: 600;
    color: #ff0000;
  }

  h4 {
    font-size: 1rem;
    margin-bottom: 4px;
    font-weight: 400;
    margin: 0;

    span {
      color: ${({ theme }) => theme.color.third};
      font-size: 0.7rem;
    }
  }
`;

export const CartInputContainer = styled.div<{ valid?: boolean }>`
  border: 1px solid ${({ valid }) => (valid !== undefined ? (valid ? '#e1e1e1' : '#ff0000') : '#e1e1e1')};
  border-radius: 8px;
  overflow: hidden;
  margin-top: 8px;
  display: flex;
  padding: 4px;

  button {
    width: 44px;
    border: 0;
    background: ${({ theme }) => theme.background.primary};
    border-radius: 6px;
    color: ${({ theme }) => theme.color.primary};
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input {
    height: 44px;
    border: 0;
    width: 100%;
    outline: 0;
    padding: 0 12px;
  }
`;

export const CheckoutOrderHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: calc(32px + 8px);
  margin-bottom: 18px;

  &:first-child div {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const CartDiscountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #e1e1e1;
  border-radius: 6px;
  margin-top: 12px;

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 0.8rem;

    svg {
      color: #198754;
    }
  }

  h2 {
    color: ${({ theme }) => theme.color.third};
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
  }
`;

export const CheckoutOrderHeaderLabelsContainer = styled.div`
  display: flex;
  width: 600px;
  justify-content: space-between;
`;

export const CheckoutHeaderStatuBox = styled.div<{ active: boolean }>`
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme, active }) => (active ? theme.background.primary : theme.color.third)};
  border-radius: 50%;
  cursor: pointer;

  svg {
    color: ${({ theme, active }) => (active ? theme.background.primary : theme.color.third)};
    font-size: 1.2rem;
  }
`;

export const CheckoutHeaderStatuLabel = styled.p<{ active: boolean }>`
  position: absolute;
  top: calc(100% + 8px);
  font-weight: 600;
  color: ${({ theme, active }) => (active ? theme.background.primary : theme.color.third)};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CheckoutHeaderStatuLine = styled.div<{ active: boolean }>`
  width: 40px;
  height: 2px;
  background: ${({ theme, active }) => (active ? theme.background.primary : theme.color.third)};

  @media (min-width: 768px) {
    width: 150px;
  }
`;

export const CheckoutDeliveryContainer = styled.div`
  position: relative;
  h1 {
    font-size: 1.4rem;
    margin-bottom: 18px;
    font-weight: 600;
  }
`;

export const CheckoutDeliveryButtonContainer = styled.div`
  display: flex;
  margin-bottom: 24px;

  button {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;

    svg {
      margin-left: 4px;
      font-size: 1rem;
    }
  }

  button:last-child {
    margin-left: 12px;
  }
`;

export const CheckoutFastDeliveryContainer = styled.div`
  text-align: center;

  button {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: ${({ theme }) => theme.background.third};
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;

    @media (max-width: 768px) {
      margin-bottom: 8px;
    }

    svg {
      margin-left: 4px;
    }
  }
`;

export const CheckoutDeliveryBox = styled.div<{ active: boolean }>`
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme, active }) => (active ? theme.background.primary : theme.background.secondary)};
  margin-bottom: 24px;
  border-radius: 8px;
  color: ${({ theme, active }) => (active ? theme.color.primary : theme.color.secondary)};
  height: 80px;
  cursor: pointer;

  h1 {
    font-size: 0.8rem;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 0.7rem;
    margin-bottom: 4px;
    font-weight: 400;
  }

  h3 {
    font-size: 0.8rem;
  }
`;

export const CheckoutPaymentContainer = styled.div`
  position: relative;
  h1 {
    font-size: 1.4rem;
    margin-bottom: 18px;
    font-weight: 600;
  }
`;

export const CheckoutDeliveryContent = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  background: ${({ theme }) => theme.background.secondary};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;

  textarea {
    padding: 12px;
    width: 100%;
    height: 200px;
    border: 0;
    border-radius: 8px;
    resize: none;
    outline: none;
  }

  button {
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    border: 0;
    border-radius: 8px;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    margin-bottom: 12px;
    cursor: pointer;

    svg {
      margin-left: 4px;
      font-size: 1.2rem;
    }
  }
`;

export const CheckoutPaymentOptionContainer = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.background.secondary};
  height: 58px;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0 18px;
  cursor: pointer;
  opacity: ${({ active }) => (active ? '1' : '.6')};

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.background.third};
  }
`;

export const CheckoutPaymentOptionContent = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};

  button {
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    border: 0;
    border-radius: 8px;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    margin-bottom: 12px;
    cursor: pointer;

    svg {
      margin-left: 4px;
      font-size: 1.2rem;
    }
  }
`;

export const CheckoutPaymentOptionBox = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${({ theme }) => theme.color.third};
    font-weight: 800;
  }

  svg {
    margin-right: 4px;
    color: ${({ theme }) => theme.background.primary};
  }
`;

export const CheckoutLoginActive = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.third};

  h1 {
    font-weight: 600;
    font-size: 1.4rem;
    color: #111;
  }

  h2 {
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 8px;
  }

  div {
    display: flex;
  }

  button:last-child {
    background: ${({ theme }) => theme.background.primary};
    margin-left: 8px;
  }

  button {
    cursor: pointer;
    padding: 0 12px;
    min-width: 100px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 6px;
    background: ${({ theme }) => theme.background.third};
    color: ${({ theme }) => theme.color.primary};

    svg {
      font-size: 1.2rem;
      margin-left: 4px;
    }
  }
`;

export const UserActiveAddressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  h1 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0;
  }

  button {
    padding: 0 24px;
    height: 48px;
    border-radius: 8px;
    border: 0;
    cursor: pointer;
    font-weight: 600;
    background: ${({ theme }) => theme.background.secondary};
  }
`;

export const UserAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  background: ${({ theme }) => theme.background.secondary};
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;

  h1 {
    font-size: 1rem;
    margin-bottom: 4px;
  }

  h2 {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.color.third};
  }
`;

export const ButtonNextStepContainer = styled.div`
  button {
    cursor: pointer;
    padding: 0 12px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 6px;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};

    svg {
      font-size: 1.2rem;
      margin-left: 4px;
    }
  }
`;

export const UserPaymentItemContainer = styled.div<{ active: boolean }>`
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme, active }) => (active ? theme.background.primary : theme.background.secondary)};
  color: ${({ theme, active }) => (active ? theme.color.primary : theme.color.secondary)};
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 0 12px;
  cursor: pointer;
  position: relative;

  h1 {
    font-size: 0.8rem;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 0.7rem;
    margin-bottom: 4px;
    font-weight: 400;
  }

  h3 {
    font-size: 0.8rem;
  }
`;

export const CheckoutAdminAddressContainer = styled.div`
  margin-bottom: 22px;

  h1 {
    font-height: 600;
  }

  p {
    color: ${({ theme }) => theme.color.third};
    margin-bottom: 4px;
  }

  h2 {
    font-weight: 800;
    font-size: 1rem;
    margin-top: 12px;
  }
`;

export const CheckoutReviewContainer = styled.div`
  position: relative;

  h1 {
    font-size: 1.4rem;
    margin-bottom: 18px;
    font-weight: 600;
  }
`;

export const CheckoutReviewBox = styled.div`
  border: 1px solid #e1e1e1;
  padding: 22px;
  border-radius: 8px;
  margin-bottom: 18px;
`;

export const CheckoutReviewItemsContainer = styled.div`
  padding-bottom: 12px;
`;

export const CheckoutReviewItem = styled.div`
  margin-bottom: 8px;
`;

export const CheckoutReviewImageContainer = styled.div`
  display: flex;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  height: 100px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    margin-bottom: 8px;
  }

  svg {
    font-size: 2rem;
    color: ${({ theme }) => theme.color.third};
  }

  img {
    max-width: 80%;
    max-height: 80%;
  }
`;

export const CheckoutReviewInfoContainer = styled.div`
  h1 {
    font-size: 0.9rem;
    margin: 0;
  }

  h2 {
    font-size: 0.7rem;
    margin: 0;
    font-weight: 300;
    color: ${({ theme }) => theme.color.secondary};
    margin-bottom: 8px;
  }

  h3 {
    font-size: 0.9rem;
    margin: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.color.secondary};
  }
`;

export const CheckoutReviewPaymentContainer = styled.div`
  padding: 12px 0;

  h1 {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.8rem;
  }

  button {
    height: 42px;
    border-radius: 6px;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    border: 0;
    width: 250px;
    margin-top: 12px;
    cursor: pointer;
    font-size: 0.8rem;
  }
`;

export const CheckoutReviewDeliveryContainer = styled.div`
  padding: 12px 0;

  h1 {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.8rem;
  }
`;

export const CheckoutReviewDeliveryButton = styled.div`
  button {
    height: 42px;
    border-radius: 6px;
    background: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.color.primary};
    border: 0;
    width: 250px;
    margin-top: 12px;
    cursor: pointer;
    font-size: 0.8rem;
  }
`;

export const CheckoutInfoContainer = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`;
