import styled from 'styled-components';

export const LoginContainer = styled.div`
  position: relative;
`;

export const LoginBox = styled.div`
  padding: 8px;
  color: #999999;

  p {
    margin-bottom: 12px;
  }

  h1 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  input {
    outline: 0;
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.background.primary};
    background: #eee;
    padding: 0 15px;
    margin-bottom: 8px;
  }
`;

export const PasswordBox = styled.div`
  padding: 8px;
  color: #999999;

  svg {
    color: ${({ theme }) => theme.background.primary};
    margin-bottom: 12px;
    font-size: 1.3rem;
    cursor: pointer;
  }

  p {
    margin-bottom: 12px;
  }

  h1 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  input {
    outline: 0;
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.background.primary};
    background: #eee;
    padding: 0 15px;
    margin-bottom: 12px;
  }
`;

export const LoginSubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;

  p {
    margin: 0;
    font-size: 0.9rem;
    cursor: pointer;
  }

  svg {
    font-size: 1.4rem;
    margin-right: 5px;
    cursor: pointer;
    display: none;
  }
`;
