import React, { useEffect, useState } from 'react';
import {
  ProductBannerContainer,
  ProductBox,
  ProductBoxOff,
  ProductBoxOffContainer,
  ProductPrice,
  ProductSubtitle,
  ProductTitle,
} from '../../styles/pages/includes/highlight';
import CustomButtom from '../../components/button';
import { type ProductResponse } from '../../interfaces/product';
import { useNavigate } from 'react-router-dom';
import Decimal from 'decimal.js';
import { formatMoney } from '../../helpers/formatNumber';
import { LuImageOff } from 'react-icons/lu';

interface ProductProps {
  data: ProductResponse;
}

const Product: React.FC<ProductProps> = ({ data }) => {
  const navigate = useNavigate();

  const [offDiscount, setOffDiscount] = useState<number>(0);

  useEffect(() => {
    if (data.newPrice !== null) {
      const discount = Decimal.div(Decimal.mul(data.newPrice, 100), data.price).toString();

      setOffDiscount(100 - parseInt(discount));
    }
  }, [data]);

  return (
    <ProductBox>
      <ProductBoxOffContainer>
        {data.offerProducts !== undefined && data.offerProducts.length > 0 ? (
          <ProductBoxOff>{data.offerProducts[0].offers.name}</ProductBoxOff>
        ) : (
          <>{offDiscount > 0 ? <ProductBoxOff>{offDiscount}% OFF</ProductBoxOff> : <></>}</>
        )}
      </ProductBoxOffContainer>
      <ProductBannerContainer>
        {data.productImages !== null && data.productImages.length > 0 ? (
          <img src={data.productImages[0].uploads.origin} alt={data.name} />
        ) : (
          <LuImageOff />
        )}
      </ProductBannerContainer>
      <ProductTitle>{data.name.length > 35 ? `${data.name.substring(0, 35)}...` : data.name}</ProductTitle>
      {data.newPrice !== null ? (
        <>
          <ProductSubtitle>R$ {formatMoney(data.price)}</ProductSubtitle>
          <ProductPrice>R$ {formatMoney(data.newPrice)}</ProductPrice>
        </>
      ) : (
        <>
          <ProductSubtitle></ProductSubtitle>
          <ProductPrice>R$ {formatMoney(data.price)}</ProductPrice>
        </>
      )}
      <CustomButtom>
        <button
          onClick={() => {
            navigate(`/produtos/${data.slug}`);
          }}
        >
          APROVEITE AGORA
        </button>
      </CustomButtom>
    </ProductBox>
  );
};

export default Product;
