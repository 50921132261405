import React, { useEffect, useState } from 'react';

import { type PromotionResponse } from '../interfaces/promotion';
import ApplicationApi from '../api/config';
import { useParams } from 'react-router-dom';
import Alert, { type AlertTypes, AlertTypesValue } from '../components/alert';
import Row from '../components/row';
import Column from '../components/column';
import Product from './includes/product';
import { PromotionBannerContainer, PromotionContainer, PromotionContainerFluid } from '../styles/pages/promotion';
import Loading from '../components/loading';
import Decimal from 'decimal.js';

const Promotion: React.FC = () => {
  const { slug } = useParams();

  const [promotion, setPromotion] = useState<PromotionResponse>();

  const [alert, setAlert] = useState<AlertTypes>();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = `Drogaria Malu :: Promoções`;
    ApplicationApi.get<PromotionResponse>(`promocoes/promocao/${slug}`)
      .then(({ data }) => {
        setPromotion(data);
        setLoading(false);

        document.title = `Drogaria Malu :: ${data.name}`;
      })
      .catch(() => {
        setAlert({ ...AlertTypesValue.error, content: 'Nenhuma promoção encontrada.' });
        setLoading(false);
      });
  }, [slug]);

  return (
    <PromotionContainerFluid>
      <PromotionContainer>
        {alert !== undefined ? <Alert type={alert} /> : <></>}
        {loading ? <Loading /> : <></>}
        {promotion !== undefined ? (
          <>
            {promotion.promotionImages !== null ? (
              <PromotionBannerContainer>
                <img src={promotion.promotionImages.uploads.origin} />
              </PromotionBannerContainer>
            ) : (
              <></>
            )}
            <>
              {promotion.promotionProducts.length > 0 ? (
                <Row>
                  {promotion.promotionProducts.map((promotion, i) => (
                    <Column key={i} xl={2}>
                      <Product
                        data={{
                          ...promotion.products,
                          newPrice: promotion.price,
                          price:
                            promotion.products.newPrice === null ||
                            new Decimal(promotion.products.price).greaterThan(
                              new Decimal(promotion.products.newPrice ?? ''),
                            )
                              ? promotion.products.price
                              : promotion.products.newPrice,
                        }}
                      />
                    </Column>
                  ))}
                </Row>
              ) : (
                <Alert type={{ ...AlertTypesValue.info, content: 'Nenhum produto encontrado.' }} />
              )}
            </>
          </>
        ) : (
          <></>
        )}
      </PromotionContainer>
    </PromotionContainerFluid>
  );
};

export default Promotion;
