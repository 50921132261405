import { styled } from 'styled-components';

export const HomeSidebarContainer = styled.div<{ margin?: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.background.primary};
  padding: 14px;
  background: #fff;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ margin }) => (margin !== undefined && !margin ? '0' : '24px')};
  position: relative;
  min-height: 74px;
`;

export const HomeSidebarItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const HomeSidebarManItem = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const HomeSidebarItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  transition: background 0.2s ease;
  cursor: pointer;

  p {
    font-weight: 600;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.background.primary};
  }

  svg {
    font-size: 1.2rem;
    margin-right: 8px;
    color: ${({ theme }) => theme.background.third};
  }

  &:hover {
    background: ${({ theme }) => theme.background.secondary};
  }
`;

export const HomeSidebarSubitem = styled.div`
  font-size: 0.8rem;
  padding: 8px 12px;
  margin-left: 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.third};
  cursor: pointer;

  svg {
    font-size: 1rem;
    margin-right: 8px;
    color: ${({ theme }) => theme.background.primary};
  }
`;

export const HomeSidebarContentContainer = styled.div<{ active: boolean }>`
  position: absolute;
  overflow: hidden;
  min-height: 72px;
  flex-wrap: wrap;
  left: 0;
  top: calc(100% + 8px);
  display: ${({ active }) => (active ? 'flex' : 'none')};
  width: 100%;
  border-radius: 18px;
  background: #fff;
  padding: 14px;
  -webkit-box-shadow: 0px 0px 22px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 22px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 22px -8px rgba(0, 0, 0, 0.75);
  z-index: 8;

  h3 {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.color.third};
  }

  @media (min-width: 768px) {
    flex-wrap: no-wrap;
  }
`;

export const HomeSidebarNoContentLabel = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.third};
`;
