/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import Decimal from 'decimal.js';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { type CouponResponse } from '../interfaces/coupon';
import { type PromotionResponse } from '../interfaces/promotion';
import { type OfferProductsResponse } from '../interfaces/offer';

interface ProductInterface {
  id: number;
  name: string;
  code: string;
  price: string;
  quantity: number;
  specialRecipe: boolean;
  stock?: number;
  image: string | null;
}

interface PromotionInteface {
  id: number;
  name: string;
  price: string;
}

interface OfferInterface {
  id: number;
  name: string;
  description: string;
  expirationDate: number;
  image: string;
  amount: string;
  products: Array<{
    id: number;
    name: string;
    price: string;
    quantity: number;
  }>;
}

interface CartInterface {
  order: {
    subtotal: string;
    deliveryFee?: string;
    discount?: CouponResponse;
    amount: string;
    amountWithoutDiscount?: string;
    products: ProductInterface[];
    promotion?: PromotionInteface;
    offer?: OfferInterface[];
  };
}

interface CartContextInterface {
  cart: CartInterface | null;
  cartActive: boolean;
  setCartActive: React.Dispatch<React.SetStateAction<boolean>>;
  addToCart: (item: ProductInterface) => void;
  directCheckout: (item: ProductInterface) => void;
  removeFromCart: (id: number) => void;
  addDeliveryFee: (price: string) => void;
  addDiscountCoupon: (coupon: CouponResponse) => void;
  addProductQuantity: (id: number) => void;
  removeProductQuantity: (id: number) => void;
  removeDeliveryFee: () => void;
  addPromotion: (promotion: PromotionResponse) => void;
  addOffer: (offer: OfferProductsResponse) => void;
  removeOffer: (id: number) => void;
  clearCart: () => void;
}

interface CartProviderProps {
  children: React.ReactNode;
}

const CartContext = createContext<CartContextInterface>({
  cart: null,
  cartActive: false,
  addToCart: () => { },
  removeFromCart: () => { },
  setCartActive: () => { },
  directCheckout: () => { },
  addDeliveryFee: () => { },
  removeDeliveryFee: () => { },
  addDiscountCoupon: () => { },
  addProductQuantity: () => { },
  removeProductQuantity: () => { },
  addPromotion: () => { },
  addOffer: () => { },
  removeOffer: () => { },
  clearCart: () => { },
});

const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [cartActive, setCartActive] = useState<boolean>(false);
  const [cart, setCart] = useState<CartInterface | null>(cookies.get('@APP::CurrentCart') ?? null);

  const [cartRefresh, setCartRefresh] = useState<number>(1);

  useEffect(() => {
    if (cart !== null) {
      cookies.remove('@APP::CurrentCart');
      cookies.set('@APP::CurrentCart', cart, { expires: new Date(Date.now() + 2 * 60 * 60 * 1000), path: '/' });
    } else {
      cookies.remove('@APP::CurrentCart');
    }
  }, [cart]);

  useEffect(() => {
    if (cart !== null) {
      let amount: string = '0.00';
      let subtotal: string = '0.00';
      let discountWithoutAmount: string | undefined;

      if (cart.order.products.length > 0 || (cart.order.offer !== undefined && cart.order.offer.length > 0)) {
        if (cart.order.promotion !== undefined) {
          amount = cart.order.amount;
          subtotal = cart.order.subtotal;
        } else {
          cart.order.products.map(
            product => (subtotal = Decimal.sum(subtotal, Decimal.mul(product.price, product.quantity)).toFixed(2)),
          );
        }

        if (cart.order.deliveryFee !== undefined) {
          amount = Decimal.sum(subtotal, cart.order.deliveryFee).toFixed(2);
        } else {
          amount = subtotal;
        }

        if (cart.order.discount !== undefined) {
          const percentualPrice = Decimal.sub(
            amount,
            Decimal.div(Decimal.mul(amount, cart.order.discount.discount), 100),
          ).toFixed(2);

          discountWithoutAmount = Decimal.sub(amount, percentualPrice).toFixed(2);
          amount = percentualPrice;
        }

        setCart({
          order: {
            amount,
            products: cart.order.products,
            subtotal,
            deliveryFee: cart.order.deliveryFee,
            offer: cart.order.offer,
            discount: cart.order.discount,
            promotion: cart.order.promotion,
            amountWithoutDiscount: discountWithoutAmount,
          },
        });
      }

      if (cart.order.offer !== undefined) {
        cart.order.offer.forEach(offer => {
          offer.products.forEach(offerProduct => {
            subtotal = Decimal.sum(subtotal, Decimal.mul(offerProduct.price, offerProduct.quantity)).toFixed(2);
            amount = Decimal.sum(amount, Decimal.mul(offerProduct.price, offerProduct.quantity)).toFixed(2);
          });
        });

        setCart({
          order: {
            amount,
            products: cart.order.products,
            subtotal,
            deliveryFee: cart.order.deliveryFee,
            offer: cart.order.offer,
            discount: cart.order.discount,
            promotion: cart.order.promotion,
            amountWithoutDiscount: discountWithoutAmount,
          },
        });
      }
    }
  }, [cartRefresh]);

  const addToCart = (item: ProductInterface): void => {
    if (cart !== null) {
      const alreadyExistsItem = cart.order.products.filter(product => product.id === item.id);

      if (alreadyExistsItem.length > 0) {
        const itemWithQuantity = alreadyExistsItem[0];
        const productsList = cart.order.products.filter(product => product.id !== item.id);
        const nextQuantity = itemWithQuantity.quantity + item.quantity;

        if (item.stock !== undefined) {
          nextQuantity <= item.stock
            ? productsList.push({ ...item, quantity: nextQuantity })
            : productsList.push({ ...item, quantity: item.stock });
        }

        setCart({
          order: {
            subtotal: cart.order.subtotal,
            amount: cart.order.amount,
            products: productsList,
            deliveryFee: cart.order.deliveryFee,
            discount: cart.order.discount,
            amountWithoutDiscount: cart.order.amountWithoutDiscount,
            offer: cart.order.offer,
            promotion: undefined,
          },
        });
      } else {
        if (item.stock !== undefined) {
          if (item.quantity <= item.stock) {
            setCart({
              order: {
                amount: cart.order.amount,
                subtotal: cart.order.subtotal,
                products: [...cart.order.products, item],
                deliveryFee: cart.order.deliveryFee,
                discount: cart.order.discount,
                amountWithoutDiscount: cart.order.amountWithoutDiscount,
                offer: cart.order.offer,
                promotion: undefined,
              },
            });
          } else {
            setCart({
              order: {
                amount: cart.order.amount,
                subtotal: cart.order.subtotal,
                products: [...cart.order.products, { ...item, quantity: item.stock }],
                deliveryFee: cart.order.deliveryFee,
                discount: cart.order.discount,
                amountWithoutDiscount: cart.order.amountWithoutDiscount,
                offer: cart.order.offer,
                promotion: undefined,
              },
            });
          }
        }
      }
    } else {
      if (item.stock !== undefined) {
        if (item.quantity <= item.stock) {
          setCart({
            order: {
              amount: new Decimal(0).toFixed(2),
              subtotal: new Decimal(0).toFixed(2),
              products: [item],
              offer: [],
            },
          });
        } else {
          setCart({
            order: {
              amount: new Decimal(0).toFixed(2),
              subtotal: new Decimal(0).toFixed(2),
              products: [{ ...item, quantity: item.stock }],
              offer: [],
            },
          });
        }
      }
    }

    setCartRefresh(refresh => refresh + 1);
  };

  const removeFromCart = (id: number): void => {
    if (cart !== null) {
      const products = cart.order.products.filter(item => item.id !== id);

      setCart({
        order: {
          amount: cart.order.amount,
          subtotal: cart.order.subtotal,
          products,
          deliveryFee: cart.order.deliveryFee,
          offer: cart.order.offer,
          discount: cart.order.discount,
          amountWithoutDiscount: cart.order.amountWithoutDiscount,
          promotion: undefined,
        },
      });
    } else {
      setCart(null);
    }

    setCartRefresh(refresh => refresh + 1);
  };

  const addProductQuantity = (id: number): void => {
    if (cart !== null) {
      const currentProduct = cart.order.products.find(product => product.id === id);

      if (currentProduct !== undefined) {
        if (currentProduct.stock !== undefined) {
          if (currentProduct.quantity + 1 <= currentProduct.stock) {
            const products = cart.order.products.filter(product =>
              product.id === id ? { ...product, quantity: product.quantity++ } : product,
            );

            setCart({
              order: {
                amount: cart.order.amount,
                products,
                subtotal: cart.order.subtotal,
                deliveryFee: cart.order.deliveryFee,
                discount: cart.order.discount,
                amountWithoutDiscount: cart.order.amountWithoutDiscount,
                offer: cart.order.offer,
                promotion: undefined,
              },
            });
          }
        }
      }
    }

    setCartRefresh(refresh => refresh + 1);
  };

  const removeProductQuantity = (id: number): void => {
    if (cart !== null) {
      const products = cart.order.products.filter(product =>
        product.id === id
          ? {
            id,
            name: product.name,
            code: product.code,
            price: product.price,
            quantity: product.quantity === 1 ? 1 : product.quantity--,
            image: product.image,
          }
          : product,
      );

      setCart({
        order: {
          amount: cart.order.amount,
          products,
          subtotal: cart.order.subtotal,
          deliveryFee: cart.order.deliveryFee,
          discount: cart.order.discount,
          amountWithoutDiscount: cart.order.amountWithoutDiscount,
          offer: cart.order.offer,
          promotion: undefined,
        },
      });
    }

    setCartRefresh(refresh => refresh + 1);
  };

  const clearCart = (): void => {
    setCart(null);
  };

  const directCheckout = (item: ProductInterface): void => {
    setCart({
      order: {
        amount: cart?.order.amount ?? new Decimal(0).toFixed(2),
        subtotal: cart?.order.subtotal ?? new Decimal(0).toFixed(2),
        promotion: undefined,
        products: [item],
      },
    });
    setCartRefresh(refresh => refresh + 1);
    navigate('/checkout');
  };

  const addDeliveryFee = (price: string): void => {
    if (cart !== null) {
      setCart({
        order: {
          products: cart.order.products,
          subtotal: cart.order.subtotal,
          deliveryFee: price,
          discount: cart.order.discount,
          amount: cart.order.amount,
          promotion: cart.order.promotion,
          amountWithoutDiscount: cart.order.amountWithoutDiscount,
          offer: cart.order.offer,
        },
      });
    } else {
      setCart({
        order: {
          products: [],
          subtotal: new Decimal(0).toFixed(2),
          deliveryFee: price,
          discount: undefined,
          amount: new Decimal(0).toFixed(2),
          offer: [],
        },
      });
    }

    setCartRefresh(refresh => refresh + 1);
  };

  const removeDeliveryFee = (): void => {
    if (cart !== null) {
      if (cart.order.deliveryFee !== undefined) {
        setCart({
          order: {
            amount: cart.order.amount,
            products: cart.order.products,
            subtotal: cart.order.subtotal,
            deliveryFee: undefined,
            discount: cart.order.discount,
            amountWithoutDiscount: cart.order.amountWithoutDiscount,
            offer: cart.order.offer,
            promotion: cart.order.promotion,
          },
        });
      }
    }

    setCartRefresh(refresh => refresh + 1);
  };

  const addDiscountCoupon = (coupon: CouponResponse): void => {
    if (cart !== null) {
      setCart({
        order: {
          products: cart.order.products,
          subtotal: cart.order.subtotal,
          deliveryFee: cart.order.deliveryFee,
          discount: coupon,
          amount: cart.order.amount,
          promotion: cart.order.promotion,
          offer: cart.order.offer,
        },
      });
    } else {
      setCart({
        order: {
          products: [],
          subtotal: new Decimal(0).toFixed(2),
          deliveryFee: new Decimal(0).toFixed(2),
          discount: coupon,
          amount: new Decimal(0).toFixed(2),
          offer: [],
        },
      });
    }

    setCartRefresh(refresh => refresh + 1);
  };

  const addPromotion = (promotion: PromotionResponse): void => {
    setCart({
      order: {
        amount: new Decimal(0).toFixed(2),
        products: promotion.promotionProducts.map(offerProducts => ({
          code: offerProducts.products.code,
          id: offerProducts.products.id,
          image:
            offerProducts.products.productImages !== null
              ? offerProducts.products.productImages[0].uploads.origin
              : null,
          name: offerProducts.products.name,
          price: '0.00',
          specialRecipe: false,
          quantity: 1,
        })),
        subtotal: new Decimal(0).toFixed(2),
        deliveryFee: cart?.order.deliveryFee,
        discount: cart?.order.discount,
        promotion: { id: promotion.id, name: promotion.name, price: new Decimal(0).toFixed(2) },
        amountWithoutDiscount: cart?.order.amountWithoutDiscount,
      },
    });

    setCartRefresh(refresh => refresh + 1);
  };

  const addOffer = (offer: OfferProductsResponse): void => {
    let amount: string = '0.00';

    offer.offers.offerProducts.map(
      product => (amount = Decimal.sum(amount, Decimal.mul(product.price, product.quantity)).toFixed(2)),
    );

    if (cart !== null) {
      const cartWithoutOffer = cart.order.offer?.filter(off => off.id !== offer.id) ?? [];

      setCart({
        order: {
          products: cart.order.products,
          subtotal: cart.order.subtotal,
          deliveryFee: cart.order.deliveryFee,
          offer: [
            ...cartWithoutOffer,
            {
              id: offer.offerId,
              description: offer.offers.description ?? '',
              name: offer.offers.name,
              expirationDate: new Date(offer.offers.expirationDate).getTime(),
              amount,
              image:
                offer.offers.offerProducts[0].products.productImages !== null
                  ? offer.offers.offerProducts[0].products.productImages[0].uploads.origin
                  : '',
              products: offer.offers.offerProducts.map(product => ({
                id: product.productId,
                name: product.products.name,
                price: product.price,
                quantity: product.quantity,
              })),
            },
          ],
          amount,
          promotion: cart.order.promotion,
        },
      });
    } else {
      setCart({
        order: {
          products: [],
          subtotal: new Decimal(0).toFixed(2),
          offer: [
            {
              id: offer.offerId,
              description: offer.offers.description ?? '',
              name: offer.offers.name,
              expirationDate: new Date(offer.offers.expirationDate).getTime(),
              amount,
              image:
                offer.offers.offerProducts[0].products.productImages !== null
                  ? offer.offers.offerProducts[0].products.productImages[0].uploads.origin
                  : '',
              products: offer.offers.offerProducts.map(product => ({
                id: product.productId,
                name: product.products.name,
                price: product.price,
                quantity: product.quantity,
              })),
            },
          ],
          amount,
        },
      });
    }

    setCartRefresh(refresh => refresh + 1);
  };

  const removeOffer = (offerId: number): void => {
    const offersWithoutOffer = cart?.order.offer?.filter(offer => offer.id !== offerId) ?? [];

    if (cart !== null) {
      setCart({
        order: {
          products: cart.order.products,
          subtotal: cart.order.subtotal,
          deliveryFee: cart.order.deliveryFee,
          discount: cart.order.discount,
          amount: cart.order.amount,
          promotion: cart.order.promotion,
          offer: offersWithoutOffer,
        },
      });
    } else {
      setCart({
        order: {
          products: [],
          subtotal: new Decimal(0).toFixed(2),
          deliveryFee: new Decimal(0).toFixed(2),
          discount: undefined,
          amount: new Decimal(0).toFixed(2),
          offer: offersWithoutOffer,
        },
      });
    }

    setCartRefresh(refresh => refresh + 1);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartActive,
        setCartActive,
        directCheckout,
        addProductQuantity,
        removeProductQuantity,
        addToCart,
        addDeliveryFee,
        addDiscountCoupon,
        removeDeliveryFee,
        clearCart,
        addPromotion,
        removeFromCart,
        addOffer,
        removeOffer,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextInterface => useContext(CartContext);

export default CartProvider;
