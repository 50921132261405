import styled from 'styled-components';

export const RegisterContainerFluid = styled.div`
  width: 100%;
  padding: 0 15px;
  background: #fff;
`;

export const HighlightContainerFluid = styled.div`
  width: 100%;
  padding: 0 15px;
  background: ${({ theme }) => theme.background.secondary};
`;

export const HighlightContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;
  position: relative;
`;

export const RegisterContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;

  h1 {
    font-weight: 400;
    color: ${({ theme }) => theme.background.primary};
    font-size: 1.5rem;
    margin-bottom: 18px;
  }

  h3 {
    font-size: 0.9rem;
    color: #6f6e77;
  }

  form {
    position: relative;
  }
`;

export const PermissionBox = styled.div`
  display: flex;
  height: 58px;
  padding: 0 22px;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 8px;

  h2 {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.background.third};
  }

  p {
    font-size: 0.8rem;
    color: #6f6e77;
    font-weight: 600;
  }

  svg {
    color: #6f6e77;
    cursor: pointer;
    font-size: 1.3rem;
  }
`;

export const PermissionContent = styled.div`
  position: relative;
  width: 100%;
  padding: 22px;
  font-size: 0.9rem;
  background: ${({ theme }) => theme.background.secondary};
  margin-top: 12px;
  border-radius: 8px;
  min-height: 150px;
  overflow: hidden;

  h3 {
    margin-bottom: 12px;
  }
`;

export const PermissionContainer = styled.div`
  padding: 24px 0;
`;
