import styled from 'styled-components';

export const CarouselImage = styled.div`
  position: relative;
  margin-bottom: 24px;
  border-radius: 18px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
`;
