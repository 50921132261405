import React, { type ReactElement } from 'react';
import { ColumnContainer } from '../styles/components/column';

interface ColumnProps {
  children: ReactElement | ReactElement[] | string | null;
  xl?: number;
  lg?: number;
  md?: number;
  sm?: number;
  center?: boolean;
}

const Column: React.FC<ColumnProps> = ({ children, xl, lg, md, sm, center }) => {
  return (
    <ColumnContainer center={center} xl={xl} lg={lg} md={md} sm={sm}>
      {children}
    </ColumnContainer>
  );
};

export default Column;
