import React, { createContext, useContext, useEffect, useState } from 'react';
import ApplicationApi from '../api/config';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { type UserFavoriteProductsResponse } from '../interfaces/user';

interface UserProps {
  id: number;
  name: string;
  email: string;
  image?: string | null;
}

interface AuthContextProps {
  user: UserProps | null;
  favoriteProducts: UserFavoriteProductsResponse[];
  token: string | null;
  loginFancybox: boolean;
  setFavoriteProducts: React.Dispatch<React.SetStateAction<UserFavoriteProductsResponse[]>>;
  setLoginFancyboxActive: React.Dispatch<React.SetStateAction<boolean>>;
  logIn: (email: string, password: string) => Promise<void>;
  logOut: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  token: null,
  loginFancybox: false,
  favoriteProducts: [],
  setFavoriteProducts: () => {},
  setLoginFancyboxActive: () => {},
  logIn: async () => {},
  logOut: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

interface LoginResponse {
  jwt: string;
  expiresIn: string;
}

interface JwtResponse {
  id: number;
  document: string;
  status: number;
  login: string;
  name: string;
  role: string;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const cookies = new Cookies();

  const [user, setUser] = useState<UserProps | null>(null);
  const [favoriteProducts, setFavoriteProducts] = useState<UserFavoriteProductsResponse[]>([]);
  const [loginFancybox, setLoginFancyboxActive] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(cookies.get('@USER::TOKEN') ?? null);

  if (token !== null) {
    if (user === null) {
      const { id, name, login } = jwtDecode(token) as JwtResponse;

      setToken(token);
      setUser({ email: login, id, name });
    }
  }

  useEffect(() => {
    if (user !== null) {
      ApplicationApi.get<UserFavoriteProductsResponse[]>(`usuarios/${user.id}/favoritos`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(({ data }) => {
          setFavoriteProducts(data);
        })
        .catch(() => {});
    }
  }, [token]);

  const logIn = async (email: string, password: string): Promise<void> => {
    const { data } = await ApplicationApi.post<LoginResponse>('usuarios/login', { email, password });
    const { id, name } = jwtDecode(data.jwt) as JwtResponse;
    const date = new Date();

    date.setTime(+date + 2 * 3600000);

    setToken(data.jwt);
    setUser({ email, id, name });

    cookies.set('@USER::TOKEN', data.jwt, { expires: date });
  };

  const logOut = (): void => {
    cookies.remove('@USER::TOKEN');

    setUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        loginFancybox,
        favoriteProducts,
        setFavoriteProducts,
        setLoginFancyboxActive,
        logIn,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => useContext(AuthContext);

export default AuthProvider;
