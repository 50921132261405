import React, { type ReactElement } from 'react';
import { AlertContainer } from '../styles/components/alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

export interface AlertTypes {
  icon: JSX.Element;
  background: string;
  color: string;
  content: string | ReactElement;
}

export const AlertTypesValue = {
  warning: { icon: <AiOutlineExclamationCircle />, background: '#fff3cd', color: '#856404' },
  error: { icon: <AiOutlineExclamationCircle />, background: '#f8d7da', color: '#721c24' },
  success: { icon: <AiOutlineExclamationCircle />, background: '#d4edda', color: '#155724' },
  info: { icon: <AiOutlineExclamationCircle />, background: '#d1ecf1', color: '#165b67' },
};

interface AlertProps {
  type: AlertTypes;
}

const Alert: React.FC<AlertProps> = ({ type }) => {
  return (
    <AlertContainer background={type.background} color={type.color}>
      {type.icon}
      {type.content}
    </AlertContainer>
  );
};

export default Alert;
