import styled from 'styled-components';

export const ProductContainer = styled.div`
  display: block;
  border-radius: 12px;
  background: #fff;
  padding: 12px;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;

  h1 {
    font-weight: 800 !important;
    font-size: 1.2rem !important;
    margin-bottom: 8px !important;
    color: ${({ theme }) => theme.color.secondary} !important;
  }
`;

export const ProductDiscountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 1.5rem;
    margin-left: 8px;
    color: #ff0000;
    cursor: pointer;
  }
`;

export const ProductDiscountBox = styled.div`
  padding: 1px 22px;
  color: #187da9;
  background: #f4e93f;
`;

export const ProductOfferBox = styled.div`
  width: 100%;
  height: 21px;
  text-transform: uppercase;
  color: #eee;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #187da9;
  font-size: 0.8rem;
`;

export const ProductBannerContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 90%;
  }

  svg {
    font-size: 4rem;
    color: ${({ theme }) => theme.color.third};
  }
`;

export const ProductTitleContainer = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  h1 {
    font-weight: 800 !important;
    font-size: 0.7rem !important;
    margin-bottom: 3px !important;
    color: ${({ theme }) => theme.color.secondary} !important;
  }

  h2 {
    font-size: 0.6rem !important;
    font-weight: 400 !important;
    text-decoration: line-through !important;
    color: ${({ theme }) => theme.color.third} !important;
  }
`;

export const ProductQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 800 !important;
    font-size: 0.9rem;
  }

  button {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.third};
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: #1482ae;
    }
  }
`;

export const ProductButton = styled.div`
  button {
    width: 100%;
    height: 32px;
    background: #1482ae;
    border: 0;
    color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.7rem;

    svg {
      font-size: 1rem;
      margin-left: 5px;
    }
  }
`;
