export const formatDateToUS = (data: string, time?: boolean): string => {
  if (time !== undefined && time) {
    const date = data.split(' ');

    const day = date[0].split('/')[0];
    const month = date[0].split('/')[1];
    const year = date[0].split('/')[2];

    const hour = date[1] !== undefined ? date[1].split(':')[0] : '';
    const minute = date[1] !== undefined ? date[1].split(':')[1] : '';

    return (
      year +
      '-' +
      ('0' + month).slice(-2) +
      '-' +
      ('0' + day).slice(-2) +
      ' ' +
      ('0' + hour).slice(-2) +
      ':' +
      ('0' + minute).slice(-2)
    );
  } else {
    const day = data.split('/')[0];
    const month = data.split('/')[1];
    const year = data.split('/')[2];

    return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
  }
};
