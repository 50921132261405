import React, { type ReactNode } from 'react';
import { CustomTextAreaContainer, CustomTextAreaLabel } from '../styles/components/textarea';

interface CustomTextAreaProps {
  children: ReactNode;
  label?: string;
  margin?: boolean;
}

export default function CustomTextArea({ children, label, margin }: CustomTextAreaProps): ReactNode {
  return (
    <>
      {label !== undefined ? <CustomTextAreaLabel>{label}</CustomTextAreaLabel> : <></>}
      <CustomTextAreaContainer margin={margin}>{children}</CustomTextAreaContainer>
    </>
  );
}
