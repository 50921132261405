import React, { type SyntheticEvent, useState } from 'react';
import {
  CardsContainer,
  FooterColumContainer,
  FooterContainer,
  FooterContainerFluid,
  FooterContainerNewsletter,
  FooterImage,
  FooterLabel,
  FooterList,
  FooterNewsletterButton,
  FooterNewsletterContainer,
  FooterNewsletterInput,
  FooterSocialContainer,
  FooterTitle,
  LogotipoImage,
} from '../../styles/pages/includes/footer';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { primaryTheme } from '../../styles/theme';
import { Link } from 'react-router-dom';
import { FaCircle, FaFacebookF, FaInstagram } from 'react-icons/fa6';
import { type NewsletterBody } from '../../interfaces/newsletter';
import InputMask from 'react-input-mask';
import Alert, { AlertTypesValue, type AlertTypes } from '../../components/alert';
import ApplicationApi from '../../api/config';
import Loading from '../../components/loading';
import { useAdmin } from '../../hooks/useAdmin';

const Footer: React.FC = () => {
  const admin = useAdmin();

  const [newsletterBody, setNewsletterBody] = useState<NewsletterBody>({ name: '', email: '', phone: '' });

  const [newsletterLoading, setNewsletterLoading] = useState<boolean>(false);

  const [newsletterAlert, setNewsletterAlert] = useState<AlertTypes>();

  const submitNewsletter = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setNewsletterLoading(true);
    try {
      await ApplicationApi.post('newsletter', newsletterBody);

      setNewsletterAlert({ ...AlertTypesValue.success, content: 'Cliente cadastrado com sucesso' });
    } catch {
      setNewsletterAlert({ ...AlertTypesValue.warning, content: 'Cliente já cadastrado em nossa newsletter' });
    }
    setNewsletterLoading(false);
  };

  return (
    <>
      <FooterContainerFluid>
        <FooterContainerNewsletter>
          <Row>
            <Column xl={6} lg={7} center>
              <FooterNewsletterContainer>
                <div>
                  <h1>Cadastre-se</h1>
                  <h4>
                    para receber as melhores ofertas
                    <br /> e super descontos!
                  </h4>
                </div>
                <img src="/images/newsletter.png" />
              </FooterNewsletterContainer>
            </Column>
            <Column xl={6} lg={5} center>
              {newsletterLoading ? <Loading /> : <></>}
              {newsletterAlert !== undefined ? (
                <Alert type={newsletterAlert} />
              ) : (
                <>
                  <form onSubmit={submitNewsletter}>
                    <Row>
                      <Column xl={3}>
                        <FooterNewsletterInput>
                          <CustomInput border>
                            <input
                              onChange={e => {
                                setNewsletterBody({ ...newsletterBody, name: e.target.value });
                              }}
                              placeholder="Nome"
                            />
                          </CustomInput>
                        </FooterNewsletterInput>
                      </Column>
                      <Column xl={3}>
                        <FooterNewsletterInput>
                          <CustomInput border>
                            <InputMask
                              placeholder="Telefone"
                              mask="(99) 99999-9999"
                              onChange={e => {
                                setNewsletterBody({ ...newsletterBody, phone: e.target.value });
                              }}
                              required
                            />
                          </CustomInput>
                        </FooterNewsletterInput>
                      </Column>
                      <Column xl={3}>
                        <FooterNewsletterInput>
                          <CustomInput border>
                            <input
                              type="email"
                              placeholder="E-mail"
                              onChange={e => {
                                setNewsletterBody({ ...newsletterBody, email: e.target.value });
                              }}
                              required
                            />
                          </CustomInput>
                        </FooterNewsletterInput>
                      </Column>
                      <Column xl={3}>
                        <FooterNewsletterButton>
                          <CustomButtom>
                            <button type="submit">Cadastrar</button>
                          </CustomButtom>
                        </FooterNewsletterButton>
                      </Column>
                    </Row>
                  </form>
                </>
              )}
            </Column>
          </Row>
        </FooterContainerNewsletter>
      </FooterContainerFluid>
      <FooterContainerFluid background={primaryTheme.background.primary}>
        <FooterContainer>
          <Row>
            <Column xl={3} lg={4}>
              <FooterColumContainer>
                <LogotipoImage src="/images/logotipo.png" alt="Logotipo" />
                <FooterLabel>
                  {admin.address}, {admin.number}
                </FooterLabel>
                <FooterLabel>
                  {admin.city} - {admin.state}
                </FooterLabel>
                <FooterLabel>CEP: {admin.zipCode}</FooterLabel>
              </FooterColumContainer>
            </Column>
            <Column xl={3}>
              <FooterColumContainer>
                <FooterTitle>Institucional</FooterTitle>
                <FooterList>
                  <Link to="/pagina/quem-somos  ">
                    <FaCircle /> A Droga Malu
                  </Link>
                  <Link to="/trabalhe-conosco">
                    <FaCircle /> Trabalhe Conosco
                  </Link>
                  <Link to="/ouvidoria">
                    <FaCircle /> Ouvidoria
                  </Link>
                </FooterList>
              </FooterColumContainer>
            </Column>
            <Column xl={3}>
              <FooterColumContainer>
                <FooterTitle>Central de Ajuda</FooterTitle>
                <FooterList>
                  <Link to="/pagina/como-comprar">
                    <FaCircle /> Como Comprar
                  </Link>
                  <Link to="/pagina/trocas-e-devolucoes">
                    <FaCircle /> Trocas e Devoluções/Cancelamento
                  </Link>
                  <Link to="/pagina/formas-de-pagamento">
                    <FaCircle /> Formas de Pagamento
                  </Link>
                  <Link to="/pagina/retire-na-loja">
                    <FaCircle /> Retire na Loja
                  </Link>
                </FooterList>
              </FooterColumContainer>
            </Column>
            <Column xl={3}>
              <FooterColumContainer>
                <FooterTitle>Privacidade</FooterTitle>
                <FooterList>
                  <Link to="/pagina/politica-de-privacidade">
                    <FaCircle /> Política de Privacidade
                  </Link>
                  <Link to="/pagina/como-protegemos-os-seus-dados">
                    <FaCircle /> Como Protegemos os seus dados
                  </Link>
                </FooterList>
              </FooterColumContainer>
            </Column>
            <Column xl={3}>
              <FooterLabel margin>
                {admin.phone} | {admin.cellphone}
              </FooterLabel>
              <FooterLabel>{admin.email}</FooterLabel>
              <FooterColumContainer>
                <FooterSocialContainer>
                  <a target="new" href={admin.facebook ?? ''}>
                    <FaFacebookF />
                  </a>
                  <a target="new" href={admin.instagram ?? ''}>
                    <FaInstagram />
                  </a>
                </FooterSocialContainer>
              </FooterColumContainer>
            </Column>
            <Column xl={3}>
              <FooterLabel bold margin>
                Farmacêutico Responsável
              </FooterLabel>
              <FooterLabel>{admin.name}</FooterLabel>
              <FooterLabel>CRF: {admin.crf}</FooterLabel>
            </Column>
          </Row>
        </FooterContainer>
      </FooterContainerFluid>
      <FooterContainerFluid background={primaryTheme.background.secondary}>
        <FooterContainer>
          <Row>
            <Column xl={5}>
              <h2>Formas de Pagamento</h2>
              <CardsContainer>
                <img src="/images/cards/visa.png" alt="VISA" />
                <img src="/images/cards/mastercard.png" alt="Mastercard" />
                <img src="/images/cards/hipercard.png" alt="Hipercard" />
                <img src="/images/cards/express.png" alt="Express" />
                <img src="/images/cards/diners.png" alt="Diners" />
                <img src="/images/cards/aura.png" alt="Aura" />
              </CardsContainer>
            </Column>
            <Column xl={2} center>
              <FooterColumContainer>
                <FooterLabel margin background={primaryTheme.background.primary}>
                  &copy; Copyright Droga Malu
                </FooterLabel>
                <FooterLabel background={primaryTheme.color.third}>AFE 7.772.451</FooterLabel>
                <FooterLabel background={primaryTheme.color.third}>CNPJ: 39.767.997/0001-45</FooterLabel>
              </FooterColumContainer>
            </Column>
            <Column xl={3} center>
              <FooterColumContainer>
                <FooterImage src="/images/anvisa.png" alt="Anvisa" />
              </FooterColumContainer>
            </Column>
            <Column xl={2} center>
              <FooterColumContainer>
                <p>Agência Pales</p>
              </FooterColumContainer>
            </Column>
          </Row>
          <FooterLabel center background={primaryTheme.background.primary}>
            As informações contidas neste site não devem ser usadas para automedicação e não substituem , em hipótese
            alguma, as orientações dadas pelo profissional da área médica. Somente o médico está apto a diagnosticar
            qualquer problema de saúde e prescrever o tratamento adequado.
          </FooterLabel>
        </FooterContainer>
      </FooterContainerFluid>
    </>
  );
};

export default Footer;
