import { styled } from 'styled-components';

export const HomeContainerFluid = styled.div<{ background?: string }>`
  width: 100%;
  padding: 0 15px;
  background: ${({ background, theme }) => background ?? theme.background.secondary};
`;

export const HomeContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 0;

  h1 {
    color: ${({ theme }) => theme.background.primary};
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
`;

export const HomePromotionContainer = styled.div`
  width: 100%;
  height: 200px;
  background: ${({ theme }) => theme.background.primary};
  border-radius: 18px;
  margin-bottom: 24px;
`;

export const HomeHighlightsContainer = styled.div`
  margin-bottom: 8px;
`;

export const HomeBrandContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 1440px) {
    justify-content: flex-start;
  }
`;

export const HomeBrandBox = styled.div`
  width: 100%;
  height: 139px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    max-width: 60%;
    max-height: 60%;
  }

  svg {
    font-size: 2rem;
    color: ${({ theme }) => theme.color.third};
  }
`;

export const HomeWhatsAppContainer = styled.a`
  position: fixed;
  inset: auto 25px 120px auto;
  width: 62px;
  height: 62px;
  background: #25d366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 1px 2px 24px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 2px 24px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 2px 24px -7px rgba(0, 0, 0, 0.75);
  z-index: 7;

  svg {
    font-size: 2rem;
    color: #eee;
  }
`;

export const HomePaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${({ theme }) => theme.color.third};
    font-wieght: 0.8rem;
  }
`;

export const HomePaginationBox = styled.div`
  display: flex;
  align-items: center;
`;

export const HomePaginationItem = styled.div<{ active: boolean }>`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active, theme }) => (active ? theme.color.primary : theme.color.secondary)};
  cursor: pointer;
  border-radius: 50%;
  background: ${({ active, theme }) => (active ? theme.background.primary : '#fff')};
  font-size: 0.7rem;
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
`;
