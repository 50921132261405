import axios from 'axios';

const apiUrl = 'https://api.drogamalu.com.br';
// const apiUrl = 'http://localhost:4000';
const publicKey =
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApFnOG6cmvBjTCPy3xr+cAMxTWhxUbZ1W2qGgXNeimbg0thCvJ8Iuocqlfan4Mbs0/e10FEqZEq8eZza/wV7xud+dY8V05lUFCyjd2+/VAqDSnViGz6+7NgbsU83RPcZOtj3Cfzwr3/gWI0trcEA6cimv7VlROUB4tz6y8Tza2Cc6ev/LII3UD4fgVdHyF+PgzbJSrjiqQNrTW+lmkAVlmKE+/4/An4jwESD6kV7MyucPaFv+8sRrpDMkB8wZXbI4VXIM7dbFC4pIABI55rYV+PffNcxYIZrnA3aOWmnTuEs0tVXk7MH5s8PMj21vu1bdHKe6Lqy1RAs9rBvWsBKV8wIDAQAB';

const ApplicationApi = axios.create({
  baseURL: apiUrl,
});

export { apiUrl, publicKey };

export default ApplicationApi;
