export const primaryTheme = {
  background: {
    primary: '#39ac85',
    secondary: '#f5f5f5',
    third: '#da026a',
  },
  color: {
    primary: '#eee',
    secondary: '#111',
    third: '#a8a8a8',
  },
};
