/* eslint-disable prettier/prettier */
import React, { type SyntheticEvent, useEffect, useState } from 'react';
import {
  DeliveryBox,
  ProductBrandBox,
  ProductBrandContainer,
  ProductButtonContainer,
  ProductCartContainer,
  ProductCheckoutButtonContainer,
  ProductCheckoutContainer,
  ProductCountDownClockContainer,
  ProductCoutdownBox,
  ProductCoutdownContainer,
  ProductDeliveryInputContainer,
  ProductDeliveryTitleContainer,
  ProductDescriptionBox,
  ProductDescriptionContainer,
  ProductDiscountContainer,
  ProductDiscountContainerActive,
  ProductEspecificationBox,
  ProductEspecificationContainer,
  ProductHighlightsContainer,
  ProductImagesBox,
  ProductImagesContainer,
  ProductInfoContainer,
  ProductMainImageBox,
  ProductNotFound,
  ProductOfferContainer,
  ProductPriceContainer,
  ProductSlugTitle,
  ProductsAdvertisingContainer,
  ProductsContainer,
  ProductsContainerFluid,
  ProductsInfoContainer,
} from '../../styles/pages/product';
import { primaryTheme } from '../../styles/theme';
import Row from '../../components/row';
import Column from '../../components/column';
import { FaArrowRight, FaBagShopping, FaCartShopping, FaCheck, FaChevronRight, FaMinus, FaPlus } from 'react-icons/fa6';
import Advertising from '../includes/advertising';
import { PiDownloadLight } from 'react-icons/pi';
import ApplicationApi from '../../api/config';
import { type ProductResponse } from '../../interfaces/product';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdErrorOutline, MdOutlineDeliveryDining } from 'react-icons/md';
import { BiSearchAlt2 } from 'react-icons/bi';
import Placeholder from '../../components/placeholder';
import { LuImageOff } from 'react-icons/lu';
import { formatMoney } from '../../helpers/formatNumber';
import Decimal from 'decimal.js';
import Loading from '../../components/loading';
import InputMask from 'react-input-mask';
import { type DeliveryResponse } from '../../interfaces/delivery';
import { useCart } from '../../hooks/useCart';
import { type CouponResponse } from '../../interfaces/coupon';
import { type FreightResponse } from '../../interfaces/freight';
import { useAdmin } from '../../hooks/useAdmin';
import Recomendation from '../includes/product';
import { type AdvertisementResponse } from '../../interfaces/advertisements';
import { ProductRecipeTypes } from '../../enums/product';
import { useQuery } from '../register';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { RiShoppingBasket2Line } from 'react-icons/ri';
import Countdown from 'react-countdown';

const Product: React.FC = () => {
  const query = useQuery();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { cart, addToCart, setCartActive, addOffer, directCheckout, addDiscountCoupon } = useCart();
  const admin = useAdmin();

  const [loading, setLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<ProductResponse>();
  const [deliveries, setDeliveries] = useState<DeliveryResponse>();
  const [freights, setFreights] = useState<FreightResponse[]>();

  const [zipCode, setZipCode] = useState<string>();
  const [couponCode, setCouponCode] = useState<string>();

  const [productCartLoading, setProductCartLoading] = useState<boolean>(false);

  const [productActiveImage, setProductActiveImage] = useState<{ id: number }>();
  const [cartQuantity, setCartQuantity] = useState<number>(1);
  const [validCoupon, setValidCoupon] = useState<boolean>();

  const [productsRecomendations, setProductsRecomendations] = useState<ProductResponse[]>([]);
  const [advertising, setAdvertising] = useState<AdvertisementResponse>();

  useEffect(() => {
    document.title = `Drogaria Malu :: Produto`;

    setLoading(true);
    setProduct(undefined);

    const token = query.get('token');

    ApplicationApi.get<ProductResponse>(
      token !== null ? `produtos/produto/${slug}/admin` : `produtos/produto/${slug}`,
      { headers: { 'api-token': query.get('token') } },
    )
      .then(({ data }) => {
        setProduct({
          ...data,
          newPrice: data.promotionProducts.length > 0 ? data.promotionProducts[0].price : data.newPrice,
        });
        setLoading(false);

        ApplicationApi.patch(`produtos/${data.id}/visitas`)
          .then(() => { })
          .catch(() => { });

        document.title = `Drogaria Malu :: ${data.name}`;
      })
      .catch(() => { });
    ApplicationApi.get<AdvertisementResponse>(`anuncios/buscar`)
      .then(({ data }) => {
        setAdvertising(data);
      })
      .catch(() => { });
  }, [slug]);

  useEffect(() => {
    if (product?.categories !== null && product !== undefined) {
      ApplicationApi.get<ProductResponse[]>(`produtos/categoria/${product.categories.id}`)
        .then(({ data }) => {
          setProductsRecomendations(data.filter(item => item.id !== product.id));
        })
        .catch(() => { });
    } else if (product?.brands !== null && product !== undefined) {
      ApplicationApi.get<ProductResponse[]>(`produtos/marca/${product.brands.id}`)
        .then(({ data }) => {
          setProductsRecomendations(data.filter(item => item.id !== product.id));
        })
        .catch(() => { });
    }
  }, [product]);

  const searchDeliveriesByCEP = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setProductCartLoading(true);

    try {
      const deliveries = await ApplicationApi.get<DeliveryResponse>(`entregas/cep/${zipCode}`);

      setDeliveries(deliveries.data);
    } catch { }

    try {
      const freights = await ApplicationApi.post<FreightResponse[]>(`fretes/calcular`, {
        from: admin.zipCode,
        to: zipCode,
      });

      setFreights(freights.data);
    } catch { }

    setProductCartLoading(false);
  };

  const validateCoupon = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setProductCartLoading(true);

    try {
      const { data } = await ApplicationApi.get<CouponResponse>(`cupons/validacao/${couponCode}`);

      addDiscountCoupon(data);
    } catch {
      setValidCoupon(false);
      setCouponCode('');
    }

    setProductCartLoading(false);
  };

  return (
    <>
      {product?.offerProducts !== undefined && product.offerProducts.length > 0 ? (
        <ProductCoutdownContainer>
          <ProductCoutdownBox>
            <div>
              <h1>Ótima hora para comprar</h1>
              <h2>Aproveite! Oferta por tempo limitado</h2>
            </div>
            <ProductCountDownClockContainer>
              <AiOutlineClockCircle />
              <div>
                <p>Termina em:</p>
                <h2>
                  <Countdown date={product.offerProducts[0].offers.expirationDate} daysInHours={false} />
                </h2>
              </div>
            </ProductCountDownClockContainer>
            <button
              onClick={() => {
                addOffer(product.offerProducts[0]);
                setCartActive(true);
              }}
            >
              <p>{product.offerProducts[0].offers.description}</p>
              <RiShoppingBasket2Line />
            </button>
          </ProductCoutdownBox>
        </ProductCoutdownContainer>
      ) : (
        <></>
      )}
      <ProductsContainerFluid background={primaryTheme.background.secondary}>
        {loading ? (
          <ProductsContainer>
            <Row>
              <Column xl={9}>
                <Placeholder height={500} />
              </Column>
              <Column xl={3}>
                <Placeholder height={500} />
              </Column>
              <Column xl={7}>
                <Placeholder height={500} />
              </Column>
              <Column xl={5}>
                <Placeholder height={500} />
              </Column>
            </Row>
          </ProductsContainer>
        ) : (
          <>
            {product !== undefined ? (
              <>
                <ProductsContainer>
                  <Row>
                    <Column xl={9}>
                      <Row>
                        <Column xl={6}>
                          <ProductInfoContainer>
                            <ProductSlugTitle>
                              {product.subcategories !== null ? (
                                <>
                                  <Link to="/">Droga Malu</Link> <FaChevronRight />{' '}
                                  <Link to={`/categorias/${product.categories?.slug}`}>{product.categories?.name}</Link>{' '}
                                  <FaChevronRight />{' '}
                                  <Link to={`/subcategorias/${product.subcategories.slug}`}>
                                    {' '}
                                    {product.subcategories.name}
                                  </Link>
                                </>
                              ) : (
                                <>
                                  {product.categories !== null ? (
                                    <>
                                      <Link to="/">Droga Malu</Link> <FaChevronRight />{' '}
                                      <Link to={`/produtos/categorias/${product.categories.slug}`}>
                                        {product.categories?.name}
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link to="/">Droga Malu</Link> <FaChevronRight /> {product.name}
                                    </>
                                  )}
                                </>
                              )}
                            </ProductSlugTitle>
                            <h1>{product.name}</h1>
                            <h2>Código {product.code}</h2>
                            <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                            {product.productDocuments !== null ? (
                              <ProductButtonContainer href={product.productDocuments.uploads.origin} target="new">
                                Baixar a bula <PiDownloadLight />
                              </ProductButtonContainer>
                            ) : (
                              <></>
                            )}
                          </ProductInfoContainer>
                        </Column>
                        <Column xl={6}>
                          <ProductImagesContainer>
                            {product.productImages !== null && product.productImages.length > 1 ? (
                              <ProductImagesBox>
                                {product.productImages.map((image, i) => (
                                  <div
                                    key={i}
                                    onClick={() => {
                                      setProductActiveImage({ id: image.id });
                                    }}
                                  >
                                    <img src={image.uploads.origin} alt={image.uploads.filename} />
                                  </div>
                                ))}
                              </ProductImagesBox>
                            ) : (
                              <></>
                            )}
                            {product.productImages !== null && product.productImages.length > 0 ? (
                              <ProductMainImageBox>
                                <img
                                  src={
                                    productActiveImage !== undefined
                                      ? product.productImages.filter(
                                        product => product.id === productActiveImage?.id ?? null,
                                      )[0].uploads.origin
                                      : product.productImages[0].uploads.origin
                                  }
                                />
                              </ProductMainImageBox>
                            ) : (
                              <ProductMainImageBox>
                                <LuImageOff />
                              </ProductMainImageBox>
                            )}
                          </ProductImagesContainer>
                        </Column>
                      </Row>
                    </Column>
                    <Column xl={3}>
                      <ProductCartContainer>
                        {productCartLoading ? <Loading /> : <></>}
                        <h1>
                          VENDIDO E ENTREGUE POR <b>Droga Malu</b>
                        </h1>
                        {product.newPrice !== null ? (
                          <ProductOfferContainer>
                            <p>R$ {formatMoney(product.price)}</p>
                            <div>
                              {Decimal.sub(100, Decimal.div(Decimal.mul(product.newPrice, 100), product.price)).toFixed(
                                0,
                              )}
                              % OFF
                            </div>
                          </ProductOfferContainer>
                        ) : (
                          <></>
                        )}
                        <ProductCheckoutContainer>
                          <ProductPriceContainer>
                            {product.newPrice !== null ? (
                              <h1>R$ {formatMoney(new Decimal(product.newPrice).toFixed(2))}</h1>
                            ) : (
                              <h1>R$ {formatMoney(new Decimal(product.price).toFixed(2))}</h1>
                            )}
                            <div>
                              <button
                                onClick={() => {
                                  setCartQuantity(cartQuantity === 1 ? 1 : cartQuantity - 1);
                                }}
                              >
                                <FaMinus />
                              </button>
                              <p>{cartQuantity}</p>
                              <button
                                onClick={() => {
                                  setCartQuantity(cartQuantity + 1);
                                }}
                              >
                                <FaPlus />
                              </button>
                            </div>
                          </ProductPriceContainer>
                          <ProductCheckoutButtonContainer>
                            <button
                              onClick={() => {
                                addToCart({
                                  id: product.id,
                                  name: product.name,
                                  price: product.newPrice ?? product.price,
                                  quantity: cartQuantity,
                                  code: product.code,
                                  stock: product.stock,
                                  specialRecipe: !![
                                    ProductRecipeTypes.COM_RETENCAO,
                                    ProductRecipeTypes.COM_RETENCAO_ESPECIAL,
                                  ].includes(product.recipeId),
                                  image:
                                    product.productImages !== null && product.productImages.length > 0
                                      ? product.productImages[0].uploads.origin
                                      : null,
                                });
                                setCartActive(true);
                              }}
                            >
                              <p>
                                ADICIONAR
                                <br /> AO CARRINHO
                              </p>
                              <FaCartShopping />
                            </button>
                            <button
                              onClick={() => {
                                directCheckout({
                                  id: product.id,
                                  name: product.name,
                                  price: product.newPrice ?? product.price,
                                  quantity: 1,
                                  code: product.code,
                                  specialRecipe: !![
                                    ProductRecipeTypes.COM_RETENCAO,
                                    ProductRecipeTypes.COM_RETENCAO_ESPECIAL,
                                  ].includes(product.recipeId),
                                  image:
                                    product.productImages !== null && product.productImages.length > 0
                                      ? product.productImages[0].uploads.origin
                                      : null,
                                });
                              }}
                            >
                              <p>
                                COMPRAR
                                <br /> AGORA
                              </p>
                              <FaBagShopping />
                            </button>
                          </ProductCheckoutButtonContainer>
                        </ProductCheckoutContainer>
                        {cart?.order.discount === undefined ? (
                          <ProductDiscountContainer valid={validCoupon}>
                            <p>Insira o seu cupom de desconto:</p>
                            <form onSubmit={validateCoupon}>
                              <div>
                                <input
                                  value={couponCode}
                                  onChange={e => {
                                    setCouponCode(e.target.value);
                                  }}
                                  required
                                />
                                <button type="submit">
                                  <FaArrowRight />
                                </button>
                              </div>
                            </form>
                          </ProductDiscountContainer>
                        ) : (
                          <ProductDiscountContainerActive>
                            <h1>
                              Cupom Aplicado <FaCheck />
                            </h1>
                            <h2>{cart.order.discount.name}</h2>
                          </ProductDiscountContainerActive>
                        )}
                        <ProductDeliveryTitleContainer>
                          <h2>CALCULAR FRETE</h2>
                          <a
                            href="https://www2.correios.com.br/sistemas/buscacep/buscacep.cfm"
                            target="new"
                            title="Não sei meu CEP"
                          >
                            Não sei meu CEP
                          </a>
                        </ProductDeliveryTitleContainer>
                        <form onSubmit={searchDeliveriesByCEP}>
                          <ProductDeliveryInputContainer>
                            <InputMask
                              onChange={e => {
                                setZipCode(e.target.value);
                              }}
                              placeholder="Informe seu CEP"
                              mask="99999-999"
                              required
                            />
                            <button type="submit">
                              <FaArrowRight />
                            </button>
                          </ProductDeliveryInputContainer>
                        </form>
                        {deliveries !== undefined ? (
                          <DeliveryBox fastDelivery={true}>
                            <h1>Entrega Rápida - {deliveries.deliveryTime}m</h1>
                            <h2>{deliveries.zipCode}</h2>
                            <h3>R$ {formatMoney(deliveries.price)}</h3>
                            <div>
                              <MdOutlineDeliveryDining />
                              ENTREGA MAIS RÁPIDA
                            </div>
                          </DeliveryBox>
                        ) : (
                          <></>
                        )}
                        {freights !== undefined ? (
                          <>
                            {freights.map((freight, i) => (
                              <DeliveryBox fastDelivery={false} key={i}>
                                <h1>
                                  {freight.company.name} - de {freight.deliveryTimeMin} a {freight.deliveryTimeMax}{' '}
                                  Dia(s) úteis
                                </h1>
                                <h2>{freight.name}</h2>
                                <h3>R$ {formatMoney(freight.price)}</h3>
                              </DeliveryBox>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </ProductCartContainer>
                    </Column>
                  </Row>
                </ProductsContainer>
                {advertising !== undefined ? (
                  <ProductsAdvertisingContainer>
                    <Advertising data={advertising} />
                  </ProductsAdvertisingContainer>
                ) : (
                  <></>
                )}
                <ProductsInfoContainer>
                  <Row>
                    <Column xl={product.brands !== null ? 7 : 12}>
                      <h1>Especificações</h1>
                      <ProductEspecificationContainer>
                        {product.activePrinciple !== null ? (
                          <ProductEspecificationBox background="#e1e1e1">
                            <Row>
                              <Column xl={6}>Princípio Ativo</Column>
                              <Column xl={6}>{product.activePrinciple ?? '-'}</Column>
                            </Row>
                          </ProductEspecificationBox>
                        ) : (
                          <></>
                        )}
                        {product.productRoutes !== null ? (
                          <ProductEspecificationBox background="#fff">
                            <Row>
                              <Column xl={6}>Forma de Administração</Column>
                              <Column xl={6}>{product.productRoutes?.name ?? '-'}</Column>
                            </Row>
                          </ProductEspecificationBox>
                        ) : (
                          <></>
                        )}
                        {product.productStrips !== null ? (
                          <ProductEspecificationBox background="#e1e1e1">
                            <Row>
                              <Column xl={6}>Classificação</Column>
                              <Column xl={6}>{product.productStrips?.name ?? '-'}</Column>
                            </Row>
                          </ProductEspecificationBox>
                        ) : (
                          <></>
                        )}
                        {product.productClasses !== null ? (
                          <ProductEspecificationBox background="#fff">
                            <Row>
                              <Column xl={6}>Classe de Medicamento</Column>
                              <Column xl={6}>{product.productClasses?.name ?? '-'}</Column>
                            </Row>
                          </ProductEspecificationBox>
                        ) : (
                          <></>
                        )}
                        {product.productRecipes !== null ? (
                          <ProductEspecificationBox background="#e1e1e1">
                            <Row>
                              <Column xl={6}>Preescrição Médica</Column>
                              <Column xl={6}>{product.productRecipes?.name ?? '-'}</Column>
                            </Row>
                          </ProductEspecificationBox>
                        ) : (
                          <></>
                        )}
                        {product.productTypes !== null ? (
                          <ProductEspecificationBox background="#fff">
                            <Row>
                              <Column xl={6}>Tipo de Medicamento</Column>
                              <Column xl={6}>{product.productTypes?.name ?? '-'}</Column>
                            </Row>
                          </ProductEspecificationBox>
                        ) : (
                          <></>
                        )}
                        {product.ms !== null ? (
                          <ProductEspecificationBox background="#e1e1e1">
                            <Row>
                              <Column xl={6}>Registro MS</Column>
                              <Column xl={6}>{product.ms ?? '-'}</Column>
                            </Row>
                          </ProductEspecificationBox>
                        ) : (
                          <></>
                        )}
                      </ProductEspecificationContainer>
                    </Column>
                    {product.brands !== null ? (
                      <Column xl={5}>
                        <h1>Marca</h1>
                        <ProductBrandContainer>
                          <ProductBrandBox>
                            {product.brands.brandImages !== null ? (
                              <img src={product.brands.brandImages.uploads.origin} />
                            ) : (
                              <h1>{product.brands.name}</h1>
                            )}
                          </ProductBrandBox>
                          <div>
                            {product.categories !== null ? (
                              <button
                                onClick={() => {
                                  navigate(`/marcas/${product.brands?.slug}`);
                                }}
                              >
                                {product.categories.name} {product.brands.name}
                              </button>
                            ) : (
                              <></>
                            )}

                            <button
                              onClick={() => {
                                navigate(`/marcas/${product.brands?.slug}`);
                              }}
                            >
                              TODOS OS PRODUTOS {product.brands.name}
                            </button>
                          </div>
                        </ProductBrandContainer>
                      </Column>
                    ) : (
                      <></>
                    )}
                  </Row>
                </ProductsInfoContainer>
                {product.especifications !== null && product.especifications !== '' ? (
                  <ProductDescriptionContainer>
                    <ProductDescriptionBox>
                      <div dangerouslySetInnerHTML={{ __html: product.especifications }} />
                    </ProductDescriptionBox>
                  </ProductDescriptionContainer>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <ProductsContainer>
                  <ProductNotFound>
                    <MdErrorOutline />
                    <p>Produto não encontrado</p>
                    <button
                      onClick={() => {
                        navigate('/produtos');
                      }}
                    >
                      Buscar por produtos <BiSearchAlt2 />
                    </button>
                  </ProductNotFound>
                </ProductsContainer>
              </>
            )}
          </>
        )}
        {productsRecomendations.length > 0 ? (
          <ProductHighlightsContainer>
            <h1>Ofertas Incríveis para você!</h1>
            <Row>
              {productsRecomendations.slice(0, 6).map((product, i) => (
                <Column key={i} xl={2}>
                  <Recomendation data={product} />
                </Column>
              ))}
            </Row>
          </ProductHighlightsContainer>
        ) : (
          <></>
        )}
      </ProductsContainerFluid>
    </>
  );
};

export default Product;
